import { Accessor } from 'solid-js';
import { Result, useResults } from '../useResults';
import { ColumnDef } from '@tanstack/solid-table';

type ResultsTableColumnDef = ColumnDef<Result>[];
export type ResultsTableColumns = Accessor<ResultsTableColumnDef>;

export const useResultsTableColumns = (): ResultsTableColumns => {
  const { prediction_target } = useResults();
  const columns = (): ResultsTableColumnDef => [
    {
      header: 'Cohort',
      columns: [
        {
          header: 'Slides file name',
          accessorKey: 'fileName',
          meta: { semiBold: true },
          size: 200,
        },
        {
          header: 'Validation/Test subset',
          accessorKey: 'group',
          meta: { semiBold: true },
          size: 200,
        },
      ],
      size: 400,
    },
    {
      header: 'Prediction target',
      columns: [
        {
          header: prediction_target(),
          accessorKey: 'prediction_target',
          meta: { semiBold: true },
          size: 200,
        },
      ],
      size: 200,
    },
    {
      header: 'Results',
      size: 300,
      columns: [
        {
          header: 'AI prediction',
          accessorKey: 'prediction_result',
          meta: { semiBold: true },
          size: 100,
        },
        {
          header: 'Result',
          accessorKey: 'result',
          meta: { semiBold: true },
          size: 100,
        },
        {
          header: 'Confidence score',
          accessorKey: 'confidence_score',
          accessorFn: (row) => row.confidence_score.toFixed(2),
          meta: { semiBold: true },
          size: 100,
        },
      ],
    },
  ];

  return columns;
};
