import { ParentProps } from 'solid-js';
import classes from './card.module.css';

interface CardProps {
  elevate?: boolean;
  noElevation?: boolean;
}

export function Card(props: ParentProps<CardProps>) {
  return (
    <div
      class={classes.card}
      classList={{
        [classes.elevate]: props.elevate,
        [classes['disable-elevation']]: props.noElevation,
      }}
    >
      {props.children}
    </div>
  );
}
