import { Match, Show, Suspense, Switch } from 'solid-js';
import { A } from '@solidjs/router';
import { Button } from '../../../components/Button';
import { SelectColumnModal } from './SelectColumnModal';
import { UploadLabelsModal } from './UploadLabelsModal';
import { CohortCard, CohortCardSkeleton } from './CohortCard';
import { ErrorModal } from '../../../business-components/Modals/ErrorModal/ErrorModal';
import { LABEL_MODE, useLabels } from './useLabels';
import { useSelectedProject } from '../../../utils/use-selected-project';

import classes from './labels.module.css';

export function LabelsPage() {
  const {
    chips,
    selectedChip,
    setSelectedChip,
    showUploadButton,
    isUploadModalOpen,
    setUploadModalOpen,
    addFile,
    isUploading,
    isColumnModalOpen,
    deleteDataUpload,
    cohortColumn,
    columns,
    onColumnConfirm,
    selectedColumn,
    setSelectedColumn,
    cohortData,
    onDuplicateConfirm,
    onMissingConfirm,
    duplicates,
    missingValues,
    uploadError,
    isUploadErrorModalOpen,
    setUploadErrorModalOpen,
  } = useLabels();

  const project = useSelectedProject();
  const slug = () => project.selectedProject()?.slug;
  const isTCGA = () => project.selectedProject()?.data_source === 'TCGA';
  const hasCohort = () => Boolean(project.selectedProject()?.cohorts.at(0)?.id);

  return (
    <div class={classes['labels-tab']}>
      <Show when={!isTCGA() || hasCohort()}>
        <div class={classes['labels-tab-header']}>
          <div class={classes.chips}>
            {/* <For each={chips()}>
            {(chip) => (
              <Chip
                label={chip.label}
                active={selectedChip() === chip.id}
                onClick={() => setSelectedChip(chip.id)}
                disabled={chip.disabled}
              />
            )}
          </For> */}
          </div>
          <Show when={isTCGA()}>
            <A href={`/projects/${slug()}/tcga`}>
              <Button>Define New Cohort</Button>
            </A>
          </Show>
          <Show when={!isTCGA() && showUploadButton()}>
            <Button onClick={() => setUploadModalOpen(true)}>
              Upload data labels
            </Button>
          </Show>
          <UploadLabelsModal
            addFile={addFile}
            open={isUploadModalOpen()}
            onClose={() => setUploadModalOpen(false)}
            disabled={isUploading()}
          />
          <SelectColumnModal
            open={isColumnModalOpen()}
            columns={columns()}
            onClose={deleteDataUpload}
            onConfirm={onColumnConfirm}
            onSelect={(column) => setSelectedColumn(column)}
            selected={selectedColumn()}
          />
        </div>
      </Show>

      <Switch>
        <Match when={selectedChip() === LABEL_MODE.COHORT && !isTCGA()}>
          <Suspense fallback={<CohortCardSkeleton />}>
            <CohortCard
              column={cohortColumn()}
              data={cohortData()}
              addFile={addFile}
              isUploading={isUploading()}
              duplicates={duplicates()}
              missingValues={missingValues()}
              onDuplicateCancel={deleteDataUpload}
              onDuplicateConfirm={onDuplicateConfirm}
              onMissingCancel={deleteDataUpload}
              onMissingConfirm={onMissingConfirm}
            />
            <ErrorModal
              title={uploadError().title}
              message={uploadError().message}
              open={isUploadErrorModalOpen()}
              onClose={() => setUploadErrorModalOpen(false)}
            />
          </Suspense>
        </Match>

        <Match when={isTCGA() && hasCohort()}>
          <CohortCard
            column={cohortColumn()}
            data={cohortData()}
            addFile={addFile}
            isUploading={isUploading()}
            duplicates={duplicates()}
            missingValues={missingValues()}
            onDuplicateCancel={deleteDataUpload}
            onDuplicateConfirm={onDuplicateConfirm}
            onMissingCancel={deleteDataUpload}
            onMissingConfirm={onMissingConfirm}
          />
        </Match>
        <Match when={selectedChip() === LABEL_MODE.TEXT}>text</Match>
        <Match when={selectedChip() === LABEL_MODE.NUMERIC}>numeric</Match>
        <Match when={selectedChip() === LABEL_MODE.MIXED}>mixed</Match>
        <Match when={selectedChip() === LABEL_MODE.NO_SLIDES}>no slides</Match>
      </Switch>
    </div>
  );
}
