export default function Check(props: { class?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={props.class}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 9.75C0 4.3739 4.3739 0 9.75 0C15.1261 0 19.5 4.3739 19.5 9.75C19.5 15.1261 15.1261 19.5 9.75 19.5C4.3739 19.5 0 15.1261 0 9.75ZM0 9.75C0 15.1348 4.36522 19.5 9.75 19.5C15.1348 19.5 19.5 15.1348 19.5 9.75C19.5 4.36522 15.1348 0 9.75 0C4.36522 0 0 4.36522 0 9.75ZM8.52422 13.9823L14.8242 6.48234C14.9488 6.32965 15.0082 6.13409 14.9897 5.93793C14.9712 5.74176 14.8763 5.56075 14.7254 5.43401C14.5746 5.30728 14.3799 5.24501 14.1835 5.26066C13.987 5.27631 13.8047 5.36863 13.6758 5.51765L7.92937 12.3572L5.8064 9.99843C5.74128 9.9226 5.66166 9.86055 5.57222 9.81593C5.48277 9.77131 5.38531 9.74502 5.28556 9.7386C5.18581 9.73219 5.08578 9.74578 4.99136 9.77857C4.89694 9.81136 4.81002 9.8627 4.73572 9.92956C4.66142 9.99643 4.60123 10.0775 4.5587 10.1679C4.51617 10.2584 4.49215 10.3564 4.48805 10.4563C4.48395 10.5562 4.49985 10.6559 4.53483 10.7495C4.56981 10.8431 4.62315 10.9288 4.69172 11.0016L7.39172 14.0016C7.46202 14.0797 7.54796 14.1422 7.64398 14.185C7.74 14.2278 7.84394 14.25 7.94906 14.25H7.96172C8.06918 14.2483 8.17502 14.2235 8.27207 14.1773C8.36912 14.1311 8.45511 14.0647 8.52422 13.9823Z"
        fill="white"
      />
    </svg>
  );
}
