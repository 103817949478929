import {
  createSolidTable,
  flexRender,
  getCoreRowModel,
  type Header,
  type ColumnDef,
  type Row,
} from '@tanstack/solid-table';
import { For } from 'solid-js';
import classes from './table.module.css';
import { Typography } from '../../components/Typography';
import { createVirtualizer } from '@tanstack/solid-virtual';

interface ColumnMetadata {
  semiBold?: boolean;
}

interface TableProps<T> {
  data: T[];
  columns: ColumnDef<T, unknown>[];
  columnOrder?: string[];
  class?: string;
  getItemKey?: (index: number) => number | string;
}

export function Table<TData>(props: TableProps<TData>) {
  let tableRef!: HTMLDivElement;

  const table = createSolidTable({
    get data() {
      return props.data;
    },
    get columns() {
      return props.columns;
    },
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnOrder: props.columnOrder,
    },
    defaultColumn: {
      size: 120,
    },
  });

  const rowVirtualizer = createVirtualizer({
    get count() {
      return table.getRowCount();
    },
    getScrollElement: () => tableRef,
    estimateSize: () => 56,
    getItemKey: props.getItemKey,
    overscan: 10,
  });

  const columnVirtualizer = createVirtualizer({
    get count() {
      return table.getVisibleLeafColumns().length;
    },
    getScrollElement: () => tableRef,
    estimateSize: (index) => table.getVisibleLeafColumns()[index]?.getSize(),
    overscan: 5,
    horizontal: true,
  });

  return (
    <div ref={tableRef} class={classes.container}>
      <div>
        <table
          class={classes.table}
          classList={{ [props.class ?? '']: !!props.class }}
        >
          <thead
            style={{
              'min-width': '100%',
              width: `${columnVirtualizer.getTotalSize()}px`,
              height: '100%',
            }}
          >
            {/* We use map instead of For because we loose reactivity when using the virtualizer */}
            {table.getHeaderGroups().map((headerGroup) => (
              <tr style={{ display: 'flex', width: '100%', height: '100%' }}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        width: `${header.getSize()}px`,
                        transform: `translateX(${header.getStart()}px)`,
                      }}
                    >
                      <Header {...header} />
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
            }}
          >
            {/* We use map instead of For because we loose reactivity when using the virtualizer */}
            {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = table.getRowModel().rows[virtualRow.index];
              return (
                <tr
                  id={row.id}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${
                      virtualRow.start - index * virtualRow.size
                    }px)`,
                  }}
                >
                  <Row {...row} />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function Header<TData>(props: Header<TData, unknown>) {
  const header = props.column?.columnDef?.header;
  return (
    <Typography wrap="20ch" title={header?.toString()}>
      {flexRender(header, props.getContext())}
    </Typography>
  );
}

function Row<TData>(props: Row<TData>) {
  return (
    <For each={props.getVisibleCells()}>
      {(cell) => {
        const semiBold = Boolean(
          (cell.column.columnDef.meta as ColumnMetadata)?.semiBold
        );
        return (
          <td id={cell.id}>
            <Typography
              wrap="15ch"
              title={cell.getValue<string>()}
              weight={semiBold ? 'semi-bold' : 'default'}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Typography>
          </td>
        );
      }}
    </For>
  );
}
