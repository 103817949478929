import { Component, Show } from 'solid-js';
import classes from '../model-page.module.css';
import { TrashCanIcon } from '../../../components/icons/TrashCanIcon';
import { Button } from '../../../components/Button';
import { useResults } from '../useResults';
import { Table } from '../../Table';
import { useResultsTableColumns } from './use-results-columns';

export const ModelResults: Component = () => {
  const { results, exportURL } = useResults();
  const columns = useResultsTableColumns();
  return (
    <div class={classes['model-results']}>
      <div class={classes['results-header']}>
        <a href={exportURL()} download>
          <Button variant="secondary">Export results</Button>
        </a>
        {/* <Button variant="secondary">Add to prediction "Panels"</Button>
        <Button variant="secondary">
          <TrashCanIcon />
        </Button> */}
      </div>

      <Show when={results().length}>
        <Table
          data={results()}
          columns={columns()}
          class={classes['results-table']}
        />
      </Show>
    </div>
  );
};
