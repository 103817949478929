import {
  Accessor,
  createContext,
  createSignal,
  ParentProps,
  useContext,
} from 'solid-js';
import { BreadcrumbItem } from '../components/Breadcrumbs';

interface BreadcrumbsContext {
  breadcrumbs: Accessor<BreadcrumbItem[]>;
  setBreadcrumbs: (breadcrumbs: BreadcrumbItem[]) => void;
}

const BreadcrumbsContext = createContext<BreadcrumbsContext>(
  {} as unknown as BreadcrumbsContext
);

export function useBreadcrumbs() {
  return useContext(BreadcrumbsContext);
}

export function BreadcrumbsProvider(props: ParentProps) {
  const [breadcrumbs, setBreadcrumbs] = createSignal<BreadcrumbItem[]>([]);
  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {props.children}
    </BreadcrumbsContext.Provider>
  );
}
