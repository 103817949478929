import { useMatch } from '@solidjs/router';
import { FolderIcon } from '../../components/icons/FolderIcon';
import { MenuButton } from '../../components/MenuButton';
import { Menu, MenuItem } from '../../components/Menu';
import { Accessor, createSignal } from 'solid-js';

interface SidebarModelItemProps {
  model: { name: string; slug: string; is_draft: boolean };
  collapsed: boolean;
  project_slug: string;
}

export function SidebarModelItem(props: SidebarModelItemProps) {
  const [open, setOpen] = createSignal(false);

  const active = useMatch(
    () => `/projects/${props.project_slug}/models/${props.model.slug}/*`
  );
  const menuItems: Accessor<MenuItem[]> = () => [
    {
      label: 'Overview',
      route: `/projects/${props.project_slug}/models/${props.model.slug}/overview`,
      disabled: props.model.is_draft,
    },
    {
      label: 'Results',
      route: `/projects/${props.project_slug}/models/${props.model.slug}/results`,
      disabled: props.model.is_draft,
    },
    {
      label: 'Validation KPIs',
      route: `/projects/${props.project_slug}/models/${props.model.slug}/kpi/validation`,
      disabled: props.model.is_draft,
    },
    {
      label: 'Test KPIs',
      route: `/projects/${props.project_slug}/models/${props.model.slug}/kpi/test`,
      disabled: props.model.is_draft,
    },
  ];

  return (
    <Menu items={menuItems()} disabled={!props.collapsed}>
      <MenuButton
        open={open()}
        active={Boolean(active())}
        text={props.model.name}
        icon={<FolderIcon />}
        collapsed={props.collapsed}
        onClick={() => {
          if (!props.collapsed) {
            setOpen((prev) => !prev);
          }
        }}
        items={menuItems()}
      />
    </Menu>
  );
}
