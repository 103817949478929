import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { WhitePlusIcon } from '../../components/icons/WhitePlusIcon';
import { Typography } from '../../components/Typography';
import classes from './projects-deck.module.css';

export function NewProjectCard(props: { onNewProject: VoidFunction }) {
  return (
    <Card>
      <div class={classes['new-project-card']}>
        <Typography weight="bold">Let's start!</Typography>
        <Typography color="secondary">
          Creating or adding new project couldn't be easier
        </Typography>
        <Button onClick={props.onNewProject}>
          <WhitePlusIcon />
          <Typography>New project</Typography>
        </Button>
      </div>
    </Card>
  );
}
