import { useMatch } from '@solidjs/router';
import { FileIcon } from '../../components/icons/FileIcon';
import { MenuButton } from '../../components/MenuButton';
import type { SingleProject } from '@imagene/fm-studio-interfaces';

import { createSignal } from 'solid-js';
import { Menu } from '../../components/Menu';

interface SidebarProjectItemProps {
  project: SingleProject;
  collapsed: boolean;
}

export function SidebarSelectedProject(props: SidebarProjectItemProps) {
  const [open, setOpen] = createSignal(false);
  const active = useMatch(() => `/projects/${props.project.slug}/*`);
  const menuItems = () => [
    {
      label: 'Models',
      route: `/projects/${props.project.slug}/models`,
    },
    {
      label: 'Data labels',
      route: `/projects/${props.project.slug}/labels`,
    },
    {
      label: 'Slides',
      route: `/projects/${props.project.slug}/slides`,
    },
  ];

  return (
    <Menu items={menuItems()} disabled={!props.collapsed}>
      <MenuButton
        open={open()}
        active={Boolean(active())}
        text={props.project.name}
        icon={<FileIcon />}
        collapsed={props.collapsed}
        onClick={() => {
          if (!props.collapsed) {
            setOpen((prev) => !prev);
          }
        }}
        items={menuItems()}
      />
    </Menu>
  );
}
