import { createEffect, ParentProps, Show } from 'solid-js';
import classes from './modal.module.css';
import { Portal } from 'solid-js/web';

interface ModalProps {
  open: boolean;
  id?: string;
  onClose?: VoidFunction;
  disableOutsideClick?: boolean;
}

export function Modal(props: ParentProps<ModalProps>) {
  let modalRef: HTMLDialogElement;

  function onOutsideClick(e: MouseEvent) {
    if (e.target === modalRef && !props.disableOutsideClick) {
      props.onClose?.();
      modalRef.close();
    }
  }

  function toggleModal() {
    props.open ? modalRef?.showModal() : modalRef?.close();
  }

  function onCancel(e: Event) {
    if (props.disableOutsideClick) {
      e.preventDefault();
    }
  }

  createEffect(() => {
    toggleModal();
    modalRef?.scrollTo({ top: 0 });
  });

  return (
    <Portal>
      <Show when={props.open}>
        <dialog
          id={props.id}
          ref={(r) => (modalRef = r)}
          class={classes.modal}
          onClick={(e) => onOutsideClick(e)}
          onClose={props.onClose}
          onCancel={onCancel}
        >
          <div class={classes['modal-content']}>{props.children}</div>
        </dialog>
      </Show>
    </Portal>
  );
}
