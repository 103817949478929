import { Component, For, Show } from 'solid-js';
import type { ValidateFileResponse } from '@imagene/fm-studio-interfaces';
import { CohortTable } from '../../../business-components/CohortTable';
import { Card } from '../../../components/Card';
import { FileInput } from '../../../components/Inputs/FileInput';
import { Typography } from '../../../components/Typography';
import { Skeleton } from '../../../components/Skeleton';
import { DuplicateLabelModal } from '../../../business-components/Modals/InformationModal/DuplicateLabelModal';
import { MissingValueModal } from '../../../business-components/Modals/InformationModal/MissingValueModal';

import classes from './labels.module.css';

interface CohortCardProps {
  column: string;
  data: Record<string, string>[];
  addFile: (files: File[]) => void;
  isUploading: boolean;
  duplicates: ValidateFileResponse['duplicates'];
  missingValues: ValidateFileResponse['missingValues'];
  onDuplicateConfirm: VoidFunction;
  onDuplicateCancel: VoidFunction;
  onMissingConfirm: VoidFunction;
  onMissingCancel: VoidFunction;
}

export function CohortCard(props: CohortCardProps) {
  const showMissingValues = () => Boolean(props.missingValues.rows.length);
  const showDuplicates = () =>
    Boolean(props.duplicates.length) && !showMissingValues();
  return (
    <Card>
      <div class={classes.cohort}>
        <Typography component="h3" weight="bold">
          Cohort: {props.data.length}
        </Typography>
        <Show
          when={Boolean(props.data.length)}
          fallback={
            <NoLabels addFile={props.addFile} isUploading={props.isUploading} />
          }
        >
          <CohortTable data={props.data} column={props.column} />
        </Show>
        <MissingValueModal
          open={showMissingValues()}
          onClose={props.onMissingCancel}
          rows={props.missingValues.rows}
          onConfirm={props.onMissingConfirm}
        />
        <DuplicateLabelModal
          duplicates={props.duplicates}
          open={showDuplicates()}
          onConfirm={props.onDuplicateConfirm}
          onClose={props.onDuplicateCancel}
        />
      </div>
    </Card>
  );
}

interface NoLabelsProps {
  addFile: (files: File[]) => void;
  isUploading: boolean;
}

function NoLabels(props: NoLabelsProps) {
  return (
    <div class={classes['no-labels']}>
      <div class={classes['no-labels-title']}>
        <Typography component="h3" weight="bold">
          No data was uploaded yet.
        </Typography>
        <Typography component="h3" weight="bold">
          Please upload the CSV file containing the table with all relevant
          information from your dataset.
        </Typography>
      </div>

      <div class={classes['no-labels-body']}>
        <div class={classes['no-labels-text']}>
          <Typography component="p">
            *Please make sure that the table contains a column <br />
            with{' '}
            <Typography class={classes.highlight} weight="bold">
              filenames of Whole Slide Images.
            </Typography>
            <br />
            The system will use this column to match slides' files to the
            uploaded data
          </Typography>
          <div class={classes['labels-sample-img']} />
        </div>
        <FileInput
          criteria={'Only .csv format'}
          accept=".csv"
          onFileSelected={props.addFile}
          buttonText="Browse file"
          hintText="Drag and drop file here or"
          disabled={props.isUploading}
        />
      </div>
    </div>
  );
}

export const CohortCardSkeleton: Component = () => (
  <Card>
    <div class={classes.skeleton}>
      <Skeleton width={156} height={25} />
      <Skeleton height={30} />
      <div class={classes.skeleton_table}>
        <For each={Array(66).fill(0)}>{() => <Skeleton width={240} />}</For>
      </div>
    </div>
  </Card>
);
