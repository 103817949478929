export function LabelsIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.616 17C2.15533 17 1.771 16.846 1.463 16.538C1.155 16.23 1.00067 15.8453 1 15.384V2.616C1 2.15533 1.15433 1.771 1.463 1.463C1.77167 1.155 2.156 1.00067 2.616 1H15.385C15.845 1 16.2293 1.15433 16.538 1.463C16.8467 1.77167 17.0007 2.156 17 2.616V15.385C17 15.845 16.846 16.2293 16.538 16.538C16.23 16.8467 15.8453 17.0007 15.384 17H2.616ZM2 5.998H16V2.616C16 2.462 15.936 2.32067 15.808 2.192C15.68 2.06333 15.5387 1.99933 15.384 2H2.616C2.462 2 2.32067 2.064 2.192 2.192C2.06333 2.32 1.99933 2.46133 2 2.616V5.998ZM2 11.002H16V6.998H2V11.002ZM2.616 16H15.385C15.5383 16 15.6793 15.936 15.808 15.808C15.9367 15.68 16.0007 15.5387 16 15.384V12.002H2V15.384C2 15.538 2.064 15.6793 2.192 15.808C2.32 15.9367 2.461 16.0007 2.615 16M3.77 4.804V3.188H5.385V4.804H3.77ZM3.77 9.808V8.192H5.385V9.808H3.77ZM3.77 14.811V13.196H5.385V14.811H3.77Z"
        fill="currentColor"
      />
      <path
        d="M2.616 16H15.385C15.5383 16 15.6793 15.936 15.808 15.808C15.9367 15.68 16.0007 15.5387 16 15.384V12.002H2V15.384C2 15.538 2.064 15.6793 2.192 15.808C2.32 15.9367 2.461 16.0007 2.615 16M2.616 17C2.15533 17 1.771 16.846 1.463 16.538C1.155 16.23 1.00067 15.8453 1 15.384V2.616C1 2.15533 1.15433 1.771 1.463 1.463C1.77167 1.155 2.156 1.00067 2.616 1H15.385C15.845 1 16.2293 1.15433 16.538 1.463C16.8467 1.77167 17.0007 2.156 17 2.616V15.385C17 15.845 16.846 16.2293 16.538 16.538C16.23 16.8467 15.8453 17.0007 15.384 17H2.616ZM2 5.998H16V2.616C16 2.462 15.936 2.32067 15.808 2.192C15.68 2.06333 15.5387 1.99933 15.384 2H2.616C2.462 2 2.32067 2.064 2.192 2.192C2.06333 2.32 1.99933 2.46133 2 2.616V5.998ZM2 11.002H16V6.998H2V11.002ZM3.77 4.804V3.188H5.385V4.804H3.77ZM3.77 9.808V8.192H5.385V9.808H3.77ZM3.77 14.811V13.196H5.385V14.811H3.77Z"
        stroke="currentColor"
        stroke-width="0.3"
      />
    </svg>
  );
}
