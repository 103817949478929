import { Routes, Route, Navigate } from '@solidjs/router';
import { MainLayout } from './pages/layouts/MainLayout';
import { Projects } from './pages/projects';
import { QueryClient, QueryClientProvider } from '@tanstack/solid-query';
import { ProjectPage } from './pages/projects/ProjectPage';
import { BreadcrumbsProvider } from './utils/use-breadcrumbs';
import { SlidesPage } from './pages/projects/Slides';
import { ModelsList, ModelsPage } from './pages/projects/Models';
import { LabelsPage } from './pages/projects/Labels';
import { EditModel } from './pages/projects/Models/EditModel';
import { ModelPage } from './business-components/ModelPage';
import { ModelOverview } from './business-components/ModelPage/ModelOverview';
import { ModelResults } from './business-components/ModelPage/ModelResults';
import { ModelKPIs } from './business-components/ModelPage/ModelKPIs';
// import { ModelSlides } from './business-components/ModelPage/ModelSlides';
import { ToastProvider } from './components/Toast';
import { AxiosProvider } from './utils/axios';
import { TCGACohortWizard } from './pages/projects/Labels/TCGACohortWizard';
import { TopLoadingBar } from './components/TopLoadingBar';
import { TrialQuotaProvider } from './utils/use-trial-quota';

const queryClient = new QueryClient();

export default function App() {
  return (
    <TopLoadingBar>
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <AxiosProvider>
            <TrialQuotaProvider>
              <BreadcrumbsProvider>
                <Routes>
                  <Route path="*" component={MainLayout}>
                    <Route path="/" element={<Navigate href="/projects" />} />
                    <Route path="/projects">
                      <Route path="/" component={Projects} />

                      <Route path="/:slug/:tab?" component={ProjectPage}>
                        <Route path="/tcga" component={TCGACohortWizard} />
                        <Route
                          path="/models/:modelSlug/wizard"
                          component={EditModel}
                        />
                        <Route path="/models" component={ModelsPage}>
                          <Route path="/" component={ModelsList} />
                          <Route
                            path="/:modelSlug?/:modelTab?"
                            component={ModelPage}
                          >
                            <Route path="/overview" component={ModelOverview} />
                            <Route path="/results" component={ModelResults} />
                            {/* <Route path="/slides" component={ModelSlides} /> */}
                            <Route
                              path="/kpi/validation"
                              element={<ModelKPIs type="validation" />}
                            />
                            <Route
                              path="/kpi/test"
                              element={<ModelKPIs type="test" />}
                            />
                          </Route>
                        </Route>

                        <Route path="/labels" component={LabelsPage} />
                        <Route path="/slides" component={SlidesPage} />
                      </Route>
                    </Route>
                  </Route>
                </Routes>
              </BreadcrumbsProvider>
            </TrialQuotaProvider>
          </AxiosProvider>
        </QueryClientProvider>
      </ToastProvider>
    </TopLoadingBar>
  );
}
