import { ColumnDef } from '@tanstack/solid-table';
import { Table } from '../Table';
import { Show } from 'solid-js';

interface CohortTableProps {
  data: Record<string, string>[];
  column: string;
}

export function CohortTable(props: CohortTableProps) {
  const columnNames = () => [...Object.keys(props.data[0] ?? [])];
  const columns = () =>
    columnNames().map<ColumnDef<Record<string, string>, unknown>>((column) => ({
      accessorFn: (row) => row[column],
      header: column,
    }));

  const columnsOrder = () => [props.column, ...columnNames()];

  return (
    <Show when={props.data.length}>
      <Table
        data={props.data}
        columns={columns()}
        columnOrder={columnsOrder()}
        getItemKey={(idx) => props.data?.[idx]?.[props.column]}
      />
    </Show>
  );
}
