import * as menu from '@zag-js/menu';
import { normalizeProps, useMachine } from '@zag-js/solid';
import {
  createMemo,
  createUniqueId,
  For,
  ParentProps,
  Show,
  splitProps,
} from 'solid-js';
import { A } from '@solidjs/router';
import classes from './menu.module.css';
import type { Placement } from '@zag-js/tooltip';

export interface MenuItem {
  label: string;
  route: string;
  disabled?: boolean;
}

export interface MenuProps {
  items: MenuItem[];
  disabled?: boolean;
  open?: boolean;
  onSelect?: (value: string) => void;
  placement?: Placement;
}

export function Menu(props: ParentProps<MenuProps>) {
  const [context, local] = splitProps(props, ['open']);
  const [state, send] = useMachine(
    menu.machine({
      id: createUniqueId(),
      open: props.open,
      onSelect: (details) => props.onSelect?.(details.value),
      closeOnSelect: true,
      positioning: {
        placement: props.placement ?? 'right-start',
      },
    }),
    { context }
  );

  const api = createMemo(() => menu.connect(state, send, normalizeProps));

  return (
    <Show when={!local.disabled} fallback={local.children}>
      <div class={classes['menu-wrapper']}>
        <button {...api().getTriggerProps()}>{local.children}</button>
        <div {...api().getPositionerProps()}>
          <ul {...api().getContentProps()}>
            <div>
              <For each={local.items}>
                {(item) => (
                  <Show
                    when={item.route}
                    fallback={
                      <div
                        {...api().getItemProps({
                          value: item.label,
                          disabled: item.disabled,
                        })}
                      >
                        {item.label}
                      </div>
                    }
                  >
                    {(route) => (
                      <A
                        activeClass={classes.active}
                        href={route()}
                        {...api().getItemProps({
                          value: item.label,
                          disabled: item.disabled,
                        })}
                      >
                        {item.label}
                      </A>
                    )}
                  </Show>
                )}
              </For>
            </div>
          </ul>
        </div>
      </div>
    </Show>
  );
}
