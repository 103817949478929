import { normalizeProps, useMachine } from '@zag-js/solid';
import * as toggle from '@zag-js/toggle-group';
import {
  createMemo,
  createUniqueId,
  For,
  JSXElement,
  Show,
  splitProps,
} from 'solid-js';
import { Typography } from '../Typography';
import './toggle-group.module.css';

interface ToggleButton {
  id: string;
  icon?: JSXElement;
  text?: string;
}

interface ToggleGroupProps {
  items: ToggleButton[];
  onToggle: (btnId: string) => void;
  value?: string[];
  disabled?: boolean;
}

export function ToggleGroup(props: ToggleGroupProps) {
  const [context, local] = splitProps(props, ['value', 'disabled']);
  const [state, send] = useMachine(
    toggle.machine({
      multiple: false,
      id: createUniqueId(),
      onValueChange: (details) => props.onToggle(details.value[0]),
    }),
    {
      context,
    }
  );
  const api = createMemo(() => toggle.connect(state, send, normalizeProps));

  return (
    <div {...api().getRootProps()}>
      <For each={local.items}>
        {(item) => (
          <button {...api().getItemProps({ value: item.id })}>
            <Show when={item.icon}>{item.icon}</Show>
            <Show when={item.text}>
              <Typography>{item.text}</Typography>
            </Show>
          </button>
        )}
      </For>
    </div>
  );
}
