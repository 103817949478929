import { Component, Match, Show, Switch } from 'solid-js';
import classes from './slides-upload-table.module.css';
import { SlideStatusIndicator } from '../SlideStatus';
import type { StudioSlide, SlideStatus } from '@imagene/fm-studio-interfaces';
import { Table } from '../Table';
import { useSlidesTableColumns } from './use-slides-columns';

interface SlidesListTableProps {
  slides: StudioSlide[];
}

export const SlidesUploadTable: Component<SlidesListTableProps> = (props) => {
  const columns = useSlidesTableColumns();
  return (
    <Show when={props.slides.length}>
      <Table
        data={props.slides}
        columns={columns()}
        class={classes['slides-table']}
        getItemKey={(idx) => props.slides?.[idx]?.fileName}
      />
    </Show>
  );
};

interface StatusIndicatorProps {
  status?: SlideStatus;
  details?: string;
}

interface MatchingStatusIndicatorProps {
  matched?: boolean;
}

export const MatchingStatusIndicator: Component<
  MatchingStatusIndicatorProps
> = (props) => {
  return (
    <Show
      when={props.matched}
      fallback={<SlideStatusIndicator text="No rows found" />}
    >
      <SlideStatusIndicator type="done" />
    </Show>
  );
};

interface UploadStatusIndicatorProps extends StatusIndicatorProps {
  progress?: number;
}

export const UploadStatusIndicator: Component<UploadStatusIndicatorProps> = (
  props
) => {
  return (
    <Show when={props.status} fallback={<SlideStatusIndicator />}>
      <Switch>
        <Match when={props.status === 'done'}>
          <SlideStatusIndicator type="done" />
        </Match>

        <Match when={props.status === 'error'}>
          <SlideStatusIndicator type="error" text="Error" />
        </Match>

        <Match when={props.status === 'ongoing'}>
          <SlideStatusIndicator type="ongoing" progressValue={props.progress} />
        </Match>
      </Switch>
    </Show>
  );
};

export const QcStatusIndicator: Component<StatusIndicatorProps> = (props) => {
  return (
    <Show when={props.status} fallback={<SlideStatusIndicator />}>
      <Switch>
        <Match when={props.status === 'done'}>
          <SlideStatusIndicator type="done" />
        </Match>

        <Match when={props.status === 'error'}>
          <Show
            when={props.details}
            fallback={<SlideStatusIndicator type="error" text="Error" />}
          >
            <SlideStatusIndicator
              type="error"
              text="QC failed"
              details={props.details}
            />
          </Show>
        </Match>

        <Match when={props.status === 'alert'}>
          <SlideStatusIndicator
            type="alert"
            text="QC Alert"
            details={props.details}
          />
        </Match>

        <Match when={props.status === 'ongoing'}>
          <SlideStatusIndicator type="ongoing" />
        </Match>
      </Switch>
    </Show>
  );
};

export const EmbeddingsStatusIndicator: Component<StatusIndicatorProps> = (
  props
) => {
  return (
    <Show when={props.status} fallback={<SlideStatusIndicator />}>
      <Switch>
        <Match when={props.status === 'done'}>
          <SlideStatusIndicator type="done" />
        </Match>

        <Match when={props.status === 'error'}>
          <SlideStatusIndicator
            type="error"
            text="Error"
            details={props.details}
          />
        </Match>

        <Match when={props.status === 'alert'}>
          <SlideStatusIndicator
            type="alert"
            text="Alert"
            details={props.details}
          />
        </Match>

        <Match when={props.status === 'ongoing'}>
          <SlideStatusIndicator type="ongoing" />
        </Match>
      </Switch>
    </Show>
  );
};
