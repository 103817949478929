export function EditIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33203 2.66714H2.66536C2.31174 2.66714 1.9726 2.80762 1.72256 3.05767C1.47251 3.30772 1.33203 3.64685 1.33203 4.00048V13.3338C1.33203 13.6874 1.47251 14.0266 1.72256 14.2766C1.9726 14.5267 2.31174 14.6671 2.66536 14.6671H11.9987C12.3523 14.6671 12.6915 14.5267 12.9415 14.2766C13.1916 14.0266 13.332 13.6874 13.332 13.3338V8.66714M12.332 1.66714C12.5972 1.40193 12.957 1.25293 13.332 1.25293C13.7071 1.25293 14.0668 1.40193 14.332 1.66714C14.5972 1.93236 14.7462 2.29207 14.7462 2.66714C14.7462 3.04222 14.5972 3.40193 14.332 3.66714L7.9987 10.0005L5.33203 10.6671L5.9987 8.00048L12.332 1.66714Z"
        stroke="#1E1E1E"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
