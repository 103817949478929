import { Component, Show, For, createSignal } from 'solid-js';
import { A } from '@solidjs/router';
import type { GetModel, KPIMetrics } from '@imagene/fm-studio-interfaces';

import { Typography } from '../../components/Typography';
import { HR } from '../../components/HR';
import { useDateFormat } from '../../utils/use-date-format';
import { GreenCheckmarkCircleIcon } from '../../components/icons/GreenCheckmarkCircleIcon';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { useToast } from '../../components/Toast';
import { AUCGraphMini } from '../Graphs/AUCGraphMini';
import { Badge, BadgeType } from '../../components/Badge';
import { EditIcon } from '../../components/icons/EditIcon';
import { Input } from '../../components/Inputs/Input';
import { Textarea } from '../../components/Inputs/Textarea';
import TempusLensURL from '../../assets/TempusLens.png';
import TempusLensDemoURL from '../../assets/TempusLensDemo.png';

import classes from './model-card.module.css';
import { Skeleton } from '../../components/Skeleton';

export type OnModelEdit = (details: {
  name: string;
  description: string | null;
}) => void;

interface ModelCardProps extends GetModel {
  route?: string;
  editable?: boolean;
  onEdit?: OnModelEdit;
  canCreate?: boolean;
}

interface ModelCardContentProps extends ModelCardProps {
  editing: boolean;
  setName: (name: string) => void;
  setDescription: (desc: string) => void;
  onToggleEditing: VoidFunction;
  onComplete?: VoidFunction;
}

export const ModelCard: Component<ModelCardProps> = (props) => {
  const [editing, setEditing] = createSignal(false);
  const [name, setName] = createSignal(props.name);
  const [description, setDescription] = createSignal(props.description);
  const toast = useToast();
  const onToggleEditMode = () => {
    if (editing()) {
      props.onEdit?.({ name: name(), description: description() });
    }
    setEditing((prev) => !prev);
  };

  const onCompleteClick = () => {
    toast.api.create({
      title: 'You have reached your trained models limit.',
      type: 'info',
    });
  };

  const link = () =>
    props.is_draft ? `${props.route}/wizard` : `${props.route}/overview`;

  return (
    <Show
      when={!props.editable}
      fallback={
        <ModelCardContent
          {...props}
          editing={editing()}
          name={name()}
          setName={setName}
          description={description()}
          setDescription={setDescription}
          onToggleEditing={onToggleEditMode}
        />
      }
    >
      <Card>
        <Show
          when={props.canCreate}
          fallback={
            <ModelCardContent
              {...props}
              editing={editing()}
              name={name()}
              setName={setName}
              description={description()}
              setDescription={setDescription}
              onToggleEditing={onToggleEditMode}
              onComplete={onCompleteClick}
            />
          }
        >
          <A href={link()} type="model-card">
            <ModelCardContent
              {...props}
              editing={editing()}
              name={name()}
              setName={setName}
              description={description()}
              setDescription={setDescription}
              onToggleEditing={onToggleEditMode}
            />
          </A>
        </Show>
      </Card>
    </Show>
  );
};

const ModelCardContent: Component<ModelCardContentProps> = (props) => (
  <div
    class={classes['model-card']}
    classList={{ [classes.editable]: props.editable }}
  >
    <ModelCardHeader {...props} />
    <HR fade />
    <Show
      when={!props.is_draft}
      fallback={<ModelCardDraftComplete onComplete={props.onComplete} />}
    >
      <ModelCardBody {...props} />
    </Show>
  </div>
);

const ModelCardHeader: Component<ModelCardContentProps> = (props) => {
  return (
    <div class={classes['model-card-header']}>
      <div class={classes.title}>
        <div class={classes.name}>
          <ModelName
            editing={props.editing}
            name={props.name}
            setName={props.setName}
          />
          <Show
            when={!props.is_draft}
            fallback={
              <>
                <Typography class={classes.draft} weight="bold">
                  (Draft)
                </Typography>
                <Badge
                  text={`Completed ${props.step ?? 0} out of 4 steps`}
                  big
                  type={BadgeType.SUCCESS}
                />
              </>
            }
          >
            <>
              <Show
                when={props.editable}
                fallback={<GreenCheckmarkCircleIcon />}
              >
                <EditBtn
                  editing={props.editing}
                  onToggleEditMode={props.onToggleEditing}
                />
              </Show>
            </>
          </Show>
        </div>
        <Show when={props.editable}>
          <ModelDescription
            editing={props.editing}
            description={props.description}
            setDescription={props.setDescription}
          />
        </Show>
      </div>
      <div style={{ 'margin-inline-start': 'auto' }}>
        {/* <div class={classes['overview-actions']}>
              <Button variant="secondary">Add to prediction "Panels"</Button>
              <Button variant="secondary">Re-run model</Button>
              <Button variant="secondary">
                <TrashCanIcon />
              </Button>
            </div> */}
        <Show
          when={!props.editable && !props.is_draft && props.last_trained_date}
        >
          {(trainDate) => (
            <Typography color="secondary" class={classes['train-date']}>
              Train date: {useDateFormat(trainDate())}
            </Typography>
          )}
        </Show>
      </div>
    </div>
  );
};

const ModelCardDraftComplete: Component<{ onComplete?: VoidFunction }> = (
  props
) => (
  <div class={classes['model-card-body-draft']}>
    <Typography weight="thin">
      Press the button to complete the model setup and start training
    </Typography>
    <Button onClick={props.onComplete}>Complete model setup</Button>
  </div>
);

const ModelCardBody: Component<ModelCardProps> = (props) => {
  const toast = useToast();

  const modelType = () => {
    switch (props.problem_type) {
      case 'binary':
        return 'Binary Classification';
      default:
        return 'Unknown';
    }
  };

  const validationNValue = () =>
    Math.ceil(
      (props.cohort_size ?? 0) *
        ((props.split_train_validation_percentage ?? 0) / 100)
    );

  const validationPercentage = () =>
    Math.ceil(props.split_train_validation_percentage ?? 0);

  const testNValue = () =>
    Math.floor(
      (props.cohort_size ?? 0) * ((props.split_test_percentage ?? 0) / 100)
    );

  const testPercentage = () => Math.floor(props.split_test_percentage ?? 0);

  const onTestClick = (e: MouseEvent) => {
    e.preventDefault();
    toast.api.create({
      title: 'Coming soon...',
    });
  };

  return (
    <div class={classes['model-card-body']}>
      <div class={classes.overview}>
        <Show
          when={!props.editable}
          fallback={
            <Typography color="secondary" weight="thin">
              Train date: {useDateFormat(props.last_trained_date ?? '')}
            </Typography>
          }
        >
          <A href={`${props.route}/results`}>
            <Button variant="secondary">Model results</Button>
          </A>
        </Show>
        <div class={classes['overview-items']}>
          <InfoItem
            label="Prediction target"
            items={props.prediction_column ? [props.prediction_column] : []}
          />
          <InfoItem
            label="Prediction labels"
            items={props.prediction_labels ?? []}
          />
          <InfoItem label="Model type" items={[modelType()]} />
          <InfoItem label="Cohort" items={[`N = ${props.cohort_size}`]} />
        </div>
      </div>
      <HR fade vertical />
      <div class={classes.kpi}>
        <ValidationGraph
          editable={props.editable}
          route={`${props.route}/kpi/validation`}
          data={props.metrics?.validation_metrics}
          nValue={validationNValue()}
          percentage={validationPercentage()}
        />
        <TestGraph
          editable={props.editable}
          route={`${props.route}/kpi/test`}
          disabled={Boolean(!props.metrics?.test_metrics)}
          data={props.metrics?.test_metrics}
          onClick={(e) => onTestClick(e)}
          nValue={testNValue()}
          percentage={testPercentage()}
        />
      </div>
      <HR fade vertical />
      <div class={classes.actions}>
        <Typography color="secondary">Use model to</Typography>
        <ModelActionButtons />
      </div>
    </div>
  );
};

const ModelActionButtons: Component = () => {
  const toast = useToast();

  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    toast.api.create({
      title: 'Coming soon...',
    });
  };

  return (
    <div class={classes.buttons}>
      <Button onClick={onClick}>Identify novel biomarkers</Button>
      <a href={TempusLensDemoURL} target="_blank">
        <Button variant="dark" fullWidth>
          Scout slides in External Database
          {/* Scout slides in <img src={TempusLensURL} /> */}
        </Button>
      </a>
      <Button onClick={onClick}>Get histological correlations</Button>
    </div>
  );
};
interface InfoItemProps {
  label: string;
  items: string[];
}

const InfoItem: Component<InfoItemProps> = (props) => (
  <div class={classes['info-item']}>
    <Typography>{props.label}</Typography>
    <Show
      when={props.items.length}
      fallback={<Typography color="secondary">N/A</Typography>}
    >
      <For each={props.items}>
        {(item) => (
          <Typography wrap="20ch" title={item}>
            {item}
          </Typography>
        )}
      </For>
    </Show>
  </div>
);

interface KPIGraphProps {
  editable: boolean | undefined;
  route: string;
  data?: KPIMetrics;
  nValue: number;
  percentage: number | null;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
}

const ValidationGraph: Component<KPIGraphProps> = (props) => (
  <div class={classes['validation-graph']}>
    <Show
      when={!props.editable}
      fallback={<Typography weight="thin">Validation KPI</Typography>}
    >
      <A href={props.route}>
        <Button variant="secondary">Validation KPIs</Button>
      </A>
    </Show>
    <div class={classes.graph}>
      <AUCGraphMini
        auc={props.data?.roc_auc}
        x={props.data?.fpr}
        y={props.data?.tpr}
      />
    </div>
    <Show when={props.percentage}>
      <Typography>
        N = {props.nValue} ({props.percentage}%)
      </Typography>
    </Show>
  </div>
);

const TestGraph: Component<KPIGraphProps> = (props) => (
  <div class={classes['test-graph']}>
    <Show
      when={!props.editable}
      fallback={<Typography weight="thin">Test KPI</Typography>}
    >
      <Show
        when={props.disabled}
        fallback={
          <A href={props.route}>
            <Button variant="secondary">Test KPIs</Button>
          </A>
        }
      >
        <Button variant="secondary" disabled>
          Test KPI
        </Button>
      </Show>
    </Show>
    <Show
      when={props.disabled}
      fallback={
        <div class={classes.graph}>
          <AUCGraphMini
            auc={props.data?.roc_auc}
            x={props.data?.fpr}
            y={props.data?.tpr}
          />
        </div>
      }
    >
      <Button onClick={props.onClick}>Test model</Button>
    </Show>
    <Show when={props.percentage}>
      <Typography>
        N = {props.nValue} ({props.percentage}%)
      </Typography>
    </Show>
  </div>
);

const EditBtn: Component<{
  editing: boolean;
  onToggleEditMode: VoidFunction;
}> = (props) => (
  <div class={classes.icon} onClick={props.onToggleEditMode}>
    <Show when={props.editing} fallback={<EditIcon />}>
      <Button variant="secondary" size="small" onClick={props.onToggleEditMode}>
        Save
      </Button>
    </Show>
  </div>
);

const ModelName: Component<{
  editing: boolean;
  name: string;
  setName: (name: string) => void;
}> = (props) => (
  <Show
    when={props.editing}
    fallback={
      <Typography weight="bold" component="h3">
        {props.name}
      </Typography>
    }
  >
    <Input
      value={props.name}
      onInput={(e) => props.setName(e.currentTarget.value)}
    />
  </Show>
);

const ModelDescription: Component<{
  editing: boolean;
  description: string | null;
  setDescription: (name: string) => void;
}> = (props) => (
  <Show
    when={props.editing}
    fallback={
      <Typography color="secondary">
        <Show when={props.description} fallback={'No description'}>
          {props.description}
        </Show>
      </Typography>
    }
  >
    <Textarea
      value={props.description ?? ''}
      placeholder="Add a description"
      onInput={(e) => props.setDescription(e.currentTarget.value)}
      rows={5}
    />
  </Show>
);

export const ModelCardSkeleton: Component = () => (
  <div class={classes.skeleton}>
    <div class={classes.skeleton_header}>
      <Skeleton width={400} height={30} />
      <Skeleton width={180} height={20} />
    </div>
    <HR fade />
    <div class={classes.skeleton_body}>
      <div class={classes.skeleton_overview}>
        <div>
          <Skeleton width={150} height={32} />
          <Skeleton width={150} height={32} />
          <Skeleton width={150} height={32} />
          <Skeleton width={150} height={32} />
        </div>
        <div>
          <Skeleton width={150} height={32} />
          <Skeleton width={150} height={32} />
        </div>
        <div>
          <Skeleton width={150} height={32} />
          <div style={{ height: '32px' }} />
        </div>
      </div>
      <HR vertical fade />
      <div class={classes.skeleton_kpis}>
        <div>
          <Skeleton width={118} height={32} />
          <Skeleton width={170} height={170} />
        </div>
        <div>
          <Skeleton width={118} height={32} />
          <Skeleton width={170} height={170} />
        </div>
      </div>
      <HR vertical fade />
      <div class={classes.skeleton_actions}>
        <Skeleton width={95} height={24} />
        <Skeleton width={278} height={40} />
        <Skeleton width={278} height={40} />
        <Skeleton width={278} height={40} />
      </div>
    </div>
  </div>
);
