import { Typography } from '../../../components/Typography';
import { Button } from '../../../components/Button';
import { XIcon } from '../../../components/icons/XIcon';
import { WarningIcon } from '../../../components/icons/WarningIcon';
import { Modal } from '../../../components/Modal';
import classes from './duplicate-modal.module.css';

interface MissingValueModalProps {
  rows: number[];
  onClose: VoidFunction;
  open: boolean;
  onConfirm: VoidFunction;
}

export function MissingValueModal(props: MissingValueModalProps) {
  return (
    <Modal open={props.open} disableOutsideClick>
      <div class={classes['duplicate-modal']}>
        <div class={classes['close-icon']} onClick={props.onClose}>
          <XIcon />
        </div>
        <WarningIcon />
        <Typography component="h2" weight="bold">
          Oops!
        </Typography>
        <Typography>
          We found missing values in {props.rows.length > 1 ? 'rows' : 'row'}{' '}
          {props.rows.join(' and ')}.
        </Typography>
        <Typography>
          Do you want to proceed data upload without these rows?
        </Typography>
        <div class={classes['modal-buttons']}>
          <Button variant="secondary" onClick={props.onClose}>
            Cancel upload
          </Button>
          <Button onClick={props.onConfirm}>
            Yes, proceed without missing values
          </Button>
        </div>
      </div>
    </Modal>
  );
}
