import { useLocation } from '@solidjs/router';

export function useTabs() {
  const location = useLocation();

  return location.pathname.slice(
    location.pathname.lastIndexOf('/') + 1,
    location.pathname.length
  );
}
