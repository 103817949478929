import { Component, createUniqueId } from 'solid-js';
import { Typography } from '../Typography';
import classes from './checkbox.module.css';

interface CheckboxProps {
  label: string;
  disabled?: boolean;
  checked?: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox: Component<CheckboxProps> = (props) => {
  const id = createUniqueId();
  const labelWeight = () => (props.checked ? 'semi-bold' : 'default');
  return (
    <div
      class={classes.checkbox}
      classList={{ [classes.disabled]: props.disabled }}
    >
      <input
        {...props}
        id={id}
        type="checkbox"
        onChange={(e) => props.onChange(e.currentTarget.checked)}
      />
      <label for={id}>
        <Typography weight={labelWeight()} wrap="15ch" title={props.label}>
          {props.label}
        </Typography>
      </label>
    </div>
  );
};
