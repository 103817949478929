import { Accessor, ParentProps, createContext, useContext } from 'solid-js';
import { useParams } from '@solidjs/router';
import { createQuery } from '@tanstack/solid-query';
import type { SingleProject } from '@imagene/fm-studio-interfaces';
import { useAxios } from './axios';

export interface SelectedProjectContext {
  selectedProject: Accessor<SingleProject | undefined>;
}

const SelectedProjectContext = createContext<SelectedProjectContext>(
  {} as unknown as SelectedProjectContext
);

export function useSelectedProject() {
  return useContext(SelectedProjectContext);
}

export function SelectedProjectProvider(props: ParentProps) {
  const axios = useAxios();

  const params = useParams();
  const projectQuery = createQuery(
    () => ['projects', params.slug],
    () =>
      axios
        .get<SingleProject>(`projects/${params.slug}`)
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      get enabled() {
        return Boolean(params.slug);
      },
    }
  );

  return (
    <SelectedProjectContext.Provider
      value={{
        selectedProject: () => projectQuery.data,
      }}
    >
      {props.children}
    </SelectedProjectContext.Provider>
  );
}
