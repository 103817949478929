import { Component } from 'solid-js';
import classes from './skeleton.module.css';

interface SkeletonProps {
  width?: number;
  height?: number;
  variant?: 'rectangle' | 'circle';
}

const DEFAULT_SIZE = '100%';

export const Skeleton: Component<SkeletonProps> = (props) => {
  const height = () =>
    props.height
      ? `${props.variant === 'circle' ? props.width : props.height}px`
      : DEFAULT_SIZE;

  return (
    <div
      class={classes.skeleton}
      classList={{ [classes.circle]: props.variant === 'circle' }}
      style={{
        width: props.width ? `${props.width}px` : DEFAULT_SIZE,
        height: height(),
      }}
    />
  );
};
