export function CheckmarkCircleIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8856 11.5259V12.4829C22.8843 14.7262 22.1579 16.909 20.8147 18.7057C19.4716 20.5024 17.5836 21.8168 15.4324 22.4528C13.2812 23.0889 10.982 23.0125 8.87773 22.2351C6.77347 21.4577 4.97689 20.0209 3.75592 18.139C2.53496 16.2571 1.95503 14.0309 2.10263 11.7925C2.25023 9.5541 3.11745 7.42337 4.57495 5.71809C6.03245 4.01282 8.00214 2.82437 10.1903 2.32999C12.3784 1.83561 14.6677 2.0618 16.7168 2.97481"
        stroke="#4777BE"
        stroke-width="2.08055"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.8869 4.16113L12.4841 14.5743L9.36328 11.4535"
        stroke="#4777BE"
        stroke-width="2.08055"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
