import { Button } from '../../../components/Button';
import { XIcon } from '../../../components/icons/XIcon';
import { SelectInput } from '../../../components/Inputs/SelectInput';
import { Modal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';
import { createSignal } from 'solid-js';
import classes from './labels.module.css';

interface SelectColumnModalProps {
  open: boolean;
  onClose: VoidFunction;
  onConfirm: () => void;
  onSelect: (column: string) => void;
  columns: string[];
  selected?: string;
}

export function SelectColumnModal(props: SelectColumnModalProps) {
  const [isSelectOpen, setSelectOpen] = createSignal(false);

  const selectedColumn = () => (props.selected ? [props.selected] : []);

  return (
    <Modal open={props.open} disableOutsideClick>
      <div class={classes['select-column-modal']}>
        <div class={classes['close-icon']} onClick={props.onClose}>
          <XIcon />
        </div>
        <Typography weight="bold">
          Please select the column with filenames
        </Typography>
        <div class={classes['select-column-input']}>
          <Typography>
            Select a column with{' '}
            <Typography class={classes.highlight} weight="bold">
              filenames of Whole Slide Images.
            </Typography>{' '}
            The system will use it to match data to the uploaded slides
          </Typography>
          <div class={classes.select}>
            <SelectInput
              value={selectedColumn()}
              onValueChange={(details) => props.onSelect(details.value[0])}
              options={props.columns}
              open={isSelectOpen()}
              onOpenChange={(details) => setSelectOpen(details.open)}
            />
          </div>
        </div>
        <div class={classes['modal-buttons']}>
          <Button variant="secondary" onClick={props.onClose}>
            Cancel
          </Button>
          <Button onClick={props.onConfirm}>Confirm filename column</Button>
        </div>
      </div>
    </Modal>
  );
}
