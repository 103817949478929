import { StudioSlide } from '@imagene/fm-studio-interfaces';
import { ColumnDef } from '@tanstack/solid-table';
import { Accessor } from 'solid-js';
import {
  EmbeddingsStatusIndicator,
  MatchingStatusIndicator,
  QcStatusIndicator,
  UploadStatusIndicator,
} from '.';

type SlidesTableColumDef = ColumnDef<StudioSlide>[];
export type SlidesTableColumns = Accessor<SlidesTableColumDef>;

export const useSlidesTableColumns = (): SlidesTableColumns => {
  const columns = (): SlidesTableColumDef => [
    {
      header: '',
      id: 'id',
      accessorFn: (_, idx) => idx + 1,
      meta: {
        semiBold: true,
      },
      size: 50,
    },
    {
      header: 'File name',
      accessorKey: 'fileName',
      meta: {
        semiBold: true,
      },
      size: 260,
    },
    {
      header: 'Data matching',
      accessorKey: 'dataMatching',
      cell: (info) => (
        <MatchingStatusIndicator matched={info.getValue<boolean>()} />
      ),
      size: 200,
    },
    {
      header: 'Upload status',
      accessorFn: (row) => ({
        status: row.uploadStatus,
        progress: row.uploadProgress,
      }),
      cell: (info) => {
        const { status, progress } = info.getValue<{
          status: string;
          progress: number;
        }>();
        return <UploadStatusIndicator status={status} progress={progress} />;
      },
      size: 200,
    },
    {
      header: 'QC status',
      accessorFn: (row) => ({ status: row.qcStatus, details: row.qcDetails }),
      cell: (info) => {
        const { status, details } = info.getValue<{
          status: string;
          details: string;
        }>();
        return <QcStatusIndicator status={status} details={details} />;
      },
      id: 'qc',
      size: 200,
    },
    {
      header: 'Embedding status',
      accessorKey: 'embeddingsStatus',
      cell: (info) => (
        <EmbeddingsStatusIndicator status={info.getValue<string>()} />
      ),
      size: 200,
    },
  ];

  return columns;
};
