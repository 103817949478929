import { JSXElement, Show } from 'solid-js';
import classes from './sidebar.module.css';
import { A } from '@solidjs/router';
import { Typography } from '../../components/Typography';
import { Tooltip } from '../../components/Tooltip';

interface SidebarItemProps {
  route: string;
  text: string;
  icon?: JSXElement;
  collapsed?: boolean;
}

export function SidebarItem(props: SidebarItemProps) {
  return (
    <A
      href={props.route}
      class={classes.item}
      activeClass={classes.active}
      classList={{
        [classes['item-collapsed']]: props.collapsed,
      }}
      end
    >
      {props.icon}
      <Show when={!props.collapsed}>
        <Tooltip text={props.text}>
          <Typography>{props.text}</Typography>
        </Tooltip>
      </Show>
    </A>
  );
}
