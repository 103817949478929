export function HomepageIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5 11.6875C23.5 11.4531 23.3828 11.1797 23.1484 10.9844L12.8359 2.23438C12.6797 2.07812 12.4453 2 12.25 2C12.0156 2 11.7812 2.07812 11.625 2.23438L1.3125 10.9844C1.07812 11.1797 1 11.4531 1 11.6875C1 12.3516 1.50781 12.625 1.9375 12.625C2.13281 12.625 2.36719 12.5859 2.52344 12.4297L3.5 11.6094V20.4375C3.5 21.3359 4.16406 22 5.0625 22H8.8125C9.67188 22 10.375 21.3359 10.375 20.4375V15.75H14.125V20.4375C14.125 21.3359 14.7891 22 15.6484 22H19.3984C20.2969 22 20.9609 21.3359 20.9609 20.4375V11.6094L21.9375 12.4297C22.0938 12.5859 22.3281 12.625 22.5234 12.625C22.9531 12.625 23.5 12.3125 23.5 11.6875ZM19.0859 20.125H15.9609V15.4375C15.9609 14.5781 15.2969 13.875 14.4375 13.875H10.0625C9.16406 13.875 8.5 14.5781 8.5 15.4375V20.125H5.375V10.125C5.375 10.125 5.33594 10.0859 5.33594 10.0469L12.25 4.1875L19.0859 10.0078V20.125Z"
        fill="currentColor"
      />
    </svg>
  );
}
