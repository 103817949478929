export function GalleryViewIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 2.99719C1.25 1.75455 2.25736 0.747192 3.5 0.747192H5.75C6.99264 0.747192 8 1.75455 8 2.99719V5.24719C8 6.48983 6.99264 7.49719 5.75 7.49719H3.5C2.25736 7.49719 1.25 6.48983 1.25 5.24719V2.99719Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.25 12.7472C1.25 11.5046 2.25736 10.4972 3.5 10.4972H5.75C6.99264 10.4972 8 11.5046 8 12.7472V14.9972C8 16.2398 6.99264 17.2472 5.75 17.2472H3.5C2.25736 17.2472 1.25 16.2398 1.25 14.9972V12.7472Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 2.99719C11 1.75455 12.0074 0.747192 13.25 0.747192H15.5C16.7426 0.747192 17.75 1.75455 17.75 2.99719V5.24719C17.75 6.48983 16.7426 7.49719 15.5 7.49719H13.25C12.0074 7.49719 11 6.48983 11 5.24719V2.99719Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 12.7472C11 11.5046 12.0074 10.4972 13.25 10.4972H15.5C16.7426 10.4972 17.75 11.5046 17.75 12.7472V14.9972C17.75 16.2398 16.7426 17.2472 15.5 17.2472H13.25C12.0074 17.2472 11 16.2398 11 14.9972V12.7472Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
