import classes from './slides-upload.module.css';
import { Card } from '../../components/Card';
import { UploadProgress } from '../UploadProgress';
import { Component, Show } from 'solid-js';
import { SlidesGallery } from '../SlidesGallery';
import { SlidesUploadTable } from '../SlidesUploadTable';
import { DrawerCollapseIcon } from '../../components/icons/DrawerCollapseIcon';
import type { StudioSlide } from '@imagene/fm-studio-interfaces';
import { NoSlides } from '../../pages/projects/Slides';

export type SlidesViewMode = 'list' | 'gallery';

interface SlidesUploadDrawerProps {
  total: number;
  completed: number;
  queued: number;
  mode: SlidesViewMode;
  collapsed: boolean;
  onCollapse: VoidFunction;
  slides: StudioSlide[];
  uploads: StudioSlide[];
  onAddFiles: (files: File[]) => void;
}

export const SlidesUploadDrawer: Component<SlidesUploadDrawerProps> = (
  props
) => (
  <Card noElevation={props.collapsed}>
    <div
      class={classes['slides-upload-drawer']}
      classList={{ [classes.collapsed]: props.collapsed }}
    >
      <div class={classes['drawer-header']}>
        <UploadProgress
          total={props.total}
          completed={props.completed}
          queued={props.queued}
          collapsed={props.collapsed}
        />
        <div class={classes['collapse-icon']} onClick={props.onCollapse}>
          <DrawerCollapseIcon />
        </div>
      </div>
      <Show when={!props.collapsed}>
        <Show
          when={props.mode === 'gallery'}
          fallback={<SlidesUploadTable slides={props.uploads} />}
        >
          <Show
            when={props.slides.length}
            fallback={<NoSlides addFiles={props.onAddFiles} />}
          >
            <SlidesGallery slides={props.slides} />
          </Show>
        </Show>
      </Show>
    </div>
  </Card>
);
