import { Component, For, ParentProps } from 'solid-js';
import { ProjectCard, ProjectCardSkeleton } from '../ProjectCard';
import type {
  ProjectsResponse,
  ProjectResponse,
} from '@imagene/fm-studio-interfaces';
import classes from './projects-deck.module.css';
import { NewProjectCard } from './NewProjectCard';
import { Skeleton } from '../../components/Skeleton';

interface ProjectsDeckProps {
  projects: ProjectsResponse;
  onNewProject: VoidFunction;
  onProjectClick: (selected: ProjectResponse) => void;
}

export function ProjectsDeck(props: ParentProps<ProjectsDeckProps>) {
  return (
    <div class={classes['projects-deck']}>
      <NewProjectCard onNewProject={props.onNewProject} />
      <For each={props.projects}>
        {(project) => (
          <ProjectCard
            {...project}
            isSample={project.is_example}
            updatedAt={project.updated_at}
            cohort={project.cohort_count}
            models={project.models_count}
            dataColumns={project.columns_count}
            onClick={() => props.onProjectClick(project)}
          />
        )}
      </For>
    </div>
  );
}

export const ProjectDeckSkeleton: Component = () => (
  <div class={classes.skeleton}>
    <div class={classes.skeleton_new_project}>
      <Skeleton />
      <Skeleton />
      <Skeleton width={170} />
    </div>
    <ProjectCardSkeleton />
    <ProjectCardSkeleton />
  </div>
);
