export function DrawerCollapseIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="31.5"
        y="31.5"
        width="31"
        height="31"
        rx="3.5"
        transform="rotate(-180 31.5 31.5)"
        stroke="#E5E9F0"
      />
      <path
        d="M15.4844 19.9062L9.39063 13.8437C9.10938 13.5312 9.10938 13.0625 9.39063 12.7812L10.1094 12.0625C10.3906 11.7812 10.8594 11.7812 11.1719 12.0625L15.9844 16.875L20.8281 12.0625C21.1406 11.7812 21.6094 11.7812 21.8906 12.0625L22.6094 12.7812C22.8906 13.0625 22.8906 13.5312 22.6094 13.8437L16.5156 19.9062C16.2344 20.1875 15.7656 20.1875 15.4844 19.9062Z"
        fill="#A6A7A6"
      />
    </svg>
  );
}
