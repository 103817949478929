import { createEffect, createSignal, onCleanup, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useParams } from '@solidjs/router';
import { Tab, Tabs } from '../../components/Tabs';
import { CubesIcon } from '../../components/icons/CubesIcon';
import { LabelsIcon } from '../../components/icons/LabelsIcon';
import { SlidesIcon } from '../../components/icons/SlidesIcon';
import { useBreadcrumbs } from '../../utils/use-breadcrumbs';
import { useSelectedProject } from '../../utils/use-selected-project';
import { useTabs } from '../../utils/use-tabs';

type ProjectPageTabs = 'models' | 'labels' | 'slides';
export type ProjectPageModelTabs = 'overview' | 'setup' | 'results';

export function ProjectPage() {
  const params = useParams();
  const { setBreadcrumbs } = useBreadcrumbs();
  const project = useSelectedProject();
  const [tab, setTab] = createSignal<ProjectPageTabs>(
    params.tab as ProjectPageTabs
  );

  const projectRelativePath = `/projects/${params.slug}`;
  const [tabs, setTabs] = createStore<Tab[]>([
    {
      id: 'models',
      label: 'Models',
      icon: <CubesIcon />,
      route: `${projectRelativePath}/models`,
    },
    {
      id: 'labels',
      label: 'Data labels',
      icon: <LabelsIcon />,
      route: `${projectRelativePath}/labels`,
    },
    {
      id: 'slides',
      label: 'Slides',
      icon: <SlidesIcon />,
      route: `${projectRelativePath}/slides`,
    },
  ]);

  createEffect(() => {
    if (params.modelSlug || params.modelTab) {
      setTab('models');
    } else {
      const tab = useTabs();
      setTab(tab as ProjectPageTabs);
    }

    const selected = project.selectedProject();
    if (selected) {
      setBreadcrumbs([
        { text: 'Projects', active: false, route: '/projects' },
        { text: selected?.name, active: true },
      ]);

      // set data labels tab name
      setTabs(
        1,
        'label',
        selected.data_source === 'INTERNAL' ? 'Data labels' : 'TCGA cohort'
      );
    }
  });

  onCleanup(() => setBreadcrumbs([]));

  return (
    <Show when={project.selectedProject()}>
      <Tabs value={tab()} tabs={tabs} />
    </Show>
  );
}
