import {
  For,
  JSXElement,
  ParentProps,
  Show,
  createMemo,
  createUniqueId,
  splitProps,
} from 'solid-js';
import { normalizeProps, useMachine } from '@zag-js/solid';
import * as tabs from '@zag-js/tabs';
import { Typography } from '../Typography';
import classes from './tabs.module.css';
import { A, Outlet } from '@solidjs/router';

export interface Tab {
  id: string;
  label: string;
  route?: string;
  disabled?: boolean;
  icon?: JSXElement;
}

export interface TabsProps {
  tabs: Tab[];
  value?: string;
  onTabSelect?: (tabId: string) => void;
  fitContent?: boolean;
}

export function Tabs(props: ParentProps<TabsProps>) {
  const [context, local] = splitProps(props, ['value']);
  const [state, send] = useMachine(
    tabs.machine({
      id: createUniqueId(),
      activationMode: 'manual',
      onValueChange: (details) => props.onTabSelect?.(details.value),
    }),
    { context }
  );

  const api = createMemo(() => tabs.connect(state, send, normalizeProps));

  return (
    <div {...api().getRootProps()}>
      <div
        {...api().getListProps()}
        classList={{ [classes['fit-content']]: props.fitContent }}
      >
        <For each={local.tabs}>
          {(item) => (
            <Show
              when={item.route}
              fallback={
                <div class={classes.tab}>
                  <button
                    {...api().getTriggerProps({
                      value: item.id,
                      disabled: item.disabled,
                    })}
                  >
                    <Show when={item.icon}>{item.icon}</Show>
                    <Typography>{item.label}</Typography>
                  </button>
                </div>
              }
            >
              {(route) => (
                <A href={route()} class={classes.tab}>
                  <button
                    {...api().getTriggerProps({
                      value: item.id,
                      disabled: item.disabled,
                    })}
                  >
                    <Show when={item.icon}>{item.icon}</Show>
                    <Typography>{item.label}</Typography>
                  </button>
                </A>
              )}
            </Show>
          )}
        </For>
      </div>
      <Outlet />
    </div>
  );
}
