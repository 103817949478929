import { Card } from '../../../components/Card';
import { Component, For, Show } from 'solid-js';
import classes from './model-summary.module.css';
import { Typography } from '../../../components/Typography';
import { Button } from '../../../components/Button';
import { PlayIcon } from '../../../components/icons/PlayIcon';
import { HR } from '../../../components/HR';

interface ModelSummaryProps {
  name: string;
  description: string;
  prediction_target: string;
  prediction_labels: string[];
  input_data: string[];
  disabled: boolean;
  onClick: VoidFunction;
  onBack: VoidFunction;
}

export const ModelSummary: Component<ModelSummaryProps> = (props) => {
  return (
    <Card>
      <div class={classes['model-summary']}>
        <div class={classes['model-summary-card']}>
          <Typography component="h3" weight="bold">
            {props.name}
          </Typography>
          <Typography color="secondary" class={classes.description}>
            <Show when={props.description} fallback={'No description'}>
              {props.description}
            </Show>
          </Typography>
          <HR fade />
          <Typography>Overview</Typography>
          <div class={classes.overview}>
            <OverviewItem
              label="Prediction target"
              items={[props.prediction_target]}
            />
            <OverviewItem
              label="Prediction labels"
              items={props.prediction_labels}
            />
            <HR fade vertical />
            {/* <OverviewItem label="Input data" items={props.input_data} /> */}
          </div>
        </div>

        <div class={classes.actions}>
          <Button variant="secondary" onClick={props.onBack}>
            Back
          </Button>

          <Button disabled={props.disabled} onClick={props.onClick}>
            Start training <PlayIcon />
          </Button>
        </div>
      </div>
    </Card>
  );
};

interface OverviewItemProps {
  label: string;
  items: string[];
}

const OverviewItem: Component<OverviewItemProps> = (props) => {
  return (
    <div class={classes['overview-item']}>
      <Typography>{props.label}</Typography>
      <For each={props.items}>
        {(item) => <Typography weight="bold">{item}</Typography>}
      </For>
    </div>
  );
};
