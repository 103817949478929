import { Component, Show, For, Suspense } from 'solid-js';
import { A, Outlet, useNavigate } from '@solidjs/router';
import classes from './models.module.css';
import { SearchInput } from '../../../components/Inputs/SearchInput';
import { Card } from '../../../components/Card';
import { Typography } from '../../../components/Typography';
import { Button } from '../../../components/Button';
import { useModels } from './use-models';
import {
  ModelCard,
  ModelCardSkeleton,
} from '../../../business-components/ModelCard';
import { WhitePlusIcon } from '../../../components/icons/WhitePlusIcon';
import { useSelectedProject } from '../../../utils/use-selected-project';
import { TrialLimit } from '../../../business-components/TrialLimit';
import { useTrialQuota } from '../../../utils/use-trial-quota';
import { useToast } from '../../../components/Toast';

export function ModelsPage() {
  const { searchTerm, setSearchTerm, model } = useModels();

  return (
    <div class={classes['models-tab']}>
      <div
        class={classes['models-tab-header']}
        classList={{ [classes['with-title']]: Boolean(model()) }}
      >
        <Show when={model()}>
          {(model) => (
            <Typography component="h2" weight="bold">
              {model().name}
            </Typography>
          )}
        </Show>
        {/* <SearchInput value={searchTerm()} onSearch={setSearchTerm} /> */}
      </div>
      <Outlet />
    </div>
  );
}

export const ModelsList: Component = () => {
  const { models, matching, projectSlug, createModel } = useModels();
  const hasMatching = () => Boolean(matching()?.has_minimum_slides);
  const hasMinimumSlides = () => Boolean(matching()?.has_minimum_slides);
  const navigate = useNavigate();
  const projectRelativePath = () => `/projects/${projectSlug()}`;
  const { canCreateModel, canCreateTCGAModel, modelsLimit, tcgaModelsLimit } =
    useTrialQuota();
  const toast = useToast();

  const onCreateModel = async () => {
    if (canCreateNewModel()) {
      const model = await createModel.mutateAsync();
      navigate(`${projectRelativePath()}/models/${model.slug}/wizard`);
    } else {
      toast.api.create({
        title: `You have used all ${
          isTCGA() ? tcgaModelsLimit() : modelsLimit()
        } free models`,
        type: 'info',
      });
    }
  };

  const project = useSelectedProject();
  const isTCGA = () => project.selectedProject()?.data_source === 'TCGA';

  const canCreateNewModel = () =>
    (isTCGA() && canCreateTCGAModel()) || (!isTCGA() && canCreateModel());

  return (
    <>
      <div class={classes['models-limit']}>
        <TrialLimit mini mode="models" />
      </div>
      <Card noElevation>
        <div class={classes.models}>
          <div class={classes['models-header']}>
            <Typography component="h1" weight="bold">
              Models list
            </Typography>

            <Show when={hasMatching() && hasMinimumSlides() && models().length}>
              <Button onClick={onCreateModel}>
                <WhitePlusIcon />
                Train new model
              </Button>
            </Show>
          </div>
          <Suspense fallback={<ModelCardSkeleton />}>
            <Show
              when={models().length}
              fallback={
                <ModelsTrainingWelcome
                  hasMatching={Boolean(matching()?.has_match)}
                  matchingSlides={matching()?.num_of_slides ?? 0}
                  minimumSlides={matching()?.minimum_slides ?? 0}
                  hasMinimumSlides={hasMinimumSlides()}
                  onCreateModel={onCreateModel}
                  projectRelPath={projectRelativePath()}
                  isTCGA={isTCGA()}
                />
              }
            >
              <div class={classes.list}>
                <For each={models()}>
                  {(model) => (
                    <ModelCard
                      {...model}
                      canCreate={canCreateNewModel()}
                      route={`/projects/${projectSlug()}/models/${model.slug}`}
                    />
                  )}
                </For>
              </div>
            </Show>
          </Suspense>
        </div>
      </Card>
    </>
  );
};

interface ModelsTrainingWelcomeProps {
  hasMatching: boolean;
  hasMinimumSlides: boolean;
  minimumSlides: number;
  matchingSlides: number;
  projectRelPath: string;
  onCreateModel: VoidFunction;
  isTCGA: boolean;
}

export const ModelsTrainingWelcome: Component<ModelsTrainingWelcomeProps> = (
  props
) => {
  const minimumMsg = () =>
    !props.matchingSlides ? 'have no' : `only have ${props.matchingSlides}`;
  return (
    <div class={classes['models-training-welcome']}>
      <Show
        when={props.hasMatching && props.hasMinimumSlides}
        fallback={
          <>
            <Typography weight="bold">
              This is your home base, here you can see all created models and
              their details.
            </Typography>
            <Show
              when={props.isTCGA}
              fallback={
                <>
                  <Typography>
                    Please upload{' '}
                    <A href={`${props.projectRelPath}/labels`}>data labels</A>{' '}
                    and <A href={`${props.projectRelPath}/slides`}>slides</A> in
                    order to train a new model.
                  </Typography>
                  <A
                    href={`${props.projectRelPath}/labels`}
                    class={classes['link-btn']}
                  >
                    <Button>Go to 'Data labels' tab</Button>
                  </A>
                </>
              }
            >
              <>
                <Typography>
                  Please define{' '}
                  <A href={`${props.projectRelPath}/tcga`}>TCGA Cohort</A> first
                  in order to train a new model.
                </Typography>
                <A
                  href={`${props.projectRelPath}/tcga`}
                  class={classes['link-btn']}
                >
                  <Button>Go to 'TCGA Cohort' tab</Button>
                </A>
              </>
            </Show>
            <Typography color="secondary" weight="thin">
              You {minimumMsg()} matching slides, you need at least{' '}
              {props.minimumSlides} to start training.
            </Typography>
          </>
        }
      >
        <Show
          when={props.isTCGA}
          fallback={
            <Typography weight="bold">
              Your <A href={`${props.projectRelPath}/labels`}>data labels</A>{' '}
              and <A href={`${props.projectRelPath}/slides`}>slides</A> were
              successfully uploaded. You can now proceed with the 'Train a
              model' flow.
            </Typography>
          }
        >
          <Typography weight="bold">
            Your <A href={`${props.projectRelPath}/tcga`}>TCGA Cohort</A> was
            successfully defined. You can now proceed with the 'Train a model'
            flow.
          </Typography>
        </Show>
        <Button onClick={props.onCreateModel}>Train new model</Button>
      </Show>
    </div>
  );
};
