export function WarningIcon() {
  return (
    <svg
      width="42"
      height="38"
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.5394 32.6562L23.9928 1.78125C23.3368 0.59375 22.1889 0 20.959 0C19.8111 0 18.6632 0.59375 17.9252 1.78125L0.460575 32.6562C-0.851324 35.0312 0.78855 38 3.49434 38H38.5057C41.2114 38 42.8513 35.0312 41.5394 32.6562ZM4.31428 33.9286L20.959 4.49554L37.6857 33.9286H4.31428ZM21.041 25.9554C19.5651 25.9554 18.4172 27.1429 18.4172 28.5848C18.4172 30.0268 19.5651 31.2143 21.041 31.2143C22.4349 31.2143 23.5828 30.0268 23.5828 28.5848C23.5828 27.1429 22.4349 25.9554 21.041 25.9554ZM19.0731 12.8929V21.0357C19.0731 22.2232 19.8931 23.0714 21.041 23.0714C22.1069 23.0714 23.0088 22.2232 23.0088 21.0357V12.8929C23.0088 11.7902 22.1069 10.8571 21.041 10.8571C19.8931 10.8571 19.0731 11.7902 19.0731 12.8929Z"
        fill="#F9C134"
      />
    </svg>
  );
}
