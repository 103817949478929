import { useNavigate } from '@solidjs/router';
import {
  createMutation,
  createQuery,
  useQueryClient,
} from '@tanstack/solid-query';
import { createSignal, Show, Suspense } from 'solid-js';
import { useAxios } from '../../utils/axios';
import type {
  ProjectsResponse,
  CreateProjectDto,
  ProjectDataSource,
  ProjectResponse,
} from '@imagene/fm-studio-interfaces';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Typography } from '../../components/Typography';
import classes from './projects.module.css';
import {
  ProjectDeckSkeleton,
  ProjectsDeck,
} from '../../business-components/ProjectsDeck';
import { CreateProjectModal } from './CreateProjectModal';
import { TrialInfoCard } from '../../business-components/TrialInfoCard';
import { isAxiosError } from 'axios';
export function Projects() {
  const axios = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = createSignal(false);
  const [error, setError] = createSignal('');
  const projectsQuery = createQuery(
    () => ['projects'],
    () => axios.get<ProjectsResponse>('/projects').then((res) => res.data)
  );

  const createProject = createMutation(
    (dto: CreateProjectDto) =>
      axios.post('/projects', dto).then((res) => res.data),
    {
      onSuccess: (data) => {
        setIsOpen(false);
        queryClient.invalidateQueries({ queryKey: ['projects'] });
        queryClient.invalidateQueries({ queryKey: ['quota'] });
        navigate(`/projects/${data.slug}/models`);
      },
      onError: (e) => {
        if (isAxiosError(e)) {
          if (e.response?.status === 400) {
            setError('Project name already exists');
          }
        }
      },
    }
  );

  const onProjectClick = (selected: ProjectResponse) => {
    navigate(`/projects/${selected.slug}/models`);
  };

  return (
    <>
      <Card noElevation>
        <div class={classes.projects}>
          <Typography component="h1" weight="semi-bold">
            My Projects
          </Typography>
          <Suspense fallback={<ProjectDeckSkeleton />}>
            <Show
              when={projectsQuery.data?.length}
              fallback={<NoProjects onCreate={() => setIsOpen(true)} />}
            >
              <ProjectsDeck
                projects={projectsQuery.data ?? []}
                onNewProject={() => setIsOpen(true)}
                onProjectClick={onProjectClick}
              />
            </Show>
          </Suspense>

          <CreateProjectModal
            open={isOpen()}
            onClose={() => {
              setIsOpen(false);
              setError('');
            }}
            disabled={createProject.isLoading}
            error={error()}
            onCreate={(details) =>
              createProject.mutate({
                name: details.project,
                data_source: details.dataSource as ProjectDataSource,
              })
            }
          />
        </div>
      </Card>
      <div class={classes['trial-info']}>
        <TrialInfoCard />
      </div>
    </>
  );
}

function NoProjects(props: { onCreate: VoidFunction }) {
  return (
    <div class={classes['no-projects']}>
      <Typography weight="bold" component="h3">
        You do not have any projects yet.
      </Typography>
      <Button onClick={props.onCreate}>Create new project</Button>
    </div>
  );
}
