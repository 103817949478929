import { useActor, useMachine, normalizeProps } from '@zag-js/solid';
import * as toast from '@zag-js/toast';
import {
  createMemo,
  createUniqueId,
  createContext,
  useContext,
  For,
  ParentComponent,
  Component,
  Show,
} from 'solid-js';
import { Portal } from 'solid-js/web';
import { XIcon } from '../icons/XIcon';
import { GreenCheckmarkCircleIcon } from '../icons/GreenCheckmarkCircleIcon';
import { ErrorIcon } from '../icons/ErrorIcon';
import { Typography } from '../Typography';
import classes from './toast.module.css';

interface ToastProps {
  actor: toast.Service;
}

//TODO: fix types and props?

// 1. Create the single toast
export const Toast: Component<ToastProps> = (props) => {
  const [state, send] = useActor(props.actor);
  const api = createMemo(() => toast.connect(state, send, normalizeProps));

  const blackIcon = () => api().type === 'success' || api().type === 'error';

  return (
    <div {...api().getRootProps()}>
      <Show when={api().type === 'success'}>
        <GreenCheckmarkCircleIcon />
      </Show>
      <Show when={api().type === 'error'}>
        <ErrorIcon />
      </Show>
      <Typography {...api().getTitleProps()} color="default">
        {api().title}
      </Typography>
      <Show when={api().description}>
        <Typography {...api().getDescriptionProps()} color="default">
          {api().description}
        </Typography>
      </Show>
      <div
        class={classes['close-icon']}
        classList={{ [classes.black]: blackIcon() }}
        onClick={api().dismiss}
      >
        <XIcon />
      </div>
    </div>
  );
};

interface ToastsContext {
  api: toast.GroupApi;
  overlapApi: toast.GroupApi;
}

// 2. Create the toast context
const ToastContext = createContext<ToastsContext>({} as ToastsContext);
export const useToast = () => useContext(ToastContext);

export const ToastProvider: ParentComponent = (props) => {
  const [state, send] = useMachine(
    toast.group.machine({ id: createUniqueId() })
  );

  const [overlapState, overlapSend] = useMachine(
    toast.group.machine({ id: createUniqueId(), overlap: true, max: 2 })
  );

  const api = createMemo(() =>
    toast.group.connect(state, send, normalizeProps)
  );

  const overlapApi = createMemo(() =>
    toast.group.connect(overlapState, overlapSend, normalizeProps)
  );

  return (
    <ToastContext.Provider value={{ api: api(), overlapApi: overlapApi() }}>
      <Portal>
        <For each={api().getPlacements()}>
          {(placement) => (
            <div {...api().getGroupProps({ placement })}>
              <For each={api().getToastsByPlacement(placement)}>
                {(toast) => <Toast actor={toast} />}
              </For>
            </div>
          )}
        </For>

        <For each={overlapApi().getPlacements()}>
          {(placement) => (
            <div {...overlapApi().getGroupProps({ placement })}>
              <For each={overlapApi().getToastsByPlacement(placement)}>
                {(toast) => <Toast actor={toast} />}
              </For>
            </div>
          )}
        </For>
      </Portal>
      {props.children}
    </ToastContext.Provider>
  );
};
