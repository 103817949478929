import { ParentComponent, For } from 'solid-js';
import { Card } from '../../components/Card';
import classes from './cohort-wizard.module.css';
import { Typography } from '../../components/Typography';
import { TCGAColumnCard } from './ColumnCard/TCGAColumnCard';
import { DefineCohortColumn } from './DefineCohort';
import { useTCGAWizard } from './use-tcga-wizard';

interface DefineColumnsProps {
  columns: DefineCohortColumn[];
}

export const DefineColumns: ParentComponent<DefineColumnsProps> = (props) => {
  const {
    onAddColumn,
    onRemoveColumn,
    columns,
    selectEntireColumn,
    removeEntireColumn,
  } = useTCGAWizard();

  const onLabelSelect = (value: string, checked: boolean, column: string) => {
    if (checked) {
      onAddColumn({ column, values: [value] });
    } else {
      onRemoveColumn(column);
    }
  };

  const onSelectAll = (values: string[], column: string, checked: boolean) => {
    if (checked) {
      selectEntireColumn({ column, values });
    } else {
      removeEntireColumn(column);
    }
  };

  const columnList = () =>
    props.columns.map((c) => ({
      ...c,
      showSelectAll: !c.title?.toLowerCase()?.includes('gene'),
    }));

  return (
    <Card>
      <div class={classes['define-columns']}>
        <Typography weight="bold">
          Selected Columns: {columns().length}
        </Typography>

        <div class={classes.columns}>
          <For each={columnList()}>
            {(column) => (
              <TCGAColumnCard
                {...column}
                searchable={column.title.toLowerCase().endsWith('genes')}
                onLabelSelect={onLabelSelect}
                onSelectAll={onSelectAll}
                columns={columns()}
                mode="columns"
              />
            )}
          </For>
        </div>
        {props.children}
      </div>
    </Card>
  );
};
