export function ArrowIcon(props: { class?: string }) {
  return (
    <svg
      class={props.class}
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.70711 6.70711C1.31658 7.09763 0.683417 7.09763 0.292893 6.70711C-0.0976304 6.31658 -0.0976304 5.68342 0.292894 5.29289L5.29289 0.292893C5.68342 -0.0976315 6.31658 -0.0976315 6.70711 0.292893L11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711C11.3166 7.09763 10.6834 7.09763 10.2929 6.70711L6 2.41421L1.70711 6.70711Z"
        fill="currentColor"
      />
    </svg>
  );
}
