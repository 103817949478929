import { Show } from 'solid-js';
import { useNavigate } from '@solidjs/router';
import { BreadcrumbItem, Breadcrumbs } from '../../../components/Breadcrumbs';
import { UploadProgress } from '../../../business-components/UploadProgress';
// import { GearIcon } from '../../../components/icons/GearIcon';
// import { BellIcon } from '../../../components/icons/BellIcon';

import classes from './topbar.module.css';

interface TopbarProps {
  breadcrumbs: BreadcrumbItem[];
  showUploadBar: boolean;
  total: number;
  completed: number;
}

export function Topbar(props: TopbarProps) {
  const navigate = useNavigate();
  return (
    <div class={classes.topbar}>
      <Breadcrumbs items={props.breadcrumbs} onClick={navigate} />
      <div class={classes['topbar-actions']}>
        <Show when={props.showUploadBar}>
          <UploadProgress
            collapsed
            total={props.total}
            completed={props.completed}
          />
        </Show>
        {/* <GearIcon />
        <BellIcon /> */}
      </div>
    </div>
  );
}
