import { Card } from '../../components/Card';
import { Tabs } from '../../components/Tabs';
import { Component, createEffect, Show } from 'solid-js';
import { useModels } from '../../pages/projects/Models/use-models';
import classes from './model-page.module.css';
import { ProjectPageModelTabs } from '../../pages/projects/ProjectPage';
import { useTabs } from '../../utils/use-tabs';
import { useMetrics } from './useMetrics';
import { useResults } from './useResults';

export const ModelPage: Component = () => {
  const { model, projectSlug, modelTab, setModelTab } = useModels();
  const { testMetrics, validationMetrics } = useMetrics();
  const { isResultsEmpty } = useResults();

  createEffect(() => {
    const tab = useTabs();

    setModelTab(tab as ProjectPageModelTabs);
  });

  return (
    <Show when={model()}>
      {(model) => {
        const basePath = () =>
          `/projects/${projectSlug()}/models/${model().slug}`;
        return (
          <Card>
            <div class={classes['model-page']}>
              <Tabs
                fitContent
                tabs={[
                  {
                    id: 'overview',
                    label: 'Overview',
                    route: `${basePath()}/overview`,
                  },

                  {
                    id: 'results',
                    label: 'Results',
                    route: `${basePath()}/results`,
                    disabled: isResultsEmpty(),
                  },
                  // {
                  //   id: 'labels',
                  //   label: 'Data labels',
                  //   route: `${basePath()}/labels`,
                  // },
                  // {
                  //   id: 'slides',
                  //   label: 'Slides',
                  //   route: `${basePath()}/slides`,
                  // },
                  {
                    id: 'validation',
                    label: 'Validation KPIs',
                    route: `${basePath()}/kpi/validation`,
                    disabled: !validationMetrics(),
                  },
                  {
                    id: 'test',
                    label: 'Test KPIs',
                    route: `${basePath()}/kpi/test`,
                    disabled: !testMetrics(),
                  },
                ]}
                value={modelTab()}
              />
            </div>
          </Card>
        );
      }}
    </Show>
  );
};
