import { Typography } from '../Typography';
import classes from './badge.module.css';

export enum BadgeType {
  DEFAULT,
  ALERT,
  ERROR,
  SUCCESS,
}

interface BadgeProps {
  text?: string;
  type?: BadgeType;
  big?: boolean;
}

export function Badge(props: BadgeProps) {
  return (
    <div
      classList={{
        [classes.badge]: true,
        [classes.alert]: props.type === BadgeType.ALERT,
        [classes.error]: props.type === BadgeType.ERROR,
        [classes.success]: props.type === BadgeType.SUCCESS,
        [classes.big]: props.big,
      }}
    >
      <Typography weight="semi-bold">{props.text}</Typography>
    </div>
  );
}
