import { SlidesUploadDrawer } from '../../../business-components/SlidesUploadDrawer';
import { createEffect, createSignal, For, Show, Suspense } from 'solid-js';
import { Button } from '../../../components/Button';
import { useUploadQueue } from '../../../utils/upload-queue-manager';
import classes from './slides.module.css';
import { GalleryViewIcon } from '../../../components/icons/GalleryViewIcon';
import { ListViewIcon } from '../../../components/icons/ListViewIcon';
import { SearchInput } from '../../../components/Inputs/SearchInput';
import { ToggleGroup } from '../../../components/ToggleGroup';
import { FileInput } from '../../../components/Inputs/FileInput';
import { SlideListCardSkeleton, SlidesListCard } from './SlidesListCard';
import { SlidesGalleryCard } from './SlidesGalleryCard';
import { Modal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';
import { XIcon } from '../../../components/icons/XIcon';
import { DuplicateSlideModal } from '../../../business-components/Modals/InformationModal/DuplicateSlideModal';
import { useSlides } from './useSlides';
import { useSelectedProject } from '../../../utils/use-selected-project';
import { A } from '@solidjs/router';
import { TrialLimit } from '../../../business-components/TrialLimit';
import { useTrialQuota } from '../../../utils/use-trial-quota';
import { useToast } from '../../../components/Toast';

export function SlidesPage() {
  const {
    total,
    completed,
    queued,
    isUploading,
    duplicates,
    removeFile,
    uploadDuplicate,
    addFiles,
    isUploadDone,
  } = useUploadQueue();
  const {
    isUploadModalOpen,
    setUploadModalOpen,
    searchTerm,
    setSearchTerm,
    viewMode,
    setViewMode,
    allSlides,
    loadingSlides,
    uploadedSlides,
    mappedUploads,
    gallerySlides,
  } = useSlides({ refetchSlides: true });
  const project = useSelectedProject();
  const { embeddingsAmount, embeddingsLimit } = useTrialQuota();
  const toast = useToast();

  let slides = () => allSlides();

  const [isDrawerCollapsed, setDrawerCollapsed] = createSignal(true);

  const onAddFiles = (files: File[]) => {
    const embeddingsLeft = embeddingsLimit() - embeddingsAmount();
    if (embeddingsLeft < files.length) {
      toast.api.create({
        title: `You have exceeded the embedding limit (${embeddingsLimit()}). Please review the amount of slides to proceed with uploading.`,
        type: 'info',
      });
    } else {
      setDrawerCollapsed(false);
      addFiles(files);
    }
  };

  const onRemoveDuplicate = (dup: string) => {
    removeFile(dup);
    if (!isDrawerCollapsed() && !isUploading()) {
      setDrawerCollapsed(true);
    }
  };

  createEffect(() => {
    if (mappedUploads().length && isUploading()) {
      slides = () => [...mappedUploads(), ...uploadedSlides()];
    }

    if (isUploadDone() && !isUploading()) {
      setDrawerCollapsed(true);
    }
  });

  const isTCGA = () => project.selectedProject()?.data_source === 'TCGA';
  const projectSlug = () => project.selectedProject()?.slug;
  const projectRelPath = () => `/projects/${projectSlug()}`;
  const showUploadButton = () =>
    (isUploading() || Boolean(slides().length)) && !isTCGA();

  const disabledUploadButton = () => embeddingsAmount() >= embeddingsLimit();

  return (
    <div class={classes['slides-tab']}>
      <div
        class={classes['slides-tab-header']}
        classList={{ [classes['with-button']]: showUploadButton() }}
      >
        <ToggleGroup
          onToggle={setViewMode}
          value={[viewMode()]}
          disabled={loadingSlides()}
          items={[
            { id: 'list', icon: <ListViewIcon /> },
            { id: 'gallery', icon: <GalleryViewIcon /> },
          ]}
        />
        <TrialLimit mini mode="embeddings" />
        {/* <SearchInput value={searchTerm()} onSearch={setSearchTerm} /> */}
        <div />
        {/* TODO: remove this when search will be back :)  */}
        <Show when={showUploadButton()}>
          <Button
            onClick={() => setUploadModalOpen(true)}
            disabled={disabledUploadButton()}
          >
            Upload Slides
          </Button>
          <Show when={isUploadModalOpen()}>
            <UploadSlidesModal
              addFiles={onAddFiles}
              open={isUploadModalOpen()}
              onClose={() => setUploadModalOpen(false)}
            />
          </Show>
        </Show>
      </div>

      <Show when={isDrawerCollapsed()}>
        <Show
          when={viewMode() === 'gallery'}
          fallback={
            <Suspense fallback={<SlideListCardSkeleton />}>
              <SlidesListCard
                addFiles={onAddFiles}
                slides={uploadedSlides()}
                isTCGA={isTCGA()}
                projectRelPath={projectRelPath()}
              />
            </Suspense>
          }
        >
          <SlidesGalleryCard
            addFiles={onAddFiles}
            slides={gallerySlides()}
            isTCGA={isTCGA()}
            projectRelPath={projectRelPath()}
          />
        </Show>
      </Show>

      <Show when={isUploading()}>
        <SlidesUploadDrawer
          collapsed={isDrawerCollapsed()}
          onCollapse={() => setDrawerCollapsed((prev) => !prev)}
          total={total()}
          completed={completed()}
          queued={queued()}
          mode={viewMode()}
          slides={slides()}
          uploads={mappedUploads()}
          onAddFiles={onAddFiles}
        />
      </Show>

      <Show when={duplicates().length}>
        <For each={duplicates()}>
          {(dup) => (
            <DuplicateSlideModal
              open={Boolean(duplicates().length)}
              name={dup.name}
              onClose={() => onRemoveDuplicate(dup.name)}
              onReplace={() => uploadDuplicate(dup.name)}
            />
          )}
        </For>
      </Show>
    </div>
  );
}

interface UploadSlidesModalProps {
  addFiles: (file: File[]) => void;
  open: boolean;
  onClose: VoidFunction;
}

export function UploadSlidesModal(props: UploadSlidesModalProps) {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div class={classes['upload-slides-modal']}>
        <div class={classes['close-icon']} onClick={props.onClose}>
          <XIcon />
        </div>
        <Typography weight="bold">
          Please select prepared slides to start uploading process
        </Typography>
        <FileInput
          multiple
          onFileSelected={(files) => {
            props.onClose();
            props.addFiles(files);
          }}
        />
      </div>
    </Modal>
  );
}

interface NoSlidesProps {
  addFiles: (files: File[]) => void;
  isTCGA?: boolean;
  projectRelPath?: string;
}

export function NoSlides(props: NoSlidesProps) {
  return (
    <Show
      when={props.isTCGA}
      fallback={
        <div class={classes['no-slides']}>
          <div class={classes['no-slides-text']}>
            <Typography component="h3" weight="bold">
              No slides were uploaded yet.
            </Typography>
            <Typography component="h3">
              Please select prepared slides to start the uploading process
            </Typography>
          </div>
          <FileInput multiple onFileSelected={props.addFiles} />
        </div>
      }
    >
      <div class={classes['no-slides-tcga']}>
        <Typography weight="bold">No slides were uploaded yet.</Typography>
        <Typography>
          Please define <A href={`${props.projectRelPath}/tcga`}>TCGA Cohort</A>{' '}
          first in order to train a new model.
        </Typography>
        <A href={`${props.projectRelPath}/tcga`} class={classes['link-btn']}>
          <Button>Go to 'TCGA Cohort' tab</Button>
        </A>
      </div>
    </Show>
  );
}
