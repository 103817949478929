export function CubesIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 15.081V9.9612L5.4791 8.04199L9.95819 9.96261V15.081L5.4791 16.9988L1 15.081Z"
        stroke="currentColor"
        stroke-width="1.08"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 9.95076L5.4791 11.87L9.95819 9.95076M5.4791 2.95703L9.95678 4.87765L14.4359 2.95845"
        stroke="currentColor"
        stroke-width="1.08"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.95654 9.95117L14.4356 11.8704L18.9147 9.95117"
        stroke="currentColor"
        stroke-width="1.08"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.479 11.8732V17M14.4386 11.8732V17M9.95668 4.87802V10.0034M5.48183 8.03898V2.91921L9.95951 1L14.4386 2.91921V8.03898L9.95951 9.95819L5.48183 8.03898ZM9.95668 15.0808V9.96102L14.4358 8.04181L18.9149 9.96243V15.0808L14.4358 16.9986L9.95668 15.0808Z"
        stroke="currentColor"
        stroke-width="1.08"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
