export function GreenCheckmarkIcon() {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7837 0.49043C15.2496 0.901509 15.294 1.61243 14.8829 2.07832L6.64764 11.4116C6.44217 11.6445 6.14958 11.7821 5.83917 11.7918C5.52877 11.8015 5.22817 11.6824 5.00857 11.4628L1.16544 7.61968C0.726102 7.18034 0.726103 6.46803 1.16544 6.02869C1.60478 5.58935 2.31709 5.58935 2.75643 6.02869L5.7528 9.02506L13.1958 0.589674C13.6069 0.123784 14.3178 0.0793513 14.7837 0.49043Z"
        fill="#009951"
      />
    </svg>
  );
}
