import { Accessor, Component, createEffect } from 'solid-js';
import Plotly from 'plotly.js-dist-min';
import { useAUC } from '../use-auc-colors';

interface AUCGraphProps {
  x?: number[];
  y?: number[];
  auc?: number;
}

export const AUCGraph: Component<AUCGraphProps> = (props) => {
  const { auc, lineColor, fillColor } = useAUC(props);

  const dash_line: Plotly.Data = {
    x: [0, 1],
    y: [0, 1],
    type: 'scatter',
    fill: 'none',
    mode: 'lines',
    line: {
      dash: 'dash',
      color: '#FFBEBE',
    },
    hoverinfo: 'none',
  };

  const roc: Accessor<Plotly.Data> = () => ({
    y: props.y,
    x: props.x,
    type: 'scatter',
    fill: 'tozeroy',
    fillcolor: fillColor(),
    opacity: 0.1,
    mode: 'lines',
    line: { color: lineColor() }, // shape: 'spline',
    hoverinfo: 'none',
  });

  const layout: Accessor<Partial<Plotly.Layout>> = () => ({
    title: `Area Under Curve: ${auc()}`,
    xaxis: {
      title: 'False Positive Rate',
      showticklabels: false,
      fixedrange: true,
    },
    yaxis: {
      title: 'True Positive Rate',
      showticklabels: false,
      fixedrange: true,
    },
    showlegend: false,
    dragmode: false,
  });

  const options: Accessor<Partial<Plotly.Config>> = () => ({
    displayModeBar: 'hover',
    displaylogo: false,
    doubleClick: false,
    showAxisDragHandles: false,
    showAxisRangeEntryBoxes: false,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
    ],
  });

  createEffect(() =>
    Plotly.react('auc-graph', [roc(), dash_line], layout(), options())
  );

  return <div id="auc-graph" />;
};
