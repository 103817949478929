import { XIcon } from '../../../components/icons/XIcon';
import { Modal } from '../../../components/Modal';
import { ErrorXIcon } from '../../../components/icons/ErrorXIcon';
import classes from './error-modal.module.css';
import { Typography } from '../../../components/Typography';
import { Button } from '../../../components/Button';
import { Show } from 'solid-js';

interface ErrorModalProps {
  open: boolean;
  title: string;
  onClose: VoidFunction;
  message?: string;
  onAction?: VoidFunction;
  actionTxt?: string;
  closeTxt?: string;
}

export function ErrorModal(props: ErrorModalProps) {
  const actionBtnTxt = () => props.actionTxt ?? 'Try again';
  const cancelBtnTxt = () => props.closeTxt ?? 'Close';

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div class={classes['error-modal']}>
        <div class={classes['close-icon']} onClick={props.onClose}>
          <XIcon />
        </div>
        <ErrorXIcon />
        <Typography weight="bold" component="h2">
          {props.title}
        </Typography>
        <Show when={props.message}>
          <Typography>{props.message}</Typography>
        </Show>

        <div class={classes['modal-buttons']}>
          <Button variant="secondary" onClick={props.onClose}>
            {cancelBtnTxt()}
          </Button>
          <Show when={props.onAction}>
            <Button onClick={props.onAction}>{actionBtnTxt()}</Button>
          </Show>
        </div>
      </div>
    </Modal>
  );
}
