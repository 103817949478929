import { Component, Show } from 'solid-js';
import classes from './tag.module.css';
import { Typography } from '../Typography';
import { WhiteXIcon } from '../icons/WhiteXIcon';

interface TagProps {
  label: string;
  disabled?: boolean;
  active?: boolean;
  removeAble?: boolean;
  onRemove?: VoidFunction;
}

export const Tag: Component<TagProps> = (props) => {
  return (
    <div
      class={classes.tag}
      classList={{
        [classes.disabled]: props.disabled,
        [classes.active]: props.active,
      }}
    >
      <Typography>{props.label}</Typography>
      <Show when={props.removeAble}>
        <div onClick={props.onRemove} class={classes.icon}>
          <WhiteXIcon />
        </div>
      </Show>
    </div>
  );
};
