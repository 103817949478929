import {
  Accessor,
  createContext,
  createSignal,
  ParentComponent,
  Setter,
  useContext,
} from 'solid-js';
import LoadingBar, { LoadingBarRef } from 'solid-top-loading-bar';

const TopLoadingBarContext = createContext({} as Accessor<LoadingBarRef>);

export const useTopLoadingBar = () => useContext(TopLoadingBarContext);

export const TopLoadingBar: ParentComponent = (props) => {
  const [loadingBar, setLoadingBar] = createSignal<LoadingBarRef>();

  return (
    <TopLoadingBarContext.Provider
      value={loadingBar as unknown as Accessor<LoadingBarRef>}
    >
      <LoadingBar
        color="var(--clr-primary-main)"
        height={'4px' as unknown as number}
        loadingBar={loadingBar()}
        setLoadingBar={setLoadingBar as unknown as Setter<LoadingBarRef>}
      />
      {props.children}
    </TopLoadingBarContext.Provider>
  );
};
