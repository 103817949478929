export function DashIcon() {
  return (
    <svg
      width="20"
      height="2"
      viewBox="0 0 20 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="1"
        x2="19"
        y2="1"
        stroke="#757575"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
