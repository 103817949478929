import axios, { AxiosError, AxiosInstance } from 'axios';
import { useToast } from '../components/Toast';
import { createContext, ParentComponent, useContext } from 'solid-js';
import { useTopLoadingBar } from '../components/TopLoadingBar';

const AxiosContext = createContext<AxiosInstance>({} as AxiosInstance);

export const useAxios = () => useContext(AxiosContext);

export const AxiosProvider: ParentComponent = (props) => {
  const toast = useToast();
  const loader = useTopLoadingBar();
  const axiosClient = axios.create({
    baseURL: '/api',
  });

  axiosClient.interceptors.request.use((req) => {
    loader().continuousStart();
    return req;
  });

  axiosClient.interceptors.response.use(
    (res) => {
      loader().complete();
      return res;
    },
    (error: AxiosError) => {
      loader().complete();
      const statusCode = error.response?.status ?? 500;
      if (statusCode >= 500) {
        toast.overlapApi.create({
          title: 'Network Issue',
          type: 'error',
        });
      } else if (statusCode === 404) {
        toast.overlapApi.create({
          title: 'Resource not found',
          type: 'error',
        });
      }
      throw error;
    }
  );

  return (
    <AxiosContext.Provider value={axiosClient}>
      {props.children}
    </AxiosContext.Provider>
  );
};
