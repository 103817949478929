import { ParentComponent, For, createSignal } from 'solid-js';
import { Card } from '../../components/Card';
import { Typography } from '../../components/Typography';
import classes from './cohort-wizard.module.css';
import { TCGAColumnCard } from './ColumnCard/TCGAColumnCard';
import { Tag } from '../../components/Tag';
import { useTCGAWizard } from './use-tcga-wizard';
import { Tabs } from '../../components/Tabs';

export interface DefineCohortColumn {
  title: string;
  categories: { name: string; amount?: number }[];
  section?: string;
}

interface DefineCohortProps {
  columns: DefineCohortColumn[];
}

enum TCGASections {
  STUDIES = 'TCGA Studies',
  DEMOGRAPHICS = 'Demographics',
  DIAGNOSIS = 'Diagnosis',
  SPECIMEN = 'Specimen',
  CLINICAL = 'Clinical',
  GENOMICS = 'Genomics',
}

export const DefineCohort: ParentComponent<DefineCohortProps> = (props) => {
  let scrollRef!: HTMLDivElement;
  const { labels, onAddFilter, onRemoveFilter, cohortCount } = useTCGAWizard();
  const [section, setSection] = createSignal<TCGASections>(
    TCGASections.STUDIES
  );

  const sections = () =>
    Object.values(TCGASections).map((i) => ({
      id: i,
      label: i,
    }));

  const onLabelSelect = (value: string, checked: boolean, column: string) => {
    if (checked) {
      onAddFilter({ value, column });
    } else {
      onRemoveFilter({ value, column });
    }
  };

  const columns = () => props.columns.filter((c) => c.section === section());

  const onSectionChange = (id: string) => {
    scrollRef.scrollTo(0, 0);
    setSection(id as TCGASections);
  };

  return (
    <Card>
      <div class={classes['define-cohort']}>
        <div class={classes.header}>
          <Tabs
            tabs={sections()}
            onTabSelect={onSectionChange}
            value={section()}
          />
          <div class={classes.title}>
            <Typography component="h3">
              Selected cohort:{' '}
              <Typography weight="bold">{cohortCount()}</Typography>
            </Typography>

            <div class={classes.tags}>
              <For each={labels()}>
                {(tag) => (
                  <Tag
                    label={`${tag.column}: ${tag.value}`}
                    onRemove={() => onRemoveFilter(tag)}
                    removeAble
                  />
                )}
              </For>
            </div>
          </div>
        </div>

        <div class={classes.columns} ref={scrollRef}>
          <For each={columns()}>
            {(column) => (
              <TCGAColumnCard
                {...column}
                searchable={column.title.toLowerCase().endsWith('genes')}
                onLabelSelect={onLabelSelect}
                labels={labels()}
                mode="cohort"
              />
            )}
          </For>
        </div>
        {props.children}
      </div>
    </Card>
  );
};
