import { Component } from 'solid-js';

export const CircleCheck: Component = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24C5 13.5066 13.5066 5 24 5C34.4934 5 43 13.5066 43 24ZM48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM34.0075 19.6543C34.921 18.619 34.8223 17.0392 33.787 16.1257C32.7517 15.2122 31.1718 15.3109 30.2583 16.3462L21.4308 26.3508L18.3713 23.2913C17.395 22.315 15.8121 22.315 14.8357 23.2913C13.8594 24.2676 13.8594 25.8506 14.8357 26.8269L19.7769 31.768C20.2649 32.256 20.9329 32.5206 21.6227 32.4991C22.3125 32.4775 22.9627 32.1718 23.4193 31.6543L34.0075 19.6543Z"
      fill="#0E9F6E"
    />
  </svg>
);
