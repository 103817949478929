export function OILogoSmall() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="#541F57"
      />
      <path
        d="M32.4379 24.9418C32.4379 28.7984 31.1614 32.0214 28.6084 34.6196C26.0555 37.2179 22.8506 38.5125 18.994 38.5125C14.9925 38.5125 11.7152 37.2179 9.17126 34.6377C6.62731 32.0576 5.35986 28.7531 5.35986 24.7335C5.35986 20.8316 6.70879 17.5996 9.41569 15.0466C12.0864 12.5389 15.3817 11.2896 19.3108 11.2896C23.1765 11.2896 26.3452 12.6023 28.8076 15.2277C31.2248 17.8169 32.4379 21.0489 32.4379 24.9418ZM28.0924 25.7113C28.0924 22.8233 27.2595 20.2884 25.5937 18.1066C23.765 15.7075 21.4383 14.5125 18.6318 14.5125C15.9611 14.5125 13.7884 15.4269 12.1045 17.2647C10.484 19.0391 9.67823 21.2662 9.67823 23.955C9.67823 26.9516 10.5202 29.5498 12.195 31.7316C14.0238 34.1217 16.4048 35.3077 19.347 35.3077C21.9634 35.3077 24.109 34.348 25.7657 32.4197C27.3229 30.6 28.0924 28.3639 28.0924 25.7113Z"
        fill="white"
      />
      <path
        d="M39.5356 38.5214H33.6782C34.2757 36.1223 34.5835 33.7775 34.5835 31.5052V19.3015C34.5835 16.2415 34.2848 13.5527 33.6782 11.2441H39.5356C38.92 13.5527 38.6122 16.2415 38.6122 19.3015V31.3151C38.6213 33.7142 38.9291 36.1132 39.5356 38.5214Z"
        fill="white"
      />
    </svg>
  );
}
