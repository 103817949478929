import { For } from 'solid-js';
import { SlideThumbnail } from '../SlideThumbnail';
import classes from './slides-gallery.module.css';
import type { StudioSlide } from '@imagene/fm-studio-interfaces';

interface SlidesGalleryProps {
  slides: StudioSlide[];
}

export function SlidesGallery(props: SlidesGalleryProps) {
  return (
    <div class={classes['slides-gallery']}>
      <For each={props.slides}>
        {(slide) => <SlideThumbnail slide={slide} />}
      </For>
    </div>
  );
}
