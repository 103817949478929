export function ErrorIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6.01102 2C5.45874 1.99983 5.01088 2.4474 5.01071 2.99969L5.00976 5.99969C5.00959 6.55197 5.45716 6.99983 6.00945 7C6.56173 7.00018 7.00959 6.5526 7.00976 6.00032L7.01071 3.00032C7.01088 2.44803 6.56331 2.00017 6.01102 2ZM6 10C6.55228 10 7 9.55229 7 9C7 8.44771 6.55228 8 6 8C5.44772 8 5 8.44771 5 9C5 9.55229 5.44772 10 6 10Z"
        fill="#E72626"
      />
    </svg>
  );
}
