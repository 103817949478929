import type {
  CreateWsiResponse,
  CreateStudioWsiDto,
  WsiState,
  CreateEmbeddingsDto,
} from '@imagene/fm-studio-interfaces';
import {
  Accessor,
  ParentProps,
  createContext,
  createEffect,
  createSignal,
  useContext,
} from 'solid-js';
import { createStore } from 'solid-js/store';
import { useAxios } from './axios';
import UploadWorker from './upload-web-worker?worker';
import Queue from 'queue';
import { useFileFormat } from './use-file-format';
import { UploadOutput } from './upload-worker-utils';
import { createMutation, useQueryClient } from '@tanstack/solid-query';
import { isAxiosError } from 'axios';
import { useSelectedProject } from './use-selected-project';
import { useToast } from '../components/Toast';

export interface WsiUploadState {
  name: string;
  state: WsiState;
  wsi_id: string;
  slide_id: string;
  total?: number;
  completed?: number;
  percentage?: number;
}

interface UploadStore {
  uploads: WsiUploadState[];
  duplicates: File[];
}

interface UploadContext {
  uploads: Accessor<WsiUploadState[]>;
  duplicates: Accessor<File[]>;
  addFiles: (files: File[]) => void;
  total: Accessor<number>;
  completed: Accessor<number>;
  queued: Accessor<number>;
  isUploading: Accessor<boolean>;
  isUploadDone: Accessor<boolean>;
  removeFile: (name: string) => void;
  uploadDuplicate: (name: string) => void;
}

const UploadQueueContext = createContext<UploadContext>({
  uploads: [],
} as unknown as UploadContext);

export function useUploadQueue() {
  return useContext(UploadQueueContext);
}

export function UploadQueueProvider(props: ParentProps) {
  const axios = useAxios();
  const [store, setStore] = createStore<UploadStore>({
    uploads: [],
    duplicates: [],
  });
  const [isUploading, setIsUploading] = createSignal(false);
  const worker = new UploadWorker();
  const q = new Queue({ concurrency: 3, results: [], autostart: true });
  const project = useSelectedProject();
  const isUploadDone = () => queued() < 1;
  const queryClient = useQueryClient();
  const projectId = () => project.selectedProject()?.id;
  const toast = useToast();

  const checkSlideExists = createMutation({
    mutationFn: (fileName: string) =>
      axios
        .get(`/projects/${projectId()}/slides`, {
          params: {
            fileName,
          },
        })
        .then((res) => res.data),
  });

  const createWsi = createMutation({
    mutationFn: (data: CreateStudioWsiDto) =>
      axios
        .post<CreateWsiResponse>(`/projects/${projectId()}/upload/wsi`, data)
        .then((res) => res.data),
    onError(e) {
      if (isAxiosError(e)) {
        if (e.response?.status === 406) {
          toast.api.create({
            title: 'Embeddings limit have been reached.',
            type: 'info',
          });
          setIsUploading(false);
          queryClient.invalidateQueries({ queryKey: ['quota'] });
        }
        console.error(e?.message);
      } else {
        console.error(e);
      }
    },
  });

  const triggerEmbeddings = createMutation({
    mutationFn: (data: CreateEmbeddingsDto) =>
      axios.post(`/projects/${projectId()}/embeddings`, {
        wsi_id: data.wsi_id,
      }),
  });

  const addFiles = async (files: File[]) => {
    for (const file of files) {
      const cohortId = project.selectedProject()?.cohorts.at(0)?.id;
      const exists = await checkSlideExists.mutateAsync(file.name);

      if (exists.length) {
        console.warn(`slide with filename ${file.name} already exists`);
        setStore('duplicates', (prev) => [...prev, file]);
        continue;
      } else {
        addFileToQueue(file, cohortId);
        setIsUploading(true);
      }
    }
  };

  const addFileToQueue = (file: File, cohort_id?: number) => {
    q.push(async () => {
      const format = useFileFormat(file);
      const slide_id = file.name.substring(0, file.name.lastIndexOf('.'));

      const wsi = await createWsi.mutateAsync({
        fileName: file.name,
        size_in_bytes: file.size,
        format: format(),
        slide_id,
        cohort_id,
      });

      setStore('uploads', (prev) => [
        ...prev,
        {
          wsi_id: wsi.id,
          state: 'IN_PROGRESS',
          name: file.name,
          slide_id,
        },
      ]);

      await queryClient.invalidateQueries(['slides', projectId()]);

      worker.postMessage({ wsi_id: wsi.id, file, project_id: projectId() });
    });
  };

  const removeFile = (name: string) => {
    if (store.duplicates.length) {
      setStore('duplicates', () =>
        store.duplicates.filter((dup) => dup.name !== name)
      );
    }
  };

  const uploadDuplicate = (name: string) => {
    const file = store.duplicates.find((dup) => dup.name === name);
    if (!file) return;
    const cohortId = project.selectedProject()?.cohorts.at(0)?.id;

    addFileToQueue(file, cohortId);
    setIsUploading(true);
    removeFile(name);
  };

  worker.onmessage = (ev: MessageEvent<UploadOutput>) => {
    if (!ev.data) return;
    const { type, wsi_id } = ev.data;
    const wsi = store.uploads.find((i) => i.wsi_id === wsi_id);
    if (!wsi) return;
    const index = store.uploads.findIndex((wsi) => wsi.wsi_id === wsi_id);

    if (type === 'DONE') {
      //   datadogLogs.logger.log(`wsi [${wsi_id}] upload done`, {
      //     wsi_id,
      //   });
      console.log(`wsi [${wsi_id}] upload done`);
      setStore('uploads', index, 'state', 'DONE');
      triggerEmbeddings.mutate({ wsi_id });
    }

    if (type === 'ERROR') {
      //   datadogLogs.logger.error(`wsi [${wsi_id}] upload error`, {
      //     wsi_id,
      //   });
      console.error(`wsi [${wsi_id}] upload error`);
      setStore('uploads', index, 'state', 'ERROR');
    }

    if (type === 'PROGRESS') {
      setStore('uploads', index, 'percentage', ev.data.percentage);
    }

    if (type === 'TIMEOUT') {
      //   datadogLogs.logger.error(`wsi [${wsi_id}] upload timedout`, {
      //     wsi_id,
      //   });
      console.error(`wsi [${wsi_id}] upload timedout`);
      setStore('uploads', index, 'state', 'TIMEOUT');
    }

    if (type === 'PART_DONE') {
      const { total, partNumber, success } = ev.data;
      // datadogLogs.logger.info(
      //   `uploaded part ${partNumber}/${total} ${
      //     success ? 'successfully' : 'with errors'
      //   } for wsi [${wsi_id}]`,
      //   { wsi_id }
      // );
      console.log(
        `uploaded part ${partNumber}/${total} ${
          success ? 'successfully' : 'with errors'
        } for wsi [${wsi_id}]`
      );
    }
  };

  const uploads = () => store.uploads;

  const completed = () =>
    uploads().filter((i) => i.state !== 'INIT' && i.state !== 'IN_PROGRESS')
      .length;

  const duplicates = () => store.duplicates;

  const total = () => uploads().length;

  const queued = () => total() - completed();

  const uploadQueueContext: UploadContext = {
    total,
    completed,
    uploads,
    queued,
    isUploading,
    addFiles,
    removeFile,
    duplicates,
    uploadDuplicate,
    isUploadDone,
  };

  createEffect(async () => {
    if (isUploadDone()) {
      setIsUploading(false);
      await queryClient.invalidateQueries(['slides', projectId()]);

      if (total()) {
        setStore('uploads', []);
      }
    }
  });

  return (
    <UploadQueueContext.Provider value={uploadQueueContext}>
      {props.children}
    </UploadQueueContext.Provider>
  );
}
