import { Accessor, Component } from 'solid-js';
import { ModelWizard } from '../../../business-components/ModelWizard';
import {
  WizardChecklistProvider,
  WizardChecklistStep,
} from '../../../business-components/WizardChecklist/use-wizard-checklist';
import { useModels } from './use-models';

export enum ModelWizardSteps {
  MODEL_NAME,
  PREDICTION_TARGET,
  DEFINE_SPLIT,
  TRAIN,
  TRAINING,
}

export const EditModel: Component = () => {
  const { model } = useModels();

  const initialStep = () => model()?.step ?? 0;

  const steps: Accessor<WizardChecklistStep[]> = () => [
    {
      title: 'Name a model',
      description:
        'Name your new model. You can also describe your research hypothesis or add any comment.',
      collapse: initialStep() != 0,
      value: initialStep() > 0 ? model()?.name : null,
    },
    {
      title: 'Select prediction target',
      description:
        'Select a value for the "Positive" label to create a binary classification model. All other values in the column will be assigned the "Negative" label.',
      collapse: initialStep() != 1,
      value: initialStep() > 1 ? model()?.prediction_column : null,
    },
    {
      title: 'Define split',
      description:
        'You can use the slider to change the percentages of each set. The slides will be divided randomly.',
      collapse: initialStep() != 2,
      value:
        initialStep() > 2
          ? `By percentage - ${model()?.split_train_validation_percentage?.toFixed()}% / ${model()?.split_test_percentage?.toFixed()}%`
          : null,
    },
    {
      title: 'Train',
      description:
        'Review your model setup summary to ensure it is correct, before proceeding with training. Press the button to start.',
      collapse: initialStep() != 3,
      value: null,
    },
  ];
  return (
    <WizardChecklistProvider steps={steps()} initialStep={initialStep()}>
      <ModelWizard />
    </WizardChecklistProvider>
  );
};
