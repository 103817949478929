import './progress.module.css';
import * as progress from '@zag-js/progress';
import { normalizeProps, useMachine } from '@zag-js/solid';
import { Component, createMemo, createUniqueId, splitProps } from 'solid-js';

export interface ProgressProps {
  value?: number | null;
  max?: number;
  min?: number;
}

export const Progress: Component<ProgressProps> = (props) => {
  const [context] = splitProps(props, ['value', 'min', 'max']);
  const [state, send] = useMachine(
    progress.machine({
      id: createUniqueId(),
      value: context.value ?? null,
      max: context.max ?? 100,
      min: context.min ?? 0,
    }),
    { context }
  );

  const api = createMemo(() => progress.connect(state, send, normalizeProps));

  return (
    <div {...api().getRootProps()}>
      <div {...api().getTrackProps()}>
        <div {...api().getRangeProps()} />
      </div>
    </div>
  );
};
