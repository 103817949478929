import { For } from 'solid-js';
import classes from './breadcrumbs.module.css';
import { Typography } from '../Typography';

export interface BreadcrumbItem {
  text: string;
  active: boolean;
  route?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
  onClick?: (target: string) => void;
}

export function Breadcrumbs(props: BreadcrumbsProps) {
  return (
    <nav class={classes.breadcrumbs}>
      <For each={props.items}>
        {(item, index) => (
          <>
            <Typography
              weight={item.active ? 'bold' : 'default'}
              classList={{
                [classes.active]: item.active,
                [classes.link]: Boolean(item.route),
              }}
              onClick={() => {
                if (props.onClick && item.route) {
                  props.onClick(item.route);
                }
              }}
            >
              {item.text}
            </Typography>
            <Typography class={classes.divider}>
              {index() !== props.items.length - 1 ? '/' : ''}
            </Typography>
          </>
        )}
      </For>
    </nav>
  );
}
