import { createSignal } from 'solid-js';
import type {
  ValidateFileResponse,
  CohortDataResponse,
  GetColumnsResponse,
  CreateCohortDto,
  ValidateFileDto,
} from '@imagene/fm-studio-interfaces';
import {
  useQueryClient,
  createQuery,
  createMutation,
} from '@tanstack/solid-query';
import { uuid4 } from '@temporalio/workflow';
import { useAxios } from '../../../utils/axios';
import { useSelectedProject } from '../../../utils/use-selected-project';
import { isAxiosError } from 'axios';
import { useNavigate } from '@solidjs/router';

export enum LABEL_MODE {
  COHORT,
  TEXT,
  NUMERIC,
  MIXED,
  NO_SLIDES,
}
interface LabelsStatsChip {
  id: LABEL_MODE;
  label: string;
  value?: string;
  active?: boolean;
  disabled?: boolean;
}

export function useLabels() {
  const axios = useAxios();
  const project = useSelectedProject();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [selectedChip, setSelectedChip] = createSignal<LABEL_MODE>(
    LABEL_MODE.COHORT
  );
  const [isUploadModalOpen, setUploadModalOpen] = createSignal(false);
  const [isColumnModalOpen, setColumnModalOpen] = createSignal(false);
  const [uploadId, setUploadId] = createSignal('');
  const [selectedColumn, setSelectedColumn] = createSignal('');
  const [columns, setColumns] = createSignal<string[]>([]);
  const [duplicates, setDuplicates] = createSignal<
    ValidateFileResponse['duplicates']
  >([]);
  const [missingValues, setMissingValues] = createSignal<
    ValidateFileResponse['missingValues']
  >({ rows: [] });
  const [removeMissingValues, setRemoveMissingValues] = createSignal(false);
  const [uploadError, setUploadError] = createSignal({
    title: '',
    message: '',
  });
  const [isUploadErrorModalOpen, setUploadErrorModalOpen] = createSignal(false);

  const [chips] = createSignal<LabelsStatsChip[]>([
    {
      label: 'Cohort:',
      id: LABEL_MODE.COHORT,
      disabled: true,
    },
    {
      label: 'Text categories data:',
      id: LABEL_MODE.TEXT,
      disabled: true,
    },
    {
      label: 'Numeric data:',
      id: LABEL_MODE.NUMERIC,
      disabled: true,
    },
    {
      label: 'Mixed data:',
      id: LABEL_MODE.MIXED,
      disabled: true,
    },
    {
      label: 'No slides:',
      id: LABEL_MODE.NO_SLIDES,
      disabled: true,
    },
  ]);
  const projectId = () => project.selectedProject()?.id;
  const projectSlug = () => project.selectedProject()?.slug;
  const cohortData = () => cohortQuery?.data?.data?.map((r) => r.data) ?? [];
  const cohortId = () => cohortQuery?.data?.cohort_id;
  const cohortColumn = () => cohortQuery?.data?.identifierColumn ?? '';
  const showUploadButton = () => Boolean(cohortData().length);
  const cohortQuery = createQuery(
    () => ['cohort', projectId()],
    async () =>
      axios
        .get<CohortDataResponse>(`/projects/${projectId()}/cohorts/`)
        .then((res) => res.data),
    {
      get enabled() {
        return Boolean(projectId());
      },
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (project.selectedProject()?.data_source === 'TCGA' && !data) {
          navigate(`/projects/${projectSlug()}/tcga`);
        }
      },
    }
  );

  const deleteUpload = createMutation({
    mutationFn: () =>
      axios.delete(`/projects/${projectId()}/cohorts/upload/${uploadId()}`),
    onSuccess: () => {
      setDuplicates([]);
      setColumnModalOpen(false);
      setMissingValues({ rows: [] });
      setSelectedColumn('');
    },
  });

  const getColumns = createMutation({
    mutationFn: () =>
      axios
        .get<GetColumnsResponse>(
          `/projects/${projectId()}/cohorts/columns/${uploadId()}`,
          {
            params: {
              cohortId: cohortId(),
            },
          }
        )
        .then((res) => res.data),
    onSuccess: (data) => {
      if (data.suggestedColumn) {
        setSelectedColumn(data.suggestedColumn);
      }
      setColumns(data.columns);
      setColumnModalOpen(true);
    },
  });

  const createCohort = createMutation({
    mutationFn: (data: CreateCohortDto) =>
      axios
        .post(`/projects/${projectId()}/cohorts`, {
          ...data,
          removeMissingValues:
            data.removeMissingValues ?? removeMissingValues(),
        })
        .then((res) => res.data),
    onSuccess: () => {
      setDuplicates([]);
      queryClient.invalidateQueries({ queryKey: ['projects', projectSlug()] });
      queryClient.invalidateQueries({ queryKey: ['cohort', projectId()] });
    },
  });

  const validateFile = createMutation({
    mutationFn: (data: ValidateFileDto) =>
      axios
        .post<ValidateFileResponse>(
          `/projects/${projectId()}/cohorts/validate`,
          data
        )
        .then((res) => res.data),

    onSuccess: async (data) => {
      setColumnModalOpen(false);
      if (data.missingValues || data.duplicates.length) {
        if (data.missingValues) {
          setMissingValues(data.missingValues);
        }
        if (data.duplicates.length) {
          setDuplicates(data.duplicates);
        }
      } else {
        await createCohort.mutateAsync({
          filename_column: selectedColumn(),
          upload_id: uploadId(),
        });
        setSelectedColumn('');
      }
    },
  });

  const uploadFile = createMutation({
    mutationFn: (data: FormData) =>
      axios
        .post(`/projects/${projectId()}/cohorts/upload`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data),
    onSuccess: async () => {
      setUploadModalOpen(false);
      await getColumns.mutateAsync();
    },

    onError: (e) => {
      if (isAxiosError(e)) {
        const error = e.response?.data;
        setUploadError({
          title: error.message,
          message: error.details,
        });
        setUploadErrorModalOpen(true);
      }
    },
  });

  const addFile = async (files: File[]) => {
    const formData = new FormData();
    setUploadId(uuid4());
    formData.append('file', files[0]);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append('project_id', projectId()!.toString());
    formData.append('upload_id', uploadId());

    await uploadFile.mutateAsync(formData);
  };

  const onColumnConfirm = async () => {
    await validateFile.mutateAsync({
      upload_id: uploadId(),
      filename_column: selectedColumn(),
    });
  };

  const onDuplicateConfirm = async () => {
    await createCohort.mutateAsync({
      filename_column: selectedColumn(),
      upload_id: uploadId(),
      removeDuplicates: true,
    });
  };

  const onMissingConfirm = async () => {
    setMissingValues({ rows: [] });
    if (duplicates().length) {
      setRemoveMissingValues(true);
    } else {
      await createCohort.mutateAsync({
        filename_column: selectedColumn(),
        upload_id: uploadId(),
        removeMissingValues: true,
      });
    }
  };

  const deleteDataUpload = () => deleteUpload.mutate();

  return {
    chips,
    selectedChip,
    setSelectedChip,
    showUploadButton,
    isUploadModalOpen,
    setUploadModalOpen,
    addFile,
    isUploading: () => uploadFile.isLoading,
    isColumnModalOpen,
    deleteDataUpload,
    cohortColumn,
    columns,
    onColumnConfirm,
    selectedColumn,
    setSelectedColumn,
    cohortData,
    onDuplicateConfirm,
    duplicates,
    missingValues,
    onMissingConfirm,
    isUploadErrorModalOpen,
    uploadError,
    setUploadErrorModalOpen,
  };
}
