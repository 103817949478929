import { Component, Show } from 'solid-js';
import { Typography } from '../../components/Typography';
import { Progress } from '../../components/Progress';
import classes from './upload-progress.module.css';
import { GreenCheckmarkCircleIcon } from '../../components/icons/GreenCheckmarkCircleIcon';

interface UploadProgressProps {
  total: number;
  completed: number;
  queued?: number;
  collapsed?: boolean;
}

export const UploadProgress: Component<UploadProgressProps> = (props) => {
  const value = () => (props.total ? props.completed : null);
  return (
    <div class={classes['upload-progress-container']}>
      <div class={classes['upload-progress']}>
        <Show
          when={props.collapsed}
          fallback={
            <Typography weight="bold">Slides upload ({props.total})</Typography>
          }
        >
          <Typography weight="bold">
            Slides upload ({props.completed}/{props.total})
          </Typography>
        </Show>
        <Progress max={props.total} value={value()} />
        <Show when={!props.collapsed}>
          <div class={classes['upload-counter']}>
            <GreenCheckmarkCircleIcon />
            <Typography weight="bold">{props.completed} done </Typography>
            <Typography>{props.queued} in queue</Typography>
          </div>
        </Show>
      </div>
    </div>
  );
};
