import { Input, InputProps } from '../Input';
import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import {
  createMemo,
  createUniqueId,
  For,
  Show,
  splitProps,
  JSX,
  onMount,
  onCleanup,
} from 'solid-js';
import { Typography } from '../../Typography';
import { normalizeProps, useMachine } from '@zag-js/solid';
import * as select from '@zag-js/select';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

import classes from './select-input.module.css';

export type SelectInputProps<T extends Array<unknown> = string[]> = Omit<
  select.Context,
  'id' | 'composite' | 'getRootNode' | 'open.controlled' | 'collection'
> & {
  options: T;
  value?: T;
} & Pick<InputProps, 'errorText' | 'hintText'>;

export function SelectInput(props: SelectInputProps) {
  const [context, local] = splitProps(props, ['open', 'value']);
  let input: HTMLInputElement;
  let inputSubscription: Subscription;

  const resetOptions = () => {
    api().setCollection(select.collection({ items: props.options }));
  };

  const onValueChange: SelectInputProps['onValueChange'] = (details) => {
    props.onValueChange?.(details);
    resetOptions();
  };

  const [state, send] = useMachine(
    select.machine({
      ...local,
      ...context,
      id: createUniqueId(),
      collection: select.collection({
        items: props.options,
      }),
      onValueChange,
      closeOnSelect: true,
    }),
    { context }
  );

  const api = createMemo(() => select.connect(state, send, normalizeProps));

  const currentItems = () => api().collection.items;

  const onInput: JSX.InputEventHandlerUnion<HTMLInputElement, InputEvent> = (
    event
  ) => {
    const term = event.target.value;
    if (term) {
      api().setCollection(
        select.collection({
          items: local.options.filter((o) =>
            o.toLowerCase().includes(term.toLowerCase())
          ),
        })
      );
      return;
    }

    resetOptions();
  };

  onMount(() => {
    inputSubscription = fromEvent(input, 'input')
      .pipe(debounceTime(1000))
      .subscribe(() => api().setOpen(true));
  });

  onCleanup(() => {
    if (inputSubscription) {
      inputSubscription.unsubscribe();
    }
  });

  return (
    <div class={classes.select}>
      <Input
        ref={(r) => (input = r)}
        value={api().valueAsString}
        onInput={onInput}
        disabled={local.disabled}
        errorText={local.errorText}
        hintText={local.hintText}
      />
      <button {...api().getTriggerProps()}>
        <ArrowIcon />
      </button>
      <div {...api().getPositionerProps()}>
        <ul {...api().getContentProps()}>
          <For each={currentItems()}>
            {(item) => (
              <li {...api().getItemProps({ item })}>
                <Typography component="span" color="default">
                  {item}
                </Typography>
              </li>
            )}
          </For>
          <Show when={!currentItems().length}>
            <Typography class={classes['no-options']} color="secondary">
              No options found...
            </Typography>
          </Show>
        </ul>
      </div>
    </div>
  );
}
