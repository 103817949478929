import { For, JSXElement, Show } from 'solid-js';
import classes from './menu-button.module.css';
import { A } from '@solidjs/router';
import { BlackArrowIcon } from '../../components/icons/BlackArrowIcon';
import { Tooltip } from '../../components/Tooltip';
import { Typography } from '../Typography';

type MenuButtonItem = {
  label: string;
  route: string;
  disabled?: boolean;
};

interface MenuButton {
  active: boolean;
  open: boolean;
  text: string;
  icon: JSXElement;
  collapsed: boolean;
  items: MenuButtonItem[];
  onClick: VoidFunction;
}

export function MenuButton(props: MenuButton) {
  const showMenuArrow = () => !props.collapsed;
  const showMenu = () => !props.collapsed && props.open;

  return (
    <div class={classes['menu-button-wrapper']}>
      <div
        onClick={props.onClick}
        class={classes['menu-button']}
        classList={{
          [classes.active]: props.active,
          [classes.collapsed]: props.collapsed,
        }}
      >
        <Show when={props.icon}>
          <Show when={props.collapsed} fallback={props.icon}>
            <Tooltip text={props.text} placement="right">
              {props.icon}
            </Tooltip>
          </Show>
        </Show>
        <Show when={!props.collapsed}>
          <Tooltip text={props.text}>
            <Typography wrap="18ch">{props.text}</Typography>
          </Tooltip>
        </Show>
        <Show when={showMenuArrow()}>
          <div
            class={classes['menu-arrow']}
            classList={{ [classes.open]: props.open }}
          >
            <BlackArrowIcon />
          </div>
        </Show>
      </div>
      <Show when={showMenu()}>{<CollapsibleMenu items={props.items} />}</Show>
    </div>
  );
}

function CollapsibleMenu(props: { items: MenuButtonItem[] }) {
  return (
    <div class={classes['menu-inner']}>
      <For each={props.items}>
        {(item) => (
          <Show
            when={item.disabled}
            fallback={
              <A
                class={classes['menu-item']}
                href={item.route}
                activeClass={classes.active}
              >
                <Typography color="secondary">{item.label}</Typography>
              </A>
            }
          >
            <div
              classList={{
                [classes['menu-item']]: true,
                [classes.disabled]: true,
              }}
            >
              <Typography color="secondary">{item.label}</Typography>
            </div>
          </Show>
        )}
      </For>
    </div>
  );
}
