import { Component, Match, Switch, Show } from 'solid-js';
import { useNavigate } from '@solidjs/router';
import { WizardChecklist } from '../WizardChecklist';
import { TCGAWizardSteps } from '../../pages/projects/Labels/TCGACohortWizard';
import { useChecklistWizard } from '../WizardChecklist/use-wizard-checklist';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import { DefineCohort } from './DefineCohort';
import { DefineColumns } from './DefineColumns';
import { useTCGAWizard } from './use-tcga-wizard';
import { useSelectedProject } from '../../utils/use-selected-project';
import classes from './cohort-wizard.module.css';
import { TrialLimit } from '../TrialLimit';

export const TCGAWizard: Component = () => {
  const { currentStep, setValue, goToStep } = useChecklistWizard();
  const {
    cohortData,
    cohortColumns,
    cohortCount,
    columns,
    onDefineCohortConfirm,
    onDefineColumnsBack,
    onDefineColumnsConfirm,
  } = useTCGAWizard();
  const navigate = useNavigate();
  const project = useSelectedProject();
  const showCancelButton = () =>
    Boolean(project.selectedProject()?.cohorts.at(0)?.id);

  return (
    <div class={classes.wizard}>
      <Switch>
        <Match when={currentStep() === TCGAWizardSteps.DEFINE_COHORT}>
          <div class={classes.header}>
            <div class={classes['define-cohort-header']}>
              <Typography component="h2" weight="bold">
                Define Cohort
              </Typography>
              <TrialLimit mini mode="embeddings" />
            </div>
            <DefineCohort columns={cohortData()}>
              <ActionButtons
                nextEnabled={true}
                onNext={() => {
                  setValue(cohortCount()?.toString());
                  onDefineCohortConfirm();
                }}
                onBack={() => navigate(-1)}
                backTxt="Cancel"
                hideBack={!showCancelButton()}
              />
            </DefineCohort>
          </div>
        </Match>
        <Match when={currentStep() === TCGAWizardSteps.DEFINE_COLUMNS}>
          <div class={classes.header}>
            <Typography component="h2" weight="bold">
              Define Dataset Columns
            </Typography>
            <DefineColumns columns={cohortColumns()}>
              <ActionButtons
                nextEnabled={Boolean(columns().length)}
                onNext={onDefineColumnsConfirm}
                onBack={() => {
                  onDefineColumnsBack();
                  goToStep(TCGAWizardSteps.DEFINE_COHORT);
                }}
              />
            </DefineColumns>
          </div>
        </Match>
      </Switch>
      <WizardChecklist />
    </div>
  );
};

const ActionButtons: Component<{
  nextEnabled: boolean;
  backTxt?: string;
  onBack: VoidFunction;
  onNext: VoidFunction;
  hideBack?: boolean;
}> = (props) => (
  <div class={classes.buttons}>
    <Show when={!props.hideBack}>
      <Button variant="secondary" onClick={props.onBack}>
        {props.backTxt ?? 'Back'}
      </Button>
    </Show>
    <Button onClick={props.onNext} disabled={!props.nextEnabled}>
      Next
    </Button>
  </div>
);
