import { Component, Show } from 'solid-js';
import { SearchIcon } from '../../icons/SearchIcon';
import { AllowedInputProps, Input } from '../Input';
import { XIcon } from '../../icons/XIcon';

import classes from './search-input.module.css';

interface SearchInputProps extends AllowedInputProps {
  id?: string;
  value: string | undefined;
  onSearchChange?: (term?: string) => void;
}

export const SearchInput: Component<SearchInputProps> = (props) => {
  return (
    <div
      class={classes.search}
      classList={{ [classes.disabled]: props.disabled }}
    >
      <Input
        {...props}
        type="search"
        placeholder={props.placeholder ?? 'Search'}
        value={props.value}
        onInput={(e) => props.onSearchChange?.(e.target.value)}
      />
      <Show
        when={!props.value?.length}
        fallback={
          <span onClick={() => props.onSearchChange?.('')}>
            <XIcon />
          </span>
        }
      >
        <SearchIcon />
      </Show>
    </div>
  );
};
