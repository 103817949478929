import type { StudioSlide } from '@imagene/fm-studio-interfaces';
import { createQuery } from '@tanstack/solid-query';
import { SlidesViewMode } from '../../../business-components/SlidesUploadDrawer';
import { useAxios } from '../../../utils/axios';
import { createSignal } from 'solid-js';
import { useSelectedProject } from '../../../utils/use-selected-project';
import { useUploadQueue } from '../../../utils/upload-queue-manager';

export function useSlides(options: { refetchSlides?: boolean } = {}) {
  const axios = useAxios();
  const { uploads } = useUploadQueue();
  const [viewMode, setViewMode] = createSignal<SlidesViewMode>('list');
  const [searchTerm, setSearchTerm] = createSignal('');
  const [isDrawerCollapsed, setDrawerCollapsed] = createSignal(true);
  const [isUploadModalOpen, setUploadModalOpen] = createSignal(false);
  const project = useSelectedProject();
  const projectId = () => project.selectedProject()?.id;

  const slidesQuery = createQuery(
    () => ['slides', projectId()],
    async () => {
      const res = await axios
        .get<StudioSlide[]>(`/projects/${projectId()}/slides`)
        .then((res) => res.data);
      return res;
    },
    {
      cacheTime: 0,
      refetchInterval: options.refetchSlides ? 10000 : false,
      get enabled() {
        return Boolean(projectId());
      },
    }
  );

  const uploadedSlides = () => slidesQuery.data ?? [];
  const mappedUploads = () =>
    uploads().map<StudioSlide>((s) => {
      const uploadStatus =
        s.state === 'DONE'
          ? 'done'
          : s.state === 'ERROR' || s.state === 'TIMEOUT'
          ? 'error'
          : 'ongoing';
      return {
        ...s,
        slideId: s.slide_id,
        fileName: s.name,
        uploadProgress: s.percentage,
        uploadStatus,
      };
    });

  const allSlides = () => [...uploadedSlides(), ...mappedUploads()];

  const gallerySlides = () =>
    allSlides().filter((s) => s.uploadStatus === 'done');

  return {
    viewMode,
    setViewMode,
    searchTerm,
    setSearchTerm,
    isDrawerCollapsed,
    setDrawerCollapsed,
    isUploadModalOpen,
    setUploadModalOpen,
    gallerySlides,
    allSlides,
    loadingSlides: () => slidesQuery.isLoading,
    uploadedSlides,
    mappedUploads,
  };
}
