export function toFixed(num: number | undefined, fixed?: number) {
  if (num === null || num === undefined) return '';

  const index = num.toString().indexOf('.');
  if (index === -1) {
    return num.toString();
  }

  const re = new RegExp('^-?\\d+(?:\\.\\d{0,' + (fixed || -1) + '})?');
  return num
    .toString()
    .match(re)?.[0]
    .padEnd((fixed ?? 0) + 2, '0');
}
