import { ParentComponent } from 'solid-js';
import { Card } from '../../../components/Card';
import classes from './model-naming.module.css';
import { Input } from '../../../components/Inputs/Input';
import { Typography } from '../../../components/Typography';
import { Textarea } from '../../../components/Inputs/Textarea';

interface ModelNamingProps {
  name: string;
  description: string;
  onNameChange: (name: string) => void;
  onDescriptionChange: (desc: string) => void;
}

export const ModelNaming: ParentComponent<ModelNamingProps> = (props) => {
  return (
    <Card>
      <div class={classes['model-naming']}>
        <div class={classes['name-input']}>
          <Typography>Please name your model</Typography>
          <Input
            placeholder="Name your model"
            value={props.name}
            onInput={(e) => props.onNameChange(e.currentTarget.value)}
          />
        </div>

        <div class={classes['desc-input']}>
          <Typography>Description (optional)</Typography>
          <Textarea
            value={props.description}
            placeholder="Add a description"
            onInput={(e) => props.onDescriptionChange(e.currentTarget.value)}
            rows={5}
          />
        </div>
        {props.children}
      </div>
    </Card>
  );
};
