import { Component, Show } from 'solid-js';
import { Card } from '../../components/Card';
import { Typography } from '../../components/Typography';
import classes from './project-card.module.css';
import backgroundSmallURL from '../../assets/background-small.png';
import { useDateFormat } from '../../utils/use-date-format';
import { Skeleton } from '../../components/Skeleton';

interface ProjectCardProps {
  name: string;
  slug: string;
  id: number;
  cohort?: number;
  dataColumns?: number;
  models?: number;
  slides?: number;
  isSample?: boolean;
  updatedAt: string;
  onClick: VoidFunction;
}

export function ProjectCard(props: ProjectCardProps) {
  return (
    <Card>
      <div
        class={classes['project-info-card']}
        classList={{ [classes.sample]: props.isSample }}
        onClick={props.onClick}
      >
        <img class={classes['project-info-graph']} src={backgroundSmallURL} />
        <Typography
          component="h3"
          title={props.name}
          weight="semi-bold"
          wrap="50ch"
        >
          {props.name}
        </Typography>
        <Show when={props.isSample}>
          <Typography color="primary">(Sample)</Typography>
        </Show>
        <div class={classes['project-info-text']}>
          <div>
            <Typography>Samples cohort:</Typography>
            <Typography>{props.cohort}</Typography>
          </div>
          <div>
            <Typography>Slides:</Typography>
            <Typography>{props.slides}</Typography>
          </div>
          <div>
            <Typography>Data columns:</Typography>
            <Typography>{props.dataColumns}</Typography>
          </div>
          <div>
            <Typography>Models:</Typography>
            <Typography>{props.models}</Typography>
          </div>
        </div>
        <div>
          <Typography color="disabled">Last update: </Typography>
          <Typography color="disabled">
            {useDateFormat(props.updatedAt)}
          </Typography>
        </div>
      </div>
    </Card>
  );
}

export const ProjectCardSkeleton: Component = () => (
  <div class={classes.skeleton}>
    <Skeleton />
    <Skeleton />
    <div class={classes.skeleton_row}>
      <Skeleton width={50} />
      <Skeleton width={25} />
    </div>
    <div class={classes.skeleton_row}>
      <Skeleton width={96} />
      <Skeleton width={18} />
    </div>
    <div class={classes.skeleton_row}>
      <Skeleton width={53} />
      <Skeleton width={10} />
    </div>
    <Skeleton width={150} />
  </div>
);
