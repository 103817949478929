import { toFixed } from '../../utils/precise-numbers-utils';

function formatAUC(auc: number, precision: number | undefined) {
  return toFixed(auc ?? 0, precision);
}

export interface UseAUCProps {
  auc?: number;
  precision?: number;
  round?: boolean;
}

export const useAUC = (props: UseAUCProps) => {
  const value = () => props.auc ?? 0;
  const auc = () => {
    const precision = props.precision ?? 4;
    if (props.round) {
      const multiplier = Math.pow(10, precision);
      const rounded = Math.round(value() * multiplier) / multiplier;
      return formatAUC(rounded, props.precision);
    }
    const auc = formatAUC(value(), precision);
    return auc;
  };

  const fillColor = () => {
    if (value() <= 0.6) {
      return '#F3D6D6';
    }
    if (value() > 0.6 && value() <= 0.75) {
      return '#FCF3D3';
    }
    if (value() > 0.75 && value() <= 0.85) {
      return '#D6F3D9';
    }
    if (value() > 0.85) {
      return '#CFECE2';
    }
  };

  const lineColor = () => {
    if (value() <= 0.6) {
      return '#BD3B3B';
    }
    if (value() > 0.6 && value() <= 0.75) {
      return '#F2A206';
    }
    if (value() > 0.75 && value() <= 0.85) {
      return '#30C13F';
    }
    if (value() > 0.85) {
      return '#0E9F6E';
    }
  };

  return {
    auc,
    lineColor,
    fillColor,
  };
};
