import { Accessor, createContext, ParentProps, useContext } from 'solid-js';
import { useAxios } from './axios';
import { createQuery } from '@tanstack/solid-query';
import { QuotaResponse } from '@imagene/fm-studio-interfaces';

export interface TrialQuotaContext {
  canCreateProject: Accessor<boolean>;
  canCreateTCGAProject: Accessor<boolean>;
  canCreateModel: Accessor<boolean>;
  canCreateTCGAModel: Accessor<boolean>;
  projectsAmount: Accessor<number>;
  projectsLimit: Accessor<number>;
  tcgaProjectsAmount: Accessor<number>;
  tcgaProjectsLimit: Accessor<number>;
  modelsAmount: Accessor<number>;
  modelsLimit: Accessor<number>;
  tcgaModelsAmount: Accessor<number>;
  tcgaModelsLimit: Accessor<number>;
  embeddingsAmount: Accessor<number>;
  embeddingsLimit: Accessor<number>;
  tcgaEmbeddingsAmount: Accessor<number>;
  tcgaEmbeddingsLimit: Accessor<number>;
  mailToString: Accessor<string>;
}

const TrialQuotaContext = createContext<TrialQuotaContext>(
  {} as TrialQuotaContext
);

export function useTrialQuota() {
  return useContext(TrialQuotaContext);
}

export function TrialQuotaProvider(props: ParentProps) {
  const axios = useAxios();

  const quotaQuery = createQuery(
    () => ['quota'],
    () => axios.get<QuotaResponse>('/quota').then((res) => res.data)
  );

  const canCreateProject = () => Boolean(quotaQuery.data?.canCreateProject);
  const canCreateTCGAProject = () =>
    Boolean(quotaQuery.data?.canCreateTCGAProject);
  const canCreateModel = () => Boolean(quotaQuery.data?.canCreateModel);
  const canCreateTCGAModel = () => Boolean(quotaQuery.data?.canCreateTCGAModel);
  const projectsAmount = () => quotaQuery?.data?.projectsAmount ?? 0;
  const projectsLimit = () => quotaQuery?.data?.projectsLimit ?? 0;
  const tcgaProjectsAmount = () => quotaQuery?.data?.tcgaProjectsAmount ?? 0;
  const tcgaProjectsLimit = () => quotaQuery?.data?.tcgaProjectsLimit ?? 0;
  const modelsAmount = () => quotaQuery?.data?.modelsAmount ?? 0;
  const modelsLimit = () => quotaQuery?.data?.modelsLimit ?? 0;
  const tcgaModelsAmount = () => quotaQuery?.data?.tcgaModelsAmount ?? 0;
  const tcgaModelsLimit = () => quotaQuery?.data?.tcgaModelsLimit ?? 0;
  const embeddingsAmount = () => quotaQuery?.data?.embeddingsAmount ?? 0;
  const embeddingsLimit = () => quotaQuery?.data?.embeddingsLimit ?? 0;
  const tcgaEmbeddingsAmount = () =>
    quotaQuery?.data?.tcgaEmbeddingsAmount ?? 0;
  const tcgaEmbeddingsLimit = () => quotaQuery?.data?.tcgaEmbeddingsLimit ?? 0;

  const mailToString = () =>
    `mailto:oisuite@imagene-ai.com?subject=OISuite Subscription Inquiry&body=Hi Imagene team,%0D%0AI'm interested in learning more about the paid subscription options for OISuite. Could you please send me more information?%0D%0AThank you!`;

  return (
    <TrialQuotaContext.Provider
      value={{
        canCreateProject,
        canCreateModel,
        canCreateTCGAModel,
        canCreateTCGAProject,
        projectsAmount,
        projectsLimit,
        tcgaProjectsAmount,
        tcgaProjectsLimit,
        modelsAmount,
        modelsLimit,
        tcgaModelsAmount,
        tcgaModelsLimit,
        embeddingsAmount,
        embeddingsLimit,
        tcgaEmbeddingsAmount,
        tcgaEmbeddingsLimit,
        mailToString,
      }}
    >
      {props.children}
    </TrialQuotaContext.Provider>
  );
}
