import {
  Component,
  createMemo,
  createUniqueId,
  For,
  Show,
  splitProps,
} from 'solid-js';
import * as slider from '@zag-js/slider';
import { normalizeProps, useMachine } from '@zag-js/solid';

import classes from './slider.module.css';
import { Typography } from '../Typography';

export interface SliderProps extends Omit<slider.Context, 'id'> {
  label?: string;
  transformValue?: (value: number | undefined) => string;
  compliment?: boolean;
  transformCompliment?: (value: number | undefined) => string;
}

export const Slider: Component<SliderProps> = (props) => {
  const [context, local] = splitProps(props, ['step', 'value']);
  const [state, send] = useMachine(
    slider.machine({
      ...local,
      id: createUniqueId(),
      thumbAlignment: 'center',
      min: props.min,
      max: props.max,
      step: context.step,
      disabled: props.readOnly,
    }),
    {
      context,
    }
  );

  const api = createMemo(() => slider.connect(state, send, normalizeProps));

  const transformValue = (value: number | undefined) =>
    props.transformValue?.(value) ?? value?.toString() ?? '';

  const compliment = () => 100 - (api().value.at(0) ?? 0);

  const transformCompliment = (value: number | undefined) =>
    props.transformCompliment?.(value) ?? value?.toString() ?? '';

  return (
    <div {...api().getRootProps()}>
      <Show when={props.label}>
        {(label) => <label {...api().getLabelProps()}>{label()}</label>}
      </Show>
      <div {...api().getControlProps()}>
        <div {...api().getTrackProps()}>
          <div {...api().getRangeProps()}>
            <output {...api().getValueTextProps()}>
              <div class={classes['text-wrapper']}>
                <Typography class={classes['slider-value']}>
                  {transformValue(api().value.at(0))}
                </Typography>
              </div>
            </output>
          </div>
          <Show when={props.compliment}>
            <output>
              <div class={classes['text-wrapper']}>
                <Typography class={classes['slider-value']}>
                  {transformCompliment(compliment())}
                </Typography>
              </div>
            </output>
          </Show>
        </div>
        <For each={api().value}>
          {(_, index) => (
            <Show when={!props.readOnly}>
              <div {...api().getThumbProps({ index: index() })}>
                <input {...api().getHiddenInputProps({ index: index() })} />
              </div>
            </Show>
          )}
        </For>
      </div>
    </div>
  );
};
