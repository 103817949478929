import { Typography } from '../../components/Typography';
import classes from './slide-thumbnail.module.css';
import placeholderUrl from '../../assets/thumbnail-placeholder.png';
import type { StudioSlide } from '@imagene/fm-studio-interfaces';
import { Component } from 'solid-js';

interface SlideThumbnailProps {
  slide: Pick<StudioSlide, 'slideId' | 'thumbnail_url'>;
}

export const SlideThumbnail: Component<SlideThumbnailProps> = (props) => {
  const imgUrl = () => props.slide.thumbnail_url ?? placeholderUrl;

  return (
    <div class={classes['slide-thumbnail']}>
      <img src={imgUrl()} />
      <div class={classes['slide-id']}>
        <Typography>Slide ID:</Typography>
        <Typography weight="bold" wrap="20ch" title={props.slide.slideId}>
          {props.slide.slideId}
        </Typography>
      </div>
    </div>
  );
};
