import { ParentComponent } from 'solid-js';
import { SplitByPercentage, SplitByPercentageProps } from './SplitByPrecentage';
import { Card } from '../../../components/Card';

import classes from './define-split.module.css';

type DefineSplitProps = SplitByPercentageProps;

export const DefineSplit: ParentComponent<DefineSplitProps> = (props) => (
  <Card>
    <div class={classes.split}>
      <SplitByPercentage {...props} />
      {props.children}
    </div>
  </Card>
);
