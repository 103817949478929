import { Component, Show } from 'solid-js';
import { Card } from '../../components/Card';
import { Typography } from '../../components/Typography';
import classes from './trial-info-card.module.css';
import { CheckmarkCircleIcon } from '../../components/icons/CheckmarkCircleIcon';
import { Button } from '../../components/Button';
import { useTrialQuota } from '../../utils/use-trial-quota';

export const TrialInfoCard: Component = () => {
  const {
    embeddingsLimit,
    tcgaEmbeddingsLimit,
    projectsLimit,
    tcgaProjectsLimit,
    modelsLimit,
    mailToString,
  } = useTrialQuota();
  return (
    <Card>
      <div class={classes['trial-info-card']}>
        <div class={classes.title}>
          <Typography weight="bold" component="h2">
            Discovery
          </Typography>
          <Typography color="secondary" component="h3">
            1 month
          </Typography>
          <Typography>The Discovery package overview:</Typography>
        </div>
        <div class={classes['info-columns']}>
          <TrialInfoColumn
            label="TCGA"
            embeddingsLimit={tcgaEmbeddingsLimit()}
            modelsLimit={modelsLimit()}
            projectsLimit={tcgaProjectsLimit()}
            showStudy
          />
          <TrialInfoColumn
            label="Internal"
            embeddingsLimit={embeddingsLimit()}
            modelsLimit={modelsLimit()}
            projectsLimit={projectsLimit()}
          />
        </div>
        <a target="_blank" href={mailToString()}>
          <Button variant="action" fullWidth>
            Contact us to explore offering
          </Button>
        </a>
      </div>
    </Card>
  );
};

interface TrialInfoColumnProps {
  label: string;
  projectsLimit: number;
  modelsLimit: number;
  embeddingsLimit: number;
  showStudy?: boolean;
}

const TrialInfoColumn: Component<TrialInfoColumnProps> = (props) => (
  <div class={classes['info-column']}>
    <Typography weight="bold">{props.label} data</Typography>
    <InfoRow label="project" amount={props.projectsLimit} />
    <InfoRow label="models" amount={props.modelsLimit} />
    <InfoRow
      label="embeddings"
      amount={props.embeddingsLimit}
      showStudy={props.showStudy}
    />
  </div>
);
interface InfoRowProps {
  label: string;
  amount: number;
  showStudy?: boolean;
}

const InfoRow: Component<InfoRowProps> = (props) => (
  <div class={classes['info-row']}>
    <CheckmarkCircleIcon />
    <Typography weight="bold">{props.amount}</Typography>
    <Typography>{props.label}</Typography>
    <Show when={props.showStudy}>
      <Typography>(TCGA-LUAD Study)</Typography>
    </Show>
  </div>
);
