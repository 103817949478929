import { For, ParentProps, Show } from 'solid-js';
import { SidebarItem } from './SidebarItem';
import classes from './sidebar.module.css';
import { OILogoSmall } from '../../components/icons/OILogoSmall';
import { OILogo } from '../../components/icons/OILogo';
import { CircleArrowIcon } from '../../components/icons/CircleArrowIcon';
import { HomepageIcon } from '../../components/icons/HomepageIcon';
import { A } from '@solidjs/router';
import { SelectedProjectContext } from '../../utils/use-selected-project';
import { Typography } from '../../components/Typography';
import { SidebarSelectedProject } from './SelectedProject';
import { SidebarModelItem } from './SidebarModelItem';
import { HR } from '../../components/HR';
import { useModels } from '../../pages/projects/Models/use-models';
import { TrialLimit } from '../TrialLimit';

interface SidebarProps {
  onToggle: () => void;
  collapsed: boolean;
  project: SelectedProjectContext;
}

export function Sidebar(props: ParentProps<SidebarProps>) {
  const { models } = useModels();

  return (
    <aside
      id="side-bar"
      classList={{
        [classes.sidebar]: true,
        [classes.collapsed]: props.collapsed,
      }}
    >
      <div class={classes['collapse-icon']} onClick={() => props.onToggle()}>
        <CircleArrowIcon />
      </div>
      <A href="/">{props.collapsed ? <OILogoSmall /> : <OILogo />}</A>
      <SidebarItem
        route="/projects"
        text="My Projects"
        icon={<HomepageIcon />}
        collapsed={props.collapsed}
      />
      <HR />
      <Show when={props.project.selectedProject()}>
        {(selected) => (
          <>
            <div class={classes['selected-project']}>
              <SidebarSelectedProject
                project={selected()}
                collapsed={props.collapsed}
              />
            </div>
            <HR />
            <div class={classes.models}>
              <Show when={!props.collapsed}>
                <Typography color="secondary" class={classes['models-title']}>
                  Models
                </Typography>
              </Show>
              <Show
                when={Boolean(models().length)}
                fallback={
                  <Show when={!props.collapsed}>
                    <Typography color="secondary" class={classes['no-models']}>
                      No created model yet
                    </Typography>
                  </Show>
                }
              >
                <For each={models()}>
                  {(model) => (
                    <SidebarModelItem
                      collapsed={props.collapsed}
                      model={model}
                      project_slug={selected().slug}
                    />
                  )}
                </For>
              </Show>
            </div>
          </>
        )}
      </Show>
      <Show when={!props.collapsed}>
        <TrialLimit />
      </Show>
    </aside>
  );
}
