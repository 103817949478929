import { Component } from 'solid-js';
import { Typography } from '../../../components/Typography';
import { Slider, SliderProps } from '../../../components/Slider';

import classes from './define-split.module.css';

export type SplitByPercentageProps = Pick<
  SliderProps,
  'value' | 'onValueChange'
> & {
  total: number;
  readOnly?: boolean;
  title?: string;
};

export const SplitByPercentage: Component<SplitByPercentageProps> = (props) => {
  const transformValue = (value: number | undefined) => {
    if (!value || value === 0) return '';
    const percentage = ((value ?? 0) / 100) * props.total;
    const calc = Number(percentage.toPrecision(4));
    return `${value?.toFixed()}% / ${Math.ceil(calc)}`;
  };

  const transformCompliment = (value: number | undefined) => {
    if (!value || value === 0) return '';
    const complimentVal = 100 - value;
    const percentage = ((complimentVal ?? 0) / 100) * props.total;
    const calc = Number(percentage.toPrecision(4));
    const count = Math.ceil(calc);
    const compCount = props.total - count;
    return `${value?.toFixed()}% / ${compCount}`;
  };

  const title = () => props.title ?? 'Define Split';

  return (
    <section class={classes['split-percentage']}>
      <Typography component="h2">{title()}</Typography>
      <div class={classes.percentage}>
        <Typography>Train + Validation</Typography>
        <Typography>Test</Typography>
        <Slider
          {...props}
          compliment
          transformValue={transformValue}
          transformCompliment={transformCompliment}
          step={Math.max(100 / props.total, 1)}
        />
      </div>
    </section>
  );
};
