import { Typography } from '../../../components/Typography';
import { Card } from '../../../components/Card';
import { SlidesGallery } from '../../../business-components/SlidesGallery';
import classes from './slides.module.css';
import type { StudioSlide } from '@imagene/fm-studio-interfaces';
import { NoSlides } from '.';
import { Show } from 'solid-js';

interface SlidesGalleryCardProps {
  slides: StudioSlide[];
  addFiles: (files: File[]) => void;
  isTCGA: boolean;
  projectRelPath: string;
}

export function SlidesGalleryCard(props: SlidesGalleryCardProps) {
  return (
    <Card>
      <div class={classes['slides-gallery']}>
        <Typography component="h3" weight="bold">
          Slides gallery
        </Typography>
        <Show
          when={!props.slides.length}
          fallback={<SlidesGallery slides={props.slides} />}
        >
          <NoSlides
            addFiles={props.addFiles}
            isTCGA={props.isTCGA}
            projectRelPath={props.projectRelPath}
          />
        </Show>
      </div>
    </Card>
  );
}
