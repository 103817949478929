import {
  Accessor,
  createContext,
  createSignal,
  ParentComponent,
  useContext,
} from 'solid-js';
import { createStore } from 'solid-js/store';

interface WizardChecklistContextProps {
  steps: WizardChecklistStep[];
  currentStep: Accessor<number>;
  setCollapse: (step: number, collapse: boolean) => void;
  setValue: (value: string) => void;
  goToStep: (step: number) => void;
}

export interface WizardChecklistStep {
  title: string;
  description: string;
  collapse: boolean;
  value: string | undefined | null;
}

const WizardChecklistContext = createContext<WizardChecklistContextProps>(
  {} as WizardChecklistContextProps
);

export const useChecklistWizard = () => useContext(WizardChecklistContext);

export const WizardChecklistProvider: ParentComponent<
  Pick<WizardChecklistContextProps, 'steps'> & {
    initialStep?: number;
  }
> = (props) => {
  const [currentStep, setCurrentStep] = createSignal(props.initialStep ?? 0);
  const [steps, setSteps] = createStore<WizardChecklistStep[]>(props.steps);

  const setCollapse = (step: number, collapse: boolean) => {
    setSteps(step, 'collapse', collapse);
  };

  const setValue = (value: string) => {
    setSteps(currentStep(), 'value', value);
    setCurrentStep(currentStep() + 1);
    setSteps((steps) =>
      steps.map((s, i) => ({ ...s, collapse: currentStep() !== i }))
    );
  };

  const goToStep = (step: number) => {
    const targetStep = steps.at(step);
    if (!targetStep?.value) {
      console.warn(
        `tried to go to step "${targetStep?.title}" that is not yet filled`
      );
      return;
    }
    setCurrentStep(step);
  };

  return (
    <WizardChecklistContext.Provider
      value={{
        steps,
        currentStep,
        setCollapse,
        setValue,
        goToStep,
      }}
    >
      {props.children}
    </WizardChecklistContext.Provider>
  );
};
