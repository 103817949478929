import { Component } from 'solid-js';
import classes from './hr.module.css';

interface HRProps {
  fade?: boolean;
  vertical?: boolean;
}

export const HR: Component<HRProps> = (props) => {
  return (
    <hr
      class={classes.hr}
      classList={{
        [classes.fade]: props.fade,
        [classes.vertical]: props.vertical,
      }}
    />
  );
};
