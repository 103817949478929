export function ErrorXIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24C5 13.5066 13.5066 5 24 5C34.4934 5 43 13.5066 43 24ZM48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM33.7678 14.2322C34.7441 15.2085 34.7441 16.7915 33.7678 17.7678L27.5355 24L33.7678 30.2322C34.7441 31.2085 34.7441 32.7915 33.7678 33.7678C32.7915 34.7441 31.2085 34.7441 30.2322 33.7678L24 27.5355L17.7678 33.7678C16.7915 34.7441 15.2085 34.7441 14.2322 33.7678C13.2559 32.7915 13.2559 31.2085 14.2322 30.2322L20.4645 24L14.2322 17.7678C13.2559 16.7915 13.2559 15.2085 14.2322 14.2322C15.2085 13.2559 16.7915 13.2559 17.7678 14.2322L24 20.4645L30.2322 14.2322C31.2085 13.2559 32.7915 13.2559 33.7678 14.2322Z"
        fill="#BD3B3B"
      />
    </svg>
  );
}
