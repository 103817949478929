import { Component, Match, Show, Switch } from 'solid-js';
import type { ModelPredictorState } from '@imagene/fm-studio-interfaces';
import { Card } from '../../../components/Card';
import { Progress } from '../../../components/Progress';
import { Typography } from '../../../components/Typography';
import { Button } from './../../../components/Button';
import { CircleX } from '../../../components/icons/CircleX';
import { CircleCheck } from '../../../components/icons/CircleCheck';

import classes from './training-progress.module.css';

interface TrainingProgressProps {
  percentage: number | undefined;
  minutes: number | undefined;
  state: ModelPredictorState | undefined;
  error: string | undefined;
  onClick?: VoidFunction;
}

export const TrainingProgress: Component<TrainingProgressProps> = (props) => {
  return (
    <Card>
      <div class={classes['training-progress']}>
        <Switch fallback={<TrainingProgressContent {...props} />}>
          <Match when={props.state === 'ERROR'}>
            <TrainingProgressError {...props} />
          </Match>
          <Match when={props.state === 'DONE'}>
            <TrainingProgressSuccess {...props} />
          </Match>
        </Switch>
      </div>
    </Card>
  );
};

const TrainingProgressContent: Component<TrainingProgressProps> = (props) => (
  <>
    <Typography weight="semi-bold" component="h1">
      Please wait, model training is in progress...
    </Typography>
    <Typography color="secondary" class={classes.description}>
      The built-in baseline algorithm is being trained based on your setup.
      <br />
      It evaluates the performance of multiple models using validation results
      and ensembles the best-performing model.
      <br />
      If you have defined a test set, the final model ensemble will be tested on
      it
    </Typography>
    <div class={classes.progress}>
      <Typography>ETA: {props.minutes?.toFixed()} minutes</Typography>
      <Progress max={100} value={props.percentage} />
    </div>
  </>
);

const TrainingProgressError: Component<TrainingProgressProps> = (props) => (
  <>
    <CircleX />
    <Typography component="h3">
      The server encountered an error during model training
    </Typography>
    <Typography class={classes.error}>
      <Show
        when={props.error}
        fallback="Please try again or create a new model"
      >
        {props.error}
      </Show>
    </Typography>
    <div class={classes.cta}>
      <Button onClick={props.onClick}>Retry training</Button>
    </div>
  </>
);

const TrainingProgressSuccess: Component<TrainingProgressProps> = (props) => (
  <>
    <CircleCheck />
    <Typography component="h3">Your model is successfully trained.</Typography>
    <div class={classes.cta}>
      <Button onClick={props.onClick}>View the results</Button>
    </div>
  </>
);
