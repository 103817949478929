import { Component, Show } from 'solid-js';
import { useModels } from '../../../pages/projects/Models/use-models';
import { ModelCard, OnModelEdit } from '../../ModelCard';

export const ModelOverview: Component = () => {
  const { model, updateSelectedModel, setModelStore } = useModels();

  const onModelUpdate: OnModelEdit = (details) => {
    setModelStore('name', details.name);
    setModelStore('description', details.description);
    updateSelectedModel.mutate();
  };

  return (
    <Show when={model()}>
      {(model) => <ModelCard {...model()} editable onEdit={onModelUpdate} />}
    </Show>
  );
};
