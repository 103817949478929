import { Component, createEffect, Match, Suspense, Switch } from 'solid-js';
import { useNavigate } from '@solidjs/router';
import { createQuery } from '@tanstack/solid-query';
import type { PredictionTarget } from '@imagene/fm-studio-interfaces';

import { useAxios } from '../../utils/axios';
import { useChecklistWizard } from '../WizardChecklist/use-wizard-checklist';
import { WizardChecklist } from '../WizardChecklist';
import { ModelNaming } from './ModelNaming';
import { Typography } from '../../components/Typography';
import { useSelectedProject } from '../../utils/use-selected-project';
import { SelectPredictionTarget } from './SelectPredictionTarget';
import { Button } from '../../components/Button';
import { DefineSplit } from './DefineSplit';
import { ModelSummary } from './ModelSummary';
import { TrainingProgress } from './TrainingProgress';
import { useModels } from '../../pages/projects/Models/use-models';
import { useSlides } from '../../pages/projects/Slides/useSlides';

import classes from './model-wizard.module.css';
import { ModelWizardSteps } from '../../pages/projects/Models/EditModel';
import { Skeleton } from '../../components/Skeleton';

export const ModelWizard: Component = () => {
  const axios = useAxios();
  const { modelStore, setModelStore, saveDraft, startTraining, trainState } =
    useModels();
  const { allSlides } = useSlides();
  const { selectedProject } = useSelectedProject();
  const { currentStep, setValue, goToStep } = useChecklistWizard();
  const navigate = useNavigate();

  const predictionColumns = createQuery(
    () => ['cohort', 'columns'],
    () =>
      axios
        .get<PredictionTarget[]>(
          `/projects/${selectedProject()?.id}/cohorts/columns`
        )
        .then((res) => res.data),
    {
      get enabled() {
        return Boolean(selectedProject()?.id);
      },
    }
  );

  const predictionTarget = () => {
    if (!modelStore.prediction_column) return '';
    return `${modelStore.prediction_column}:${modelStore.positive_label}`;
  };

  const availableSlides = () =>
    allSlides().filter((s) => s.dataMatching && s.embeddingsStatus === 'done')
      .length;

  const splitValue = () => {
    if (!modelStore.split_train_validation_percentage) {
      setModelStore('split_train_validation_percentage', 75);
      setModelStore('split_test_percentage', 25);
    }
    return `By percentage - ${modelStore.split_train_validation_percentage?.toFixed()}% / ${modelStore.split_test_percentage?.toFixed()}%`;
  };

  const onSplitChange = (value: number) => {
    setModelStore('split_train_validation_percentage', value);
    setModelStore('split_test_percentage', 100 - value);
  };

  const saveAndContinue = async (value: string) => {
    const newModel = await saveDraft.mutateAsync();
    setValue(value);
    return newModel;
  };

  const onStartTraining = async () => {
    await startTraining.mutateAsync();
    setValue('   ');
  };

  const onTrainComplete = () => {
    if (trainState.data?.state === 'DONE') {
      navigate(
        `/projects/${selectedProject()?.slug}/models/${modelStore.slug}/results`
      );
    }
    if (trainState.data?.state === 'ERROR') {
      goToStep(ModelWizardSteps.TRAIN);
    }
  };

  createEffect(() => {
    // stop refetching training status
    if (['DONE', 'ERROR'].includes(trainState.data?.state ?? 'INIT')) {
      setModelStore('trainId', null);
    }
  });

  const onModelNameChange = async () => {
    const updatedModel = await saveAndContinue(modelStore.name);
    navigate(
      `/projects/${selectedProject()?.slug}/models/${updatedModel.slug}/wizard`,
      { replace: true }
    );
  };

  return (
    <div class={classes.wizard}>
      <Switch>
        <Match when={currentStep() === ModelWizardSteps.MODEL_NAME}>
          <div class={classes.header}>
            <Typography component="h2">Name a new model</Typography>
          </div>
          <ModelNaming
            name={modelStore.name}
            description={modelStore.description ?? ''}
            onNameChange={(name) => setModelStore('name', name)}
            onDescriptionChange={(desc) => setModelStore('description', desc)}
          >
            <ActionButtons
              disabled={saveDraft.isLoading}
              nextEnabled={Boolean(modelStore.name)}
              backTxt="Cancel"
              onNext={onModelNameChange}
              onBack={() => navigate(-1)}
            />
          </ModelNaming>
        </Match>
        <Match when={currentStep() === ModelWizardSteps.PREDICTION_TARGET}>
          <div class={classes.header}>
            <Typography component="h2">{modelStore.name}</Typography>
          </div>
          <SelectPredictionTarget
            columns={predictionColumns.data ?? []}
            target={predictionTarget()}
            onLabelSelect={({ column, label }) => {
              setModelStore('prediction_column', column);
              setModelStore('positive_label', label);
            }}
          >
            <ActionButtons
              disabled={saveDraft.isLoading}
              nextEnabled={
                Boolean(modelStore.prediction_column) &&
                Boolean(modelStore.positive_label)
              }
              onNext={() => saveAndContinue(predictionTarget())}
              onBack={() => goToStep(ModelWizardSteps.MODEL_NAME)}
            />
          </SelectPredictionTarget>
        </Match>
        <Match when={currentStep() === ModelWizardSteps.DEFINE_SPLIT}>
          <div class={classes.header}>
            <Typography component="h2">{modelStore.name}</Typography>
          </div>
          <DefineSplit
            total={availableSlides()}
            value={[modelStore.split_train_validation_percentage ?? 75]}
            onValueChange={(details) => onSplitChange(details.value[0])}
          >
            <span></span>
            <ActionButtons
              disabled={saveDraft.isLoading}
              nextEnabled
              onNext={() => saveAndContinue(splitValue())}
              onBack={() => goToStep(ModelWizardSteps.PREDICTION_TARGET)}
            />
          </DefineSplit>
        </Match>
        <Match when={currentStep() === ModelWizardSteps.TRAIN}>
          <div class={classes['align-start']}>
            <ModelSummary
              name={modelStore.name}
              description={modelStore.description ?? ''}
              input_data={[]}
              disabled={startTraining.isLoading}
              onClick={onStartTraining}
              onBack={() => goToStep(ModelWizardSteps.DEFINE_SPLIT)}
              prediction_labels={['Positive', 'Negative']}
              prediction_target={predictionTarget()}
            />
          </div>
        </Match>
        <Match when={currentStep() === ModelWizardSteps.TRAINING}>
          <div class={classes['align-start']}>
            <TrainingProgress
              state={trainState.data?.state}
              error={trainState.data?.error}
              minutes={trainState.data?.ETA}
              percentage={trainState.data?.percentage}
              onClick={onTrainComplete}
            />
          </div>
        </Match>
      </Switch>
      <Suspense fallback={ <Skeleton />}>
        <WizardChecklist />
      </Suspense>
    </div>
  );
};

const ActionButtons: Component<{
  disabled: boolean;
  nextEnabled: boolean;
  backTxt?: string;
  onBack: VoidFunction;
  onNext: VoidFunction;
}> = (props) => (
  <div class={classes.buttons}>
    <Button
      disabled={props.disabled}
      variant="secondary"
      onClick={props.onBack}
    >
      {props.backTxt ?? 'Back'}
    </Button>
    <Button
      onClick={props.onNext}
      disabled={props.disabled || !props.nextEnabled}
    >
      Next
    </Button>
  </div>
);
