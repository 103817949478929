import { Component, For } from 'solid-js';
import { WizardChecklistStep } from './WizardChecklistStep';
import { Typography } from '../../components/Typography';
import { Progress } from '../../components/Progress';
import { useChecklistWizard } from './use-wizard-checklist';

import classes from './wizard-checklist.module.css';

export const WizardChecklist: Component = () => {
  const { steps, currentStep, setCollapse } = useChecklistWizard();
  const totalSteps = () => steps.length;
  const current = () => Math.min(currentStep(), totalSteps());

  return (
    <aside class={classes.checklist}>
      <header>
        <Typography component="h4">Create new model checklist</Typography>
        <Progress min={0} max={totalSteps()} value={currentStep()} />
        <Typography class={classes.summary}>
          You've completed {current()} out of {totalSteps()} steps
        </Typography>
      </header>
      <ul>
        <For each={steps}>
          {(step, idx) => (
            <WizardChecklistStep
              title={step.title}
              description={step.description}
              step={idx()}
              steps={totalSteps()}
              active={idx() === currentStep()}
              value={step.value}
              collapsed={step.collapse}
              onCollapseChange={(collapse) => setCollapse(idx(), collapse)}
            />
          )}
        </For>
      </ul>
    </aside>
  );
};
