export function GreenCheckmarkCircleIcon() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.64937 0.525391C3.22203 0.525391 0.433594 3.42972 0.433594 6.99951C0.433594 10.5693 3.22203 13.4736 6.64937 13.4736C10.0767 13.4736 12.8652 10.5693 12.8652 6.99951C12.8652 3.42972 10.0767 0.525391 6.64937 0.525391ZM9.88427 4.82975L5.86792 9.80984C5.82386 9.8645 5.76904 9.90866 5.70717 9.93932C5.6453 9.96998 5.57783 9.98644 5.50932 9.98757H5.50125C5.43423 9.98755 5.36797 9.97285 5.30676 9.94443C5.24554 9.91601 5.19075 9.8745 5.14593 9.82261L3.42464 7.83057C3.38092 7.78227 3.34692 7.72537 3.32462 7.66319C3.30232 7.60102 3.29218 7.53482 3.2948 7.46851C3.29741 7.40219 3.31272 7.33709 3.33984 7.27702C3.36696 7.21696 3.40532 7.16315 3.45269 7.11875C3.50006 7.07435 3.55547 7.04026 3.61567 7.01849C3.67586 6.99671 3.73963 6.98769 3.80322 6.99195C3.86682 6.99621 3.92895 7.01367 3.98597 7.0433C4.04299 7.07292 4.09375 7.11413 4.13527 7.16448L5.4887 8.73072L9.15212 4.18918C9.2343 4.09023 9.35056 4.02893 9.47578 4.01854C9.60099 4.00815 9.72511 4.04949 9.82129 4.13365C9.91747 4.2178 9.978 4.33799 9.98978 4.46825C10.0016 4.59851 9.96367 4.72836 9.88427 4.82975V4.82975Z"
        fill="#0E9F6E"
      />
    </svg>
  );
}
