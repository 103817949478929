import { JSX, Show } from 'solid-js';
import classes from './input.module.css';
import { Typography } from '../../Typography';
import { ErrorIcon } from '../../icons/ErrorIcon';

export type AllowedInputProps = Omit<
  JSX.InputHTMLAttributes<HTMLInputElement>,
  'class' | 'classList'
>;
export interface InputProps extends AllowedInputProps {
  id?: string;
  required?: boolean;
  errorText?: string;
  hintText?: string;
}

export function Input(props: InputProps) {
  return (
    <div class={classes.input}>
      <input
        {...props}
        classList={{
          [classes.disabled]: props.disabled,
          [classes.error]: Boolean(props.errorText),
        }}
      />
      <Show when={props.errorText}>
        <div class={classes['error-txt']}>
          <ErrorIcon />
          <Typography>{props.errorText}</Typography>
        </div>
      </Show>
      <Show when={props.hintText}>
        <div class={classes['hint-txt']}>
          <Typography>{props.hintText}</Typography>
        </div>
      </Show>
    </div>
  );
}
