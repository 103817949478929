import { Component, Show } from 'solid-js';
import classes from '../model-page.module.css';
import { Card } from '../../../components/Card';
import { Typography } from '../../../components/Typography';
import { LabelGraph } from '../../Graphs/LabelGraph';
import { AUCGraph } from '../../Graphs/AUCGraph';
import { useMetrics, MetricOptions } from '../useMetrics';
import { useModels } from '../../../pages/projects/Models/use-models';

type ModelKPIsProps = MetricOptions;

export const ModelKPIs: Component<ModelKPIsProps> = (props) => {
  const {
    labelGraphData,
    accuracy,
    balancedAccuracy,
    sensitivity,
    specificity,
    ppv,
    npv,
    auc,
    fpr,
    tpr,
  } = useMetrics(props);
  const { model } = useModels();
  return (
    <div class={classes['model-kpis']}>
      <Show when={model()}>
        {(model) => (
          <Typography>
            {model().prediction_column}: {model().positive_label}
          </Typography>
        )}
      </Show>
      <div class={classes.graphs}>
        <Card elevate>
          <div class={classes.graph}>
            <LabelGraph
              tp_amount={labelGraphData().tp}
              fp_amount={labelGraphData().fp}
              tn_amount={labelGraphData().tn}
              fn_amount={labelGraphData().fn}
              negative_prediction_label={labelGraphData().negativeLabel}
              positive_prediction_label={labelGraphData().positiveLabel}
            />
          </div>
        </Card>

        <Card elevate>
          <AUCGraph y={tpr()} x={fpr()} auc={auc()} />
        </Card>
      </div>

      <div class={classes.kpis}>
        <Typography>Sensitivity: {sensitivity()}</Typography>
        <Typography>PPV: {ppv()}</Typography>
        <Typography>Accuracy: {accuracy()}</Typography>
        <Typography>Specificity: {specificity()}</Typography>
        <Typography>NPV: {npv()}</Typography>
        <Typography>Balanced accuracy: {balancedAccuracy()}</Typography>
      </div>
    </div>
  );
};
