import { Component, JSX, Show } from 'solid-js';
import classes from './text-area.module.css';
import { Typography } from '../../../components/Typography';
import { ErrorIcon } from '../../icons/ErrorIcon';

export type AllowedTextareaProps = Omit<
  JSX.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'class' | 'classList'
>;

interface TextareaProps extends AllowedTextareaProps {
  errorText?: string;
  hintText?: string;
  resize?: boolean;
}

export const Textarea: Component<TextareaProps> = (props) => {
  const rows = () => props.rows ?? 3;
  return (
    <div class={classes.textarea}>
      <textarea
        {...props}
        rows={rows()}
        classList={{
          [classes.disabled]: props.disabled,
          [classes.error]: Boolean(props.errorText),
          [classes.resize]: props.resize,
        }}
      />
      <Show when={props.errorText}>
        <div class={classes['error-txt']}>
          <ErrorIcon />
          <Typography>{props.errorText}</Typography>
        </div>
      </Show>
      <Show when={props.hintText}>
        <div class={classes['hint-txt']}>
          <Typography>{props.hintText}</Typography>
        </div>
      </Show>
    </div>
  );
};
