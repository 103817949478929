import { Modal } from '../../../components/Modal';
import classes from './duplicate-modal.module.css';
import { Typography } from '../../../components/Typography';
import { Button } from '../../../components/Button';
import { XIcon } from '../../../components/icons/XIcon';
import { WarningIcon } from '../../../components/icons/WarningIcon';
import { Component } from 'solid-js';

interface DuplicateSlideModalProps {
  name: string;
  onClose: VoidFunction;
  open: boolean;
  onReplace: VoidFunction;
}

export const DuplicateSlideModal: Component<DuplicateSlideModalProps> = (
  props
) => {
  return (
    <Modal open={props.open} onClose={props.onClose} disableOutsideClick>
      <div class={classes['duplicate-modal']}>
        <div class={classes['close-icon']} onClick={props.onClose}>
          <XIcon />
        </div>
        <WarningIcon />
        <Typography component="h2" weight="bold">
          Oops!
        </Typography>
        <Typography>
          The slide with filename {''}
          <Typography weight="semi-bold">'{props.name}'</Typography> already
          exists.
        </Typography>
        <Typography>Do you want to replace the existing file?</Typography>
        <div class={classes['modal-buttons']}>
          <Button variant="secondary" onClick={props.onClose}>
            Ignore
          </Button>
          <Button onClick={props.onReplace}>Replace file</Button>
        </div>
      </div>
    </Modal>
  );
};
