import { Component, Show } from 'solid-js';
import classes from './label-graph.module.css';
import { Typography } from '../../../components/Typography';

interface LabelGraphProps {
  tp_amount?: number;
  fp_amount?: number;
  tn_amount?: number;
  fn_amount?: number;
  positive_prediction_label?: string;
  negative_prediction_label?: string;
}

export const LabelGraph: Component<LabelGraphProps> = (props) => {
  return (
    <div class={classes['label-graph']}>
      <div class={classes['graph-header']}>
        <Typography component="h3">Prediction label</Typography>
      </div>
      <div class={classes['graph-body']}>
        <Typography component="h3">Actual label</Typography>
        <div class={classes.cells}>
          <LabelGraphCell
            label="True Positive"
            value={props.tp_amount}
            actualLabel="Positive"
            predictedLabel={props.positive_prediction_label}
          />
          <LabelGraphCell
            label="False Negative"
            value={props.fn_amount}
            predictedLabel={props.negative_prediction_label}
          />
          <LabelGraphCell
            label="False Positive"
            value={props.fp_amount}
            actualLabel="Negative"
          />
          <LabelGraphCell label="True Negative" value={props.tn_amount} />
        </div>
      </div>
    </div>
  );
};

interface LabelGraphCellProps {
  value?: number;
  label: string;
  actualLabel?: string;
  predictedLabel?: string;
}

const LabelGraphCell: Component<LabelGraphCellProps> = (props) => {
  return (
    <Show
      when={props.actualLabel || props.predictedLabel}
      fallback={
        <div class={classes.cell}>
          <Typography component="h1" weight="semi-bold">
            {props.value}
          </Typography>
          <Typography>{props.label}</Typography>
        </div>
      }
    >
      <div class={classes['cell-with-label']}>
        <Show when={props.predictedLabel}>
          <Typography>{props.predictedLabel}</Typography>
        </Show>
        <Show when={props.actualLabel}>
          <Typography class={classes['side-label']}>
            {props.actualLabel}
          </Typography>
        </Show>
        <div class={classes.cell}>
          <Typography component="h1" weight="semi-bold">
            {props.value}
          </Typography>
          <Typography>{props.label}</Typography>
        </div>
      </div>
    </Show>
  );
};
