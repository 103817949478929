import { Component, For, Show } from 'solid-js';
import type { StudioSlide } from '@imagene/fm-studio-interfaces';
import { Typography } from '../../../components/Typography';
import { Card } from '../../../components/Card';
import { SlidesUploadTable } from '../../../business-components/SlidesUploadTable';
import { NoSlides } from '.';
import { Skeleton } from '../../../components/Skeleton';
import classes from './slides.module.css';

interface SlidesListProps {
  slides: StudioSlide[];
  addFiles: (files: File[]) => void;
  isTCGA: boolean;
  projectRelPath: string;
}

export function SlidesListCard(props: SlidesListProps) {
  const slides = () =>
    props.isTCGA ? props.slides.filter((s) => s.dataMatching) : props.slides;
  const title = () =>
    slides().length ? `Slides list: ${slides().length}` : 'Slides list';
  return (
    <Card>
      <div class={classes['slides-list']}>
        <Typography component="h3" weight="bold">
          {title()}
        </Typography>
        <SlidesUploadTable slides={slides()} />
        <Show when={!slides().length}>
          <NoSlides
            addFiles={props.addFiles}
            isTCGA={props.isTCGA}
            projectRelPath={props.projectRelPath}
          />
        </Show>
      </div>
    </Card>
  );
}

export const SlideListCardSkeleton: Component = () => (
  <Card>
    <div class={classes.skeleton}>
      <Skeleton width={156} height={25} />
      <Skeleton height={30} />
      <div class={classes.skeleton_table}>
        <For each={Array(66).fill(0)}>{() => <Skeleton width={240} />}</For>
      </div>
    </div>
  </Card>
);
