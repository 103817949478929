import { Component, For, ParentComponent, Show, Suspense } from 'solid-js';
import type { PredictionTarget } from '@imagene/fm-studio-interfaces';
import { ColumnCard, ColumnCardProps, ColumnCardSkeleton } from '../ColumnCard';
import { Card } from '../../../components/Card';
import { Typography } from '../../../components/Typography';
import { useToast } from '../../../components/Toast';
import { Skeleton } from '../../../components/Skeleton';

import classes from './select-prediction-target.module.css';

interface SelectPredictionTargetProps {
  columns: PredictionTarget[];
  target?: string;
  onLabelSelect: ColumnCardProps['onLabelSelect'];
}

export const SelectPredictionTarget: ParentComponent<
  SelectPredictionTargetProps
> = (props) => {
  const toast = useToast();
  const isNumeric = (target: PredictionTarget) =>
    target.categories.some((c) => !Number.isNaN(parseInt(c.name)));

  const isDisabled = (target: PredictionTarget) =>
    isNumeric(target) || target.categories.length < 2;

  const onColumnSelect = (column: PredictionTarget) => {
    const numeric = isNumeric(column);
    const disabled = isDisabled(column);
    if (numeric) {
      toast.api.create({
        title:
          'Demo version of OISuite provides an algorithm for Binary Classification. Please click on a radio button to select a text value/category as your Prediction Target.',
      });
    } else if (disabled) {
      toast.api.create({
        title: 'Please select a target with at least two labels',
      });
    }
  };

  return (
    <Card>
      <div class={classes['select-prediction-target']}>
        <div class={classes.title}>
          <Typography component="h3" weight="bold">
            Select prediction target
          </Typography>
          <Show when={props.target}>
            <Typography color="secondary">
              <Typography weight="semi-bold">{props.target}</Typography>
            </Typography>
          </Show>
        </div>

        <div class={classes.columns}>
          <Suspense fallback={<ColumnCardsSkeleton />}>
            <For each={props.columns}>
              {(column) => (
                <div class={classes['column']}>
                  <ColumnCard
                    {...column}
                    target={props.target}
                    onLabelSelect={props.onLabelSelect}
                    onColumnSelect={() => onColumnSelect(column)}
                    disabled={isDisabled(column)}
                  />
                </div>
              )}
            </For>
          </Suspense>
        </div>
        {props.children}
      </div>
    </Card>
  );
};

const ColumnCardsSkeleton: Component = () => (
  <div class={classes.skeleton}>
    <For each={Array(8)}>{() => <ColumnCardSkeleton />}</For>
  </div>
);
