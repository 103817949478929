import { Component, Show } from 'solid-js';
import { Card } from '../../components/Card';
import classes from './trial-limit.module.css';
import { Typography } from '../../components/Typography';
import { Progress } from '../../components/Progress';
import { Button } from '../../components/Button';
import { useTrialQuota } from '../../utils/use-trial-quota';
import { useSelectedProject } from '../../utils/use-selected-project';

type TrialLimitMode = 'projects' | 'models' | 'embeddings';

interface TrialLimitProps {
  mode?: TrialLimitMode;
  mini?: boolean;
}

export const TrialLimit: Component<TrialLimitProps> = (props) => {
  const {
    tcgaEmbeddingsAmount,
    tcgaEmbeddingsLimit,
    tcgaModelsAmount,
    tcgaModelsLimit,
    tcgaProjectsAmount,
    tcgaProjectsLimit,
    projectsAmount,
    projectsLimit,
    modelsAmount,
    modelsLimit,
    embeddingsAmount,
    embeddingsLimit,
    mailToString,
  } = useTrialQuota();
  const project = useSelectedProject();

  const isTCGA = () => project.selectedProject()?.data_source === 'TCGA';
  const mode = () =>
    props.mode ?? (project.selectedProject() ? 'models' : 'projects');

  const projects = () => ({ total: projectsLimit(), value: projectsAmount() });
  const tcgaProjects = () => ({
    total: tcgaProjectsLimit(),
    value: tcgaProjectsAmount(),
  });

  const models = () =>
    isTCGA()
      ? { total: tcgaModelsLimit(), value: tcgaModelsAmount() }
      : { total: modelsLimit(), value: modelsAmount() };

  const embeddings = () =>
    isTCGA()
      ? {
          total: tcgaEmbeddingsLimit(),
          value: tcgaEmbeddingsAmount(),
        }
      : {
          total: embeddingsLimit(),
          value: embeddingsAmount(),
        };

  const showProjectsLimitMsg = () =>
    projectsLimit() === projectsAmount() &&
    tcgaProjectsLimit() === tcgaProjectsAmount();

  const showEmbeddingsLimitMsg = () =>
    embeddings().total === embeddings().value;

  const showModelsLimitMsg = () => models().total === models().value;

  const totalProjects = () => tcgaProjectsLimit() + projectsLimit();

  return (
    <Card noElevation>
      <div
        class={classes['trial-limit']}
        classList={{ [classes.mini]: props.mini }}
      >
        <Typography weight="bold">Discovery package usage</Typography>
        <Show
          when={props.mini}
          fallback={
            <>
              <Show when={mode() === 'projects'}>
                <LimitRow {...tcgaProjects()} label="TCGA project" />
                <LimitRow {...projects()} label="Internal project" />
                <Show when={showProjectsLimitMsg()}>
                  <Typography>
                    You have used all {totalProjects()} free projects.
                  </Typography>
                </Show>
              </Show>
              <Show when={mode() === 'models'}>
                <LimitRow {...models()} label="Models" />
                <LimitRow
                  {...embeddings()}
                  label="Embeddings"
                  showStudy={isTCGA()}
                />
                <Show
                  when={showEmbeddingsLimitMsg() && showModelsLimitMsg()}
                  fallback={
                    <Show when={showEmbeddingsLimitMsg()}>
                      You have used all {embeddings().total} free embeddings.
                    </Show>
                  }
                >
                  You have used all {models().total} free models and{' '}
                  {embeddings().total} free embeddings
                </Show>
              </Show>

              <a target="_blank" href={mailToString()}>
                <Button variant="action" fullWidth>
                  Upgrade plan
                </Button>
              </a>
            </>
          }
        >
          <Show when={mode() === 'models'}>
            <LimitRow {...models()} label="Models" />
          </Show>
          <Show when={mode() === 'embeddings'}>
            <LimitRow {...embeddings()} label="Embeddings" />
          </Show>
        </Show>
      </div>
    </Card>
  );
};

interface LimitRowProps {
  label?: string;
  total?: number;
  value?: number;
  showStudy?: boolean;
}

const LimitRow: Component<LimitRowProps> = (props) => {
  return (
    <div class={classes['limit-row']}>
      <div class={classes['row-text']}>
        <Typography weight="thin">{props.label}</Typography>
        <Typography>
          {props.value}/{props.total}
        </Typography>
      </div>
      <Progress
        max={props.total}
        value={Math.min(props.total ?? 0, props.value ?? 0)}
      />
      <Show when={props.showStudy}>
        <Typography color="secondary">(LUAD Study)</Typography>
      </Show>
    </div>
  );
};
