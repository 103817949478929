import { Outlet, useMatch } from '@solidjs/router';
import { Sidebar } from '../../../business-components/Sidebar';
import classes from './main-layout.module.css';
import { Topbar } from '../Topbar';
import { Component, createSignal } from 'solid-js';
import {
  SelectedProjectProvider,
  useSelectedProject,
} from '../../../utils/use-selected-project';
import { useBreadcrumbs } from '../../../utils/use-breadcrumbs';
import {
  UploadQueueProvider,
  useUploadQueue,
} from '../../../utils/upload-queue-manager';

export const MainLayout: Component = () => {
  return (
    <SelectedProjectProvider>
      <UploadQueueProvider>
        <MainLayoutOutlet />
      </UploadQueueProvider>
    </SelectedProjectProvider>
  );
};

const MainLayoutOutlet: Component = () => {
  const [isCollapsed, setCollapsed] = createSignal(false);
  const { breadcrumbs } = useBreadcrumbs();
  const project = useSelectedProject();
  const { isUploading, completed, total } = useUploadQueue();

  const isSlidesPage = useMatch(
    () => `projects/${project.selectedProject()?.slug}/slides`
  );

  const showUploadBar = () => Boolean(isUploading()) && !isSlidesPage();

  return (
    <div
      class={classes.layout}
      classList={{ [classes.collapsed]: isCollapsed() }}
    >
      <Sidebar
        onToggle={() => {
          setCollapsed((prev) => !prev);
        }}
        collapsed={isCollapsed()}
        project={project}
      />
      <main class={classes.main}>
        <Topbar
          breadcrumbs={breadcrumbs()}
          showUploadBar={showUploadBar()}
          total={total()}
          completed={completed()}
        />
        <div class={classes.content}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};
