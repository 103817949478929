import { XIcon } from '../../../components/icons/XIcon';
import { FileInput } from '../../../components/Inputs/FileInput';
import { Modal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';
import classes from './labels.module.css';

interface UploadLabelsModalProps {
  open: boolean;
  disabled: boolean;
  addFile: (files: File[]) => void;
  onClose: VoidFunction;
}

export function UploadLabelsModal(props: UploadLabelsModalProps) {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div class={classes['upload-labels-modal']}>
        <div class={classes['close-icon']} onClick={props.onClose}>
          <XIcon />
        </div>
        <Typography weight="bold">
          Please upload the CSV file containing the table with all relevant
          information from your dataset.
        </Typography>
        <div class={classes['upload-labels-body']}>
          <div class={classes['upload-labels-text']}>
            <Typography component="p">
              *Please make sure that the table contains a column <br />
              with{' '}
              <Typography class={classes.highlight} weight="bold">
                filenames of Whole Slide Images.
              </Typography>
              <br />
              System will use this column to match slides' files to the uploaded
              data
            </Typography>
            <div class={classes['labels-sample-img']} />
          </div>
          <FileInput
            criteria={'Only .csv format'}
            accept=".csv"
            onFileSelected={props.addFile}
            buttonText="Browse file"
            hintText="Drag and drop file here or"
            disabled={props.disabled}
          />
        </div>
      </div>
    </Modal>
  );
}
