export function FileIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_718_38215)">
        <path
          d="M8.34375 10.875C8.11997 10.875 7.90536 10.9639 7.74713 11.1221C7.58889 11.2804 7.5 11.495 7.5 11.7188C7.5 11.9425 7.58889 12.1571 7.74713 12.3154C7.90536 12.4736 8.11997 12.5625 8.34375 12.5625H13.4062C13.63 12.5625 13.8446 12.4736 14.0029 12.3154C14.1611 12.1571 14.25 11.9425 14.25 11.7188C14.25 11.495 14.1611 11.2804 14.0029 11.1221C13.8446 10.9639 13.63 10.875 13.4062 10.875H8.34375ZM8.625 8.34375C8.625 8.11997 8.71389 7.90536 8.87213 7.74713C9.03036 7.58889 9.24497 7.5 9.46875 7.5H15.6562C15.88 7.5 16.0946 7.58889 16.2529 7.74713C16.4111 7.90536 16.5 8.11997 16.5 8.34375C16.5 8.56753 16.4111 8.78214 16.2529 8.94037C16.0946 9.09861 15.88 9.1875 15.6562 9.1875H9.46875C9.24497 9.1875 9.03036 9.09861 8.87213 8.94037C8.71389 8.78214 8.625 8.56753 8.625 8.34375ZM10.5938 14.25C10.37 14.25 10.1554 14.3389 9.99713 14.4971C9.83889 14.6554 9.75 14.87 9.75 15.0938C9.75 15.3175 9.83889 15.5321 9.99713 15.6904C10.1554 15.8486 10.37 15.9375 10.5938 15.9375H15.6562C15.88 15.9375 16.0946 15.8486 16.2529 15.6904C16.4111 15.5321 16.5 15.3175 16.5 15.0938C16.5 14.87 16.4111 14.6554 16.2529 14.4971C16.0946 14.3389 15.88 14.25 15.6562 14.25H10.5938Z"
          fill="currentColor"
        />
        <path
          d="M3 4.96875C3 3.882 3.882 3 4.96875 3H19.0312C20.118 3 21 3.882 21 4.96875V19.0312C21 19.5534 20.7926 20.0542 20.4234 20.4234C20.0542 20.7926 19.5534 21 19.0312 21H4.96875C4.44661 21 3.94585 20.7926 3.57663 20.4234C3.20742 20.0542 3 19.5534 3 19.0312V4.96875ZM4.96875 4.6875C4.89416 4.6875 4.82262 4.71713 4.76988 4.76988C4.71713 4.82262 4.6875 4.89416 4.6875 4.96875V19.0312C4.6875 19.1865 4.8135 19.3125 4.96875 19.3125H19.0312C19.1058 19.3125 19.1774 19.2829 19.2301 19.2301C19.2829 19.1774 19.3125 19.1058 19.3125 19.0312V4.96875C19.3125 4.89416 19.2829 4.82262 19.2301 4.76988C19.1774 4.71713 19.1058 4.6875 19.0312 4.6875H4.96875Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_718_38215">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
