import { Typography } from '../../../components/Typography';
import { Button } from '../../../components/Button';
import { XIcon } from '../../../components/icons/XIcon';
import { WarningIcon } from '../../../components/icons/WarningIcon';
import { Modal } from '../../../components/Modal';
import classes from './duplicate-modal.module.css';
import { For } from 'solid-js';
import type { ValidateFileResponse } from '@imagene/fm-studio-interfaces';

interface DuplicateLabelModalProps {
  duplicates: ValidateFileResponse['duplicates'];
  onClose: VoidFunction;
  open: boolean;
  onConfirm: VoidFunction;
}

export function DuplicateLabelModal(props: DuplicateLabelModalProps) {
  return (
    <Modal open={props.open} disableOutsideClick>
      <div class={classes['duplicate-modal']}>
        <div class={classes['close-icon']} onClick={props.onClose}>
          <XIcon />
        </div>
        <WarningIcon />
        <Typography component="h2" weight="bold">
          Oops!
        </Typography>
        <For each={props.duplicates}>
          {(duplicate) => (
            <Typography>
              The selected file includes duplicate{' '}
              {duplicate.rows.length > 1 ? 'rows' : 'row'}{' '}
              {duplicate.rows.join(' and ')} with the filename{' '}
              <Typography weight="semi-bold">'{duplicate.value}'</Typography>.
            </Typography>
          )}
        </For>
        <Typography>
          Do you want to proceed{' '}
          <Typography weight="semi-bold">without</Typography> duplicate rows?
        </Typography>
        <div class={classes['modal-buttons']}>
          <Button variant="secondary" onClick={props.onClose}>
            Cancel upload
          </Button>
          <Button onClick={props.onConfirm}>
            Yes, proceed without duplicates
          </Button>
        </div>
      </div>
    </Modal>
  );
}
