import { createQuery } from '@tanstack/solid-query';
import { useModels } from '../../pages/projects/Models/use-models';
import { useAxios } from '../../utils/axios';
import { useSelectedProject } from '../../utils/use-selected-project';

export interface Result {
  fileName: string;
  group: 'validation' | 'test';
  prediction_target: string;
  prediction_result: string;
  result: string;
  confidence_score: number;
}

export function useResults() {
  const axios = useAxios();
  const project = useSelectedProject();
  const { model } = useModels();
  const projectId = () => project.selectedProject()?.id;
  const modelId = () => model()?.id;
  const basePath = () => `/projects/${projectId()}/models/${modelId()}/results`;

  const resultsQuery = createQuery(
    () => [projectId(), 'models', modelId(), 'results'],
    () => axios.get(basePath()).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      get enabled() {
        return Boolean(modelId());
      },
    }
  );

  const exportURL = () => `${axios.defaults.baseURL}${basePath()}/export`;

  const results = (): Result[] => resultsQuery?.data ?? [];

  const prediction_target = () => model()?.prediction_column ?? '';

  const isResultsEmpty = () => !results().length;

  return {
    results,
    prediction_target,
    isResultsEmpty,
    exportURL,
  };
}
