import { Component, Show, For, createSignal, createMemo } from 'solid-js';
import { createVirtualizer } from '@tanstack/solid-virtual';
import {
  CohortColumnFilter,
  CohortValueFilter,
} from '@imagene/fm-studio-interfaces';
import { DefineCohortColumn } from '../DefineCohort';
import { Typography } from '../../../components/Typography';
import { Tooltip } from '../../../components/Tooltip';
import { Checkbox } from '../../../components/Checkbox';
import { SearchInput } from '../../../components/Inputs/SearchInput';

import classes from './tcga-column-card.module.css';

export interface TCGAColumnCardProps extends DefineCohortColumn {
  selected?: boolean;
  disabled?: boolean;
  hideProgress?: boolean;
  totalValues?: number;
  searchable?: boolean;
  onLabelSelect: (label: string, checked: boolean, column: string) => void;
  onSelectAll?: (values: string[], column: string, checked: boolean) => void;
  style?: Record<string, string | number | undefined>;
  labels?: CohortValueFilter[];
  columns?: CohortColumnFilter[];
  showSelectAll?: boolean;
  mode: 'columns' | 'cohort';
}
export const TCGAColumnCard: Component<TCGAColumnCardProps> = (props) => {
  let scrollTarget!: HTMLDivElement;
  const [searchGene, setSearchGene] = createSignal<string>();

  const isCategoryChecked = (name: string) => {
    const column = props.columns?.find((c) => c.column === props.title);
    const label = props.labels?.find(
      ({ value, column }) =>
        column === props.title && value?.toLowerCase() === name?.toLowerCase()
    );

    return Boolean(column?.values?.includes(name) || label);
  };

  const isAllSelected = () => {
    const column = props.columns?.find((c) => c.column === props.title);
    return Boolean(column?.values.every((c) => isCategoryChecked(c)));
  };

  const isStudyColumn = () =>
    props.title?.toLowerCase() === 'tcga study' && props.mode === 'cohort';

  const sortedCategories = () => {
    if (isStudyColumn()) {
      const val = props.categories.find(
        (c) =>
          c.name?.toLowerCase() ===
          'lung adenocarcinoma (tcga, pancancer atlas)'
      );
      const filtered = props.categories.filter((c) => c.name !== val?.name);
      if (val) {
        return [val, ...filtered];
      }
    }
    return props.categories;
  };

  const categories = () => {
    const gene = searchGene();
    const sorted = sortedCategories();
    if (!gene) return sorted;

    return sorted.filter((cat) =>
      cat.name.toLowerCase().includes(gene.toLowerCase())
    );
  };

  const virtualizer = createMemo(() =>
    createVirtualizer({
      get count() {
        return categories().length;
      },
      getScrollElement: () => scrollTarget,
      estimateSize: () => 55,
      overscan: 10,
    })
  );

  return (
    <section
      class={classes['column-card']}
      style={props.style}
      ref={scrollTarget}
    >
      <div
        class={classes.title}
        classList={{ [classes.selected]: props.selected }}
      >
        <Tooltip text={props.title} placement="top">
          <Typography wrap="15ch" weight={props.selected ? 'bold' : 'default'}>
            {props.title}
          </Typography>
        </Tooltip>
        <Show when={props.searchable}>
          <SearchInput value={searchGene()} onSearchChange={setSearchGene} />
        </Show>
      </div>
      <div
        class={classes['column-inner']}
        classList={{
          [classes.selected]: props.selected,
        }}
      >
        <div class={classes.categories}>
          <Show when={props.showSelectAll}>
            <Checkbox
              label="Select All"
              onChange={(checked) =>
                props.onSelectAll?.(
                  props.categories.map((c) => c.name),
                  props.title,
                  checked
                )
              }
              checked={isAllSelected()}
            />
          </Show>
          <div
            class={classes['categories-inner']}
            style={{
              height: `calc(${virtualizer().getTotalSize()}px + 50px)`,
            }}
          >
            <For each={virtualizer().getVirtualItems()}>
              {(item, index) => {
                const row = categories()[item.index];
                return (
                  <CategoryRow
                    name={row?.name}
                    amount={row?.amount}
                    total={props.totalValues}
                    onCheck={(label, checked) =>
                      props.onLabelSelect(label, checked, props.title)
                    }
                    disabled={props.disabled || isStudyColumn()}
                    hideProgress={props.hideProgress}
                    checked={isCategoryChecked(row?.name)}
                    style={{
                      height: `${item.size}px`,
                      transform: `translateY(${
                        item.start - index() * item.size
                      }px)`,
                    }}
                  />
                );
              }}
            </For>
          </div>
        </div>
      </div>
    </section>
  );
};

interface CategoryRowProps {
  name: string;
  amount?: number;
  total?: number;
  disabled?: boolean;
  hideProgress?: boolean;
  onCheck: (label: string, checked: boolean) => void;
  style?: Record<string, string | number | undefined>;
  checked?: boolean;
}

const CategoryRow: Component<CategoryRowProps> = (props) => {
  return (
    <div
      class={classes['category-row']}
      classList={{ [classes.disabled]: props.disabled }}
      style={props.style}
      role="listitem"
    >
      <div class={classes.text}>
        <Checkbox
          checked={props.checked}
          label={props.name}
          disabled={props.disabled}
          onChange={(checked) => props.onCheck(props.name, checked)}
        />

        <Typography weight="thin">{props.amount}</Typography>
      </div>
      <Show when={!props.hideProgress && props.total}>
        <progress
          max={props.total}
          value={props.amount}
          class={classes.progress}
          classList={{ [classes.disabled]: props.disabled }}
        />
      </Show>
    </div>
  );
};
