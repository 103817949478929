import { createSignal } from 'solid-js';
import { ProjectDataSource } from '@imagene/fm-studio-interfaces';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { Typography } from '../../components/Typography';
import { Textarea } from '../../components/Inputs/Textarea';
import { SelectInput } from '../../components/Inputs/SelectInput';

import classes from './projects.module.css';
import { useTrialQuota } from '../../utils/use-trial-quota';
import { useToast } from '../../components/Toast';

interface CreateProjectDetails {
  project: string;
  dataSource: ProjectDataSource;
}

interface CreateProjectModalProps {
  open: boolean;
  onClose: VoidFunction;
  onCreate: (details: CreateProjectDetails) => void;
  disabled?: boolean;
  error?: string;
}

export function CreateProjectModal(props: CreateProjectModalProps) {
  const options = ['Internal cohort', 'TCGA'];
  const [project, setProject] = createSignal('');
  const [dataSource, setDataSource] = createSignal<string[]>([options[0]]);
  const { canCreateProject, canCreateTCGAProject } = useTrialQuota();
  const toast = useToast();

  const optionMap: Record<string, ProjectDataSource> = {
    'Internal cohort': 'INTERNAL' as ProjectDataSource,
    TCGA: 'TCGA' as ProjectDataSource,
  };

  const disableCreate = () => props.disabled || !project().length;

  const onCreate = () => {
    const selectedSource = optionMap[dataSource()[0]];
    let canCreate = false;
    if (canCreateProject() && selectedSource === 'INTERNAL') {
      canCreate = true;
    }
    if (canCreateTCGAProject() && selectedSource === 'TCGA') {
      canCreate = true;
    }

    if (canCreate) {
      props.onCreate({
        project: project(),
        dataSource: selectedSource,
      });
    } else {
      props.onClose();
      toast.api.create({
        title: `You have reached your ${dataSource()[0]} projects limit.`,
        type: 'info',
      });
    }
    setProject('');
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div class={classes['create-project-modal']}>
        <Typography>Please name your project</Typography>
        <Textarea
          onInput={(e) => setProject(e.currentTarget.value)}
          required
          minlength={1}
          maxlength={100}
          errorText={props.error}
        />
        <Typography>Select data source</Typography>
        <SelectInput
          options={options}
          value={dataSource()}
          onValueChange={(d) => setDataSource(d.value)}
        />
        <div class={classes['modal-buttons']}>
          <Button
            variant="secondary"
            onClick={props.onClose}
            disabled={props.disabled}
          >
            Cancel
          </Button>
          <Button disabled={disableCreate()} onClick={onCreate}>
            Create project
          </Button>
        </div>
      </div>
    </Modal>
  );
}
