import { Match, Show, Switch } from 'solid-js';
import classes from './slide-status.module.css';
import { Badge, BadgeType } from '../../components/Badge';
import { Typography } from '../../components/Typography';
import { GreenCheckmarkIcon } from '../../components/icons/GreenCheckmarkIcon';
import { DashIcon } from '../../components/icons/DashIcon';
import { Progress } from '../../components/Progress';

interface SlideStatusIndicatorProps {
  text?: string;
  type?: 'alert' | 'error' | 'done' | 'ongoing';
  details?: string;
  progressValue?: number;
}

export function SlideStatusIndicator(props: SlideStatusIndicatorProps) {
  return (
    <div class={classes['slide-status']}>
      <Switch fallback={<DashIcon />}>
        <Match when={props.type === 'ongoing'}>
          <Progress value={props.progressValue ?? null} />
        </Match>
        <Match when={props.type === 'alert'}>
          <Badge type={BadgeType.ALERT} text={props.text} />
          <Show when={props.details}>
            <Typography weight="semi-bold">{props.details}</Typography>
          </Show>
        </Match>

        <Match when={props.type === 'error'}>
          <Badge type={BadgeType.ERROR} text={props.text} />
          <Show when={props.details}>
            <Typography weight="semi-bold">{props.details}</Typography>
          </Show>
        </Match>

        <Match when={props.type === 'done'}>
          <GreenCheckmarkIcon />
        </Match>

        <Match when={!props.type && props.text}>
          <Badge text={props.text} />
        </Match>
      </Switch>
    </div>
  );
}
