import {
  createContext,
  createMemo,
  createUniqueId,
  ParentComponent,
  splitProps,
  useContext,
  Component,
  createEffect,
} from 'solid-js';
import * as radio from '@zag-js/radio-group';
import { normalizeProps, useMachine } from '@zag-js/solid';
import { Typography, TypographyProps } from '../Typography';
import './radio-item.module.css';

type RadioGroupContext = radio.Api;

interface RadioGroupProps {
  value?: string;
  onValueChange?: (value: string) => void;
}

const RadioGroupContext = createContext({} as RadioGroupContext);

const useRadioGroup = () => useContext(RadioGroupContext);

export const RadioGroup: ParentComponent<RadioGroupProps> = (props) => {
  const [context, local] = splitProps(props, ['value']);
  const [state, send] = useMachine(
    radio.machine({
      id: createUniqueId(),
      value: props.value,
      onValueChange: (details) => local.onValueChange?.(details.value),
    }),
    {
      context,
    }
  );

  createEffect(() => {
    if (context.value === undefined) {
      api().clearValue();
    }
  });

  const api = createMemo(() => radio.connect(state, send, normalizeProps));
  return (
    <RadioGroupContext.Provider value={api()}>
      {props.children}
    </RadioGroupContext.Provider>
  );
};

interface RadioItemProps {
  id: string;
  label: string;
  labelProps?: TypographyProps;
  disabled?: boolean;
}

export const RadioItem: Component<RadioItemProps> = (props) => {
  const api = useRadioGroup();

  return (
    <div {...api?.getRootProps()}>
      <label
        {...api?.getItemProps({ value: props.id, disabled: props.disabled })}
      >
        <div
          {...api?.getItemControlProps({
            value: props.id,
            disabled: props.disabled,
          })}
        />
        <Typography
          {...api?.getItemTextProps({
            value: props.id,
            disabled: props.disabled,
          })}
          {...props.labelProps}
        >
          {props.label}
        </Typography>
        <input
          {...api?.getItemHiddenInputProps({
            value: props.id,
            disabled: props.disabled,
          })}
        />
      </label>
    </div>
  );
};
