import { Component } from 'solid-js';
import {
  WizardChecklistProvider,
  WizardChecklistStep,
} from '../../../business-components/WizardChecklist/use-wizard-checklist';
import { TCGAWizard } from '../../../business-components/TCGACohortWizard';
import { TCGAWizardProvider } from '../../../business-components/TCGACohortWizard/use-tcga-wizard';

export enum TCGAWizardSteps {
  DEFINE_COHORT,
  DEFINE_COLUMNS,
}

export const TCGACohortWizard: Component = () => {
  const steps = (): WizardChecklistStep[] => [
    {
      title: 'Define Cohort',
      description:
        'Select the checkboxes to filter TCGA samples and define your cohort of interest.',
      collapse: true,
      value: null,
    },
    {
      title: 'Define Dataset Columns',
      description: `Select the checkboxes to choose the columns you want to include in your dataset. Note: The gene columns show the number of samples.`,
      collapse: true,
      value: null,
    },
  ];
  return (
    <WizardChecklistProvider steps={steps()}>
      <TCGAWizardProvider>
        <TCGAWizard />
      </TCGAWizardProvider>
    </WizardChecklistProvider>
  );
};
