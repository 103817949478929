import { Component, createEffect, createSignal, Show } from 'solid-js';
import { createElementSize } from '@solid-primitives/resize-observer';
import { Typography } from '../../components/Typography';
import { ArrowIcon } from '../../components/icons/ArrowIcon';
import Check from '../../components/icons/Check';
import { Badge, BadgeType } from '../../components/Badge';

import classes from './wizard-checklist.module.css';

export interface WizardChecklistStepProps {
  title: string;
  description: string;
  step: number;
  steps: number;
  collapsed: boolean;
  optional?: boolean;
  active: boolean;
  value: string | undefined | null;
  onCollapseChange?: (collapsed: boolean) => void;
}

export const WizardChecklistStep: Component<WizardChecklistStepProps> = (
  props
) => {
  const [target, setTarget] = createSignal<HTMLLIElement>();
  const size = createElementSize(target);

  createEffect(() => {
    target()?.style.setProperty('--indicator-line-height', `${size.height}px`);
  });

  return (
    <li
      ref={setTarget}
      class={classes.step}
      classList={{
        [classes.expanded]: !props.collapsed,
        [classes.active]: props.active,
        [classes.filled]: Boolean(props.value),
      }}
      onClick={() => props.onCollapseChange?.(!props.collapsed)}
    >
      <header>
        <div class={classes.indicator}>
          <div>
            <Show when={props.value}>
              <Check />
            </Show>
          </div>
          <Show when={props.step + 1 !== props.steps}>
            <hr />
          </Show>
        </div>
        <Typography wrap="20ch">
          {props.title}
          <Show when={props.optional}>
            <Typography class={classes.optional}> (optional)</Typography>
          </Show>
        </Typography>
        <ArrowIcon class={classes.arrow} />
      </header>
      <Show when={props.value}>
        {(val) => (
          <>
            <Typography wrap="25ch" class={classes.value} title={val()}>
              {val()}
            </Typography>
            <br />
          </>
        )}
      </Show>
      <Typography
        class={classes.description}
        title={props.description}
        component="div"
      >
        {props.description}
      </Typography>
      <Show when={props.active}>
        <div class={classes.badge}>
          <Badge
            text={`${props.step + 1} out of ${props.steps}`}
            big
            type={BadgeType.SUCCESS}
          />
        </div>
      </Show>
    </li>
  );
};
