import { Component, For, Show } from 'solid-js';
import { Typography } from '../../../components/Typography';
import { Tooltip } from '../../../components/Tooltip';
import { RadioGroup, RadioItem } from '../../../components/RadioGroup';
import { Skeleton } from '../../../components/Skeleton';

import classes from './column-card.module.css';

export interface ColumnCardProps {
  title: string;
  categories: {
    name: string;
    amount: number;
  }[];
  target: string | undefined;
  missingValues: number;
  totalValues: number;
  selected?: boolean;
  disabled?: boolean;
  onColumnSelect?: VoidFunction;
  onLabelSelect: (details: { column: string; label: string }) => void;
}

export const ColumnCard: Component<ColumnCardProps> = (props) => {
  const sortedCategories = () =>
    props.categories.sort((a, b) => a.amount - b.amount);

  const selectedValue = () => {
    if (!props.target) return undefined;
    const [title, val] = props.target.split(':');
    if (title !== props.title) return undefined;
    return props.categories.find((c) => c.name === val)?.name;
  };

  return (
    <section class={classes['column-container']}>
      <div
        class={classes['column-card']}
        classList={{
          [classes.selected]: props.selected,
          [classes.disabled]: props.disabled,
        }}
        onClick={props.onColumnSelect}
      >
        <div
          class={classes.title}
          classList={{ [classes.selected]: props.selected }}
        >
          <Tooltip text={props.title} placement="top">
            <Typography
              wrap="15ch"
              weight={props.selected ? 'bold' : 'default'}
            >
              {props.title}
            </Typography>
          </Tooltip>
        </div>

        <div class={classes['column-card-body']}>
          <div class={classes['missing-values']}>
            <CategoryRow
              name="Missing values"
              amount={props.missingValues}
              total={props.totalValues}
              disabled={props.disabled}
            />
          </div>

          <div class={classes.categories}>
            <RadioGroup
              value={selectedValue()}
              onValueChange={(label) =>
                props.onLabelSelect({ label, column: props.title })
              }
            >
              <Typography>
                # of categories: {props.categories.length}
              </Typography>
              <For each={sortedCategories()}>
                {(category) => (
                  <CategoryRow
                    name={category.name}
                    amount={category.amount}
                    total={props.totalValues}
                    disabled={props.disabled}
                    showRadio
                  />
                )}
              </For>
            </RadioGroup>
          </div>
        </div>
      </div>
    </section>
  );
};

interface CategoryRowProps {
  name: string;
  amount: number;
  total: number;
  disabled?: boolean;
  showRadio?: boolean;
}

const CategoryRow: Component<CategoryRowProps> = (props) => {
  return (
    <div
      class={classes['category-row']}
      classList={{ [classes.disabled]: props.disabled }}
      title={props.name}
    >
      <div class={classes.text}>
        <Show
          when={props.showRadio && !props.disabled}
          fallback={
            <Typography weight="thin" wrap="15ch" title={props.name}>
              {props.name}
            </Typography>
          }
        >
          <RadioItem
            id={props.name}
            label={props.name}
            labelProps={{ weight: 'thin', wrap: '15ch' }}
            disabled={props.disabled}
          />
        </Show>

        <Typography weight="thin">{props.amount}</Typography>
      </div>
      <progress
        max={props.total}
        value={props.amount}
        class={classes.progress}
        classList={{ [classes.disabled]: props.disabled }}
      />
    </div>
  );
};

export const ColumnCardSkeleton: Component = () => (
  <div class={classes.skeleton}>
    <Skeleton />
    <div class={classes.skeleton_missing_values}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
    <div class={classes.skeleton_values}>
      <Skeleton width={100} height={16} />
      <For each={Array(5)}>
        {() => (
          <div class={classes.skeleton_value}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
      </For>
    </div>
  </div>
);
