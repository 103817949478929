import {
  Accessor,
  Component,
  createEffect,
  createUniqueId,
  mergeProps,
} from 'solid-js';
import Plotly from 'plotly.js-dist-min';
import classes from './auc-graph-mini.module.css';
import { useAUC } from '../use-auc-colors';

interface AUCGraphProps {
  x?: number[];
  y?: number[];
  auc: number | undefined;
}

export const AUCGraphMini: Component<AUCGraphProps> = (props) => {
  const id = `auc_graph_mini_${createUniqueId()}`;
  const { auc, fillColor, lineColor } = useAUC(
    mergeProps(props, { precision: 2, round: true })
  );

  const roc: Accessor<Plotly.Data> = () => ({
    y: props.y,
    x: props.x,
    type: 'scatter',
    fill: 'tozeroy',
    fillcolor: fillColor(),
    opacity: 0.1,
    mode: 'lines',
    line: { color: lineColor() }, // shape: 'spline' (smooth line)
    hoverinfo: 'none',
  });

  const layout: Accessor<Partial<Plotly.Layout>> = () => ({
    xaxis: {
      showgrid: false,
      showticklabels: false,
      fixedrange: true,
      showline: false,
    },
    yaxis: {
      showticklabels: false,
      showgrid: false,
      fixedrange: true,
      showline: false,
    },
    showlegend: false,
    dragmode: false,
    clickmode: 'none',
    width: 177,
    height: 168,
    margin: {
      pad: 0,
      b: 0,
      t: 0,
      l: 0,
      r: 0,
    },
  });

  const options: Accessor<Partial<Plotly.Config>> = () => ({
    displayModeBar: false,
    displaylogo: false,
    doubleClick: false,
    showAxisDragHandles: false,
    showAxisRangeEntryBoxes: false,
  });

  createEffect(() => Plotly.react(id, [roc()], layout(), options()));

  return (
    <div class={classes.auc}>
      <h1 style={{ color: lineColor() }}>{auc()}</h1>
      <div id={id} />
    </div>
  );
};
