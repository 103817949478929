import { onMount, onCleanup, JSXElement, Show } from 'solid-js';
import { Button } from '../../Button';
import { Typography } from '../../Typography';
import classes from './file-input.module.css';

interface FileInputProps {
  id?: string;
  onFileSelected: (files: File[]) => void;
  criteria?: JSXElement | string;
  accept?: string;
  multiple?: boolean;
  hintText?: string;
  buttonText?: string;
  disabled?: boolean;
}

export function FileInput(props: FileInputProps) {
  let input: HTMLInputElement | null = null;
  const ACCEPTED_FORMATS = props.accept ?? '.svs,.ndpi,.tiff,.tif,.isyntax';
  const ALLOWED_FILE_EXTENSIONS = ACCEPTED_FORMATS.split(',').map((s) =>
    s.trim()
  );
  const UNSUPPORTED_FORMAT = `unsupported file format (${ACCEPTED_FORMATS})`;

  const hintText = () => props.hintText ?? 'Drag and drop slides here or';
  const buttonText = () => props.buttonText ?? 'Browse slides';

  const defaultCriteria = () => (
    <>
      Supported slides files: <br />
      File format: *.svs, *.ndpi, *.tiff, *.isyntax
      <br />
      Magnification: x20 or x40
      <br />
      Staining: H&E
    </>
  );

  function isFileExtensionValid(files: FileList) {
    return Array.from(files).every((file) =>
      ALLOWED_FILE_EXTENSIONS.some((ext) => file.name.endsWith(ext))
    );
  }

  function onFileChange(files: FileList | null | undefined) {
    if (!files) return;
    if (!isFileExtensionValid(files)) {
      console.error(UNSUPPORTED_FORMAT);
      return;
    }

    props.onFileSelected(Array.from(files));
  }

  function onClick(e: MouseEvent) {
    e.preventDefault();
    input?.click();
  }

  function onChange(e: Event) {
    const files = (e.target as HTMLInputElement).files;
    onFileChange(files);
  }

  function allowDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
  }

  function onDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    onFileChange(e.dataTransfer?.files);
  }

  onMount(() => {
    input?.addEventListener('change', onChange);
  });

  onCleanup(() => {
    input?.removeEventListener('change', onChange);
  });

  return (
    <div onDragOver={allowDrop} onDrop={onDrop} class={classes['file-input']}>
      <input
        id={props.id}
        type="file"
        accept={ACCEPTED_FORMATS}
        multiple={props.multiple}
        ref={(r) => (input = r)}
        onClick={() => {
          if (input) {
            input.value = '';
          }
        }}
      />
      <Typography weight="semi-bold">{hintText()}</Typography>
      <Button disabled={props.disabled} onClick={onClick}>
        {buttonText()}
      </Button>
      <div class={classes.criteria}>
        <Typography component="p" color="secondary">
          <Show when={props.criteria} fallback={defaultCriteria()}>
            {props.criteria}
          </Show>
        </Typography>
      </div>
    </div>
  );
}
