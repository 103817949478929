import { JSX, ParentProps } from 'solid-js';
import classes from './button.module.css';

type ButtonSize = 'small' | 'medium';

type ButtonProps = ParentProps<
  Omit<JSX.ButtonHTMLAttributes<HTMLButtonElement>, 'class' | 'classList'>
> & {
  loading?: boolean;
  size?: ButtonSize;
  fullWidth?: boolean;
  variant?: 'default' | 'secondary' | 'dark' | 'action';
};

export function Button(props: ButtonProps) {
  const size = props.size ?? 'medium';
  return (
    <button
      {...props}
      class={classes.btn}
      classList={{
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
        [classes.secondary]: props.variant === 'secondary',
        [classes['full-width']]: props.fullWidth,
        [classes.dark]: props.variant === 'dark',
        [classes.action]: props.variant === 'action',
      }}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
