import { JSX, Match, ParentProps, Switch } from 'solid-js';

import classes from './typography.module.css';

type TypographyExtendProps = {
  color?: 'primary' | 'secondary' | 'error' | 'default' | 'disabled';
  weight?: 'bold' | 'semi-bold' | 'default' | 'thin';
  wrap?: string;
};

export type TypographyProps = (
  | (ParentProps<JSX.HTMLAttributes<HTMLSpanElement>> & { component?: 'span' })
  | (ParentProps<JSX.HTMLAttributes<HTMLDivElement>> & { component: 'div' })
  | (ParentProps<JSX.HTMLAttributes<HTMLHeadingElement>> & {
      component: 'h1' | 'h2' | 'h3' | 'h4';
    })
  | (ParentProps<JSX.HTMLAttributes<HTMLParagraphElement>> & { component: 'p' })
  | (ParentProps<JSX.HTMLAttributes<HTMLTableCellElement>> & {
      component: 'th';
    })
  | (ParentProps<JSX.HTMLAttributes<HTMLTableCellElement>> & {
      component: 'td';
    })
  | (ParentProps<JSX.HTMLAttributes<HTMLPreElement>> & { component: 'pre' })
) &
  TypographyExtendProps;

export function Typography(props: TypographyProps) {
  const css = () => ({
    [classes.error]: props.color === 'error',
    [classes.primary]: props.color === 'primary',
    [classes.disabled]: props.color === 'disabled',
    [classes.secondary]: props.color === 'secondary',
    [classes.default]: props.color === 'default',
    [classes.bold]: props.weight === 'bold',
    [classes['semi-bold']]: props.weight === 'semi-bold',
    [classes.thin]: props.weight === 'thin',
    [props.class ?? '']: !!props.class,
    [classes.wrap]: Boolean(props.wrap),
    ...props.classList,
  });

  const style: JSX.CSSProperties = props.wrap
    ? {
        'max-width': props.wrap,
      }
    : {};

  return (
    <Switch
      fallback={
        <span
          {...(props as ParentProps<JSX.HTMLAttributes<HTMLSpanElement>>)}
          class={classes.text}
          classList={css()}
          style={style}
        >
          {props.children}
        </span>
      }
    >
      <Match when={props.component === 'div'}>
        <div
          {...(props as ParentProps<JSX.HTMLAttributes<HTMLDivElement>>)}
          class={classes.text}
          classList={css()}
          style={style}
        >
          {props.children}
        </div>
      </Match>
      <Match when={props.component === 'p'}>
        <p
          {...(props as ParentProps<JSX.HTMLAttributes<HTMLParagraphElement>>)}
          class={classes.text}
          classList={css()}
          style={style}
        >
          {props.children}
        </p>
      </Match>
      <Match when={props.component === 'h1'}>
        <h1
          {...(props as ParentProps<JSX.HTMLAttributes<HTMLHeadingElement>>)}
          class={classes.text}
          classList={css()}
          style={style}
        >
          {props.children}
        </h1>
      </Match>
      <Match when={props.component === 'h2'}>
        <h2
          {...(props as ParentProps<JSX.HTMLAttributes<HTMLHeadingElement>>)}
          class={classes.text}
          classList={css()}
          style={style}
        >
          {props.children}
        </h2>
      </Match>
      <Match when={props.component === 'h3'}>
        <h3
          {...(props as ParentProps<JSX.HTMLAttributes<HTMLHeadingElement>>)}
          class={classes.text}
          classList={css()}
          style={style}
        >
          {props.children}
        </h3>
      </Match>
      <Match when={props.component === 'h4'}>
        <h4
          {...(props as ParentProps<JSX.HTMLAttributes<HTMLHeadingElement>>)}
          class={classes.text}
          classList={css()}
          style={style}
        >
          {props.children}
        </h4>
      </Match>
      <Match when={props.component === 'th'}>
        <th
          {...(props as ParentProps<JSX.HTMLAttributes<HTMLTableCellElement>>)}
          class={classes.text}
          classList={css()}
          style={style}
        >
          {props.children}
        </th>
      </Match>
      <Match when={props.component === 'td'}>
        <td
          {...(props as ParentProps<JSX.HTMLAttributes<HTMLTableCellElement>>)}
          class={classes.text}
          classList={css()}
          style={style}
        >
          {props.children}
        </td>
      </Match>
      <Match when={props.component === 'pre'}>
        <pre
          {...(props as ParentProps<JSX.HTMLAttributes<HTMLPreElement>>)}
          class={classes.text}
          classList={css()}
          style={style}
        >
          {props.children}
        </pre>
      </Match>
    </Switch>
  );
}
